import React from 'react';
import styles from './SubmitInvestment.module.scss';
import NextArrow from 'assets/images/next-arrow';
import SubscriptionAgreementUrl from './Payments/SubscriptionAgreementUrl';
import Button from 'components/Button';
import LoadSpinner from 'assets/images/load-spinner';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const SubmitInvestment = ({ onSubmit, handleSubmit }) => {
  const isfundMovementLoading = useSelector(({ investor }) => {
    return get(investor, 'fundMovement.loading', false);
  });

  const onSubmitProspectLoader = useSelector(({ formBody }) => {
    return get(formBody, 'prospect.loading', false);
  });

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  return (
    <div className={styles.container}>
      <Button
        className={styles.button}
        isDisabled={isfundMovementLoading || onSubmitProspectLoader}
        onClick={handleSubmit(onSubmit)}
        style={
          offering?.payment_enabled
            ? {}
            : {
                paddingLeft: '40px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }
        }
      >
        {offering?.payment_enabled ? (
          <>
            {!isfundMovementLoading ? (
              <>
                <h3>Submit Investment</h3> <NextArrow />
              </>
            ) : (
              <>
                <h3>Submitting</h3> <LoadSpinner />
              </>
            )}
          </>
        ) : (
          <>
            {!onSubmitProspectLoader ? (
              <>
                <h3>Submit Commitment</h3> <NextArrow />
              </>
            ) : (
              <>
                <h3>Submitting</h3> <LoadSpinner />
              </>
            )}
          </>
        )}
      </Button>

      <SubscriptionAgreementUrl />
    </div>
  );
};

export default SubmitInvestment;
