import React from 'react';
import RSelect from 'react-select';
import { Controller } from 'react-hook-form';
import cs from 'classnames';
import classes from './select.module.scss';

const Select = props => {
  const {
    wrapperClassName = 'flex-col',
    labelClassName,
    error,
    id,
    className,
    name,
    value,
    touched,
    options,
    control,
    setError,
    type,
    ...rest
  } = props;

  return (
    <div className={cs(wrapperClassName, classes.root)} data-testid={name}>
      <label className={cs(`block mb-2`, labelClassName)} htmlFor={type}>
        {props.label}
      </label>
      <Controller
        render={({ field }) => {
          return (
            <RSelect
              inputId={type}
              autoComplete={type}
              classNamePrefix="rselect"
              className={cs(
                'border focus:border-brandorange focus:outline-none rounded mt-2 w-full ',
                { 'border-brandred': error },
                className
              )}
              getOptionLabel={opt => opt.label}
              getOptionValue={opt => opt.value}
              id={id}
              name={name}
              options={options}
              {...field}
              onChange={option => {
                field.onChange(option);
                if (rest.onChange) rest.onChange(option);
                setError(`${name}`, {});
              }}
            />
          );
        }}
        options={options}
        name={name}
        control={control}
        {...rest}
      />

      {error ? (
        <span
          className="text-brandred -mt-3 text-sm"
          data-testid={'error.' + name}
        >
          {error}
        </span>
      ) : null}
    </div>
  );
};

export default Select;
