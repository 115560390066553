import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { Redirect, useParams } from 'react-router-dom';
import useQuery from 'helpers/useQuery';

export const CanAccess = ({ children }) => {
  const { offeringName } = useParams();

  const query = useQuery();
  const code = query.get('code');

  const accountData = useSelector(({ investor }) => {
    return get(investor, 'account.data', null);
  });

  const fundMovementData = useSelector(({ investor }) => {
    return get(investor, 'fundMovement.data', null);
  });

  const userData = useSelector(state => {
    return get(state, 'formBody.user.data', null);
  });

  const prospect = useSelector(state => {
    return get(state, 'formBody.prospect.data', null);
  });

  return userData || accountData || fundMovementData || code || prospect ? (
    children
  ) : (
    <Redirect
      exact
      to={{
        pathname: `/${offeringName}/investor-information`,
        state: { from: location.pathname },
      }}
    />
  );
};

export default CanAccess;
