import BaseAxios from 'axios';
import get from 'lodash/get';

class Request {
  axios;

  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 });
  }

  async call(config) {
    let headers = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept, Authorization',
      'Content-Type': 'application/json',
    };

    const slug = localStorage.getItem('slug');

    const { headers: configHeaders, ...restConfig } = config;

    headers = {
      ...headers,
      ...configHeaders,
      'X-Offering-Slug': slug,
    };

    try {
      const res = await this.axios.request(
        {
          baseURL: process.env.REACT_APP_API_URL,
          headers,
          ...restConfig,
        },
        {}
      );

      return { ...res.data, status: 1 };
    } catch (error) {
      if (get(error, 'response', null)) {
        let data = error.response.data;

        if (get(data, 'message') && typeof data.message === 'object') {
          // convert  error message object into string
          let message = '';
          if (!(data.message instanceof Array)) {
            for (const field in data.message) {
              message = get(data.message, `${field}.0`, '');
              if (message) {
                data.message = message;
                break;
              }
            }
          }
        }

        return { ...data, status: 0 };
      } else {
        return {
          status: 0,
          message: 'Please try again later',
        };
      }
    }
  }
}

export default new Request();
