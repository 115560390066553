// Importing dependencies
import { applyMiddleware, createStore, compose } from 'redux';
import { createLogger } from 'redux-logger';

// redux-thunk (middleware)
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './reducer/combineReducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['prospect', 'analytics'],
};

const emptyMiddleWare = () => next => action => {
  const result = next(action);
  return result;
};

let loggerMiddleWare = emptyMiddleWare;

if (process.env.NODE_ENV === 'development') {
  loggerMiddleWare = createLogger({
    collapsed: true,
  });
}

var isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  process.env.NODE_ENV === 'development' && isChrome
    ? compose(
        applyMiddleware(thunk, loggerMiddleWare),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : applyMiddleware(thunk, loggerMiddleWare)
);
const persistor = persistStore(store);

export { store, persistor };
