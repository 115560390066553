import React, { useEffect, useState, useLayoutEffect } from 'react';
import styles from './InvestmentBody.module.scss';
import SideBar from '../../components/Sidebar/SideBar';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useQuery from 'helpers/useQuery';

import get from 'lodash/get';

import Modal from 'components/Modal';
import { XCircle } from 'react-feather';

import {
  fetchOffering,
  getInvestorByMagiclink,
} from 'redux/actions/investor.action';
import OfferingModal from 'components/InvestmentLayout/OfferingModal';
import cs from 'classnames';
import PageChange from 'components/PageChange/PageChange';

const Investment = ({ children }) => {
  const query = useQuery();

  const dispatch = useDispatch();

  const { offeringName } = useParams();

  const offeringData = useSelector(state => {
    return get(state, 'offering.data', false);
  });

  const isOfferingLoading = useSelector(state => {
    return get(state, 'offering.loading', false);
  });

  const isMagicLinkLoading = useSelector(({ investor }) =>
    get(investor, 'magicLinkLoader.loading', null)
  );

  const [onOfferingSelectionClick, setOfferingSelectionClick] = useState(false);
  const [first, setfirst] = useState(true);

  useEffect(() => {
    if (query.get('code')) {
      dispatch(
        getInvestorByMagiclink({
          nanoId: query.get('code'),
          type: query.get('type') ? query.get('type') : '',
        })
      );
    }
    dispatch(fetchOffering(offeringName));

    setfirst(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOfferingSelectionClick]);

  if (isMagicLinkLoading || isOfferingLoading || first) {
    return <PageChange />;
  }

  return (
    <div
      className={cs(styles.investmentBody, {
        [styles.offeringValidError]: offeringData ? false : true,
      })}
    >
      <SideBar />
      {children}

      <OfferingModal
        offeringData={offeringData}
        setOfferingSelectionClick={setOfferingSelectionClick}
        onOfferingSelectionClick={onOfferingSelectionClick}
      />

      <Modal
        center={true}
        width={'80%'}
        isOpen={false}
        isModalOutsideClose={true}
        // handleCloseClick={() => {
        //   if (query.get('type') && query.get('type') === 'payment') return;
        //   setpaymentFailureModal(false);
        // }}
      >
        <div className="flex flex-col items-center p-16 text-center">
          <XCircle className="mb-1" width={40} height={40} color="red" />
          {/* <h3 className="m-auto text-lg">{payment_failure_message}</h3> */}
          <p className="text-sm font-bold text-brandorange mt-1">
            Have questions? Contact us at{' '}
            <a className="underline" href="mailto:invest@boxabl.com">
              invest@boxabl.com
            </a>
          </p>
        </div>{' '}
      </Modal>
    </div>
  );
};

export default Investment;
