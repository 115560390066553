import { createTheme, ThemeProvider } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState, useEffect } from 'react';
import Downarrow from './DownArrow';
import { Controller } from 'react-hook-form';
import styles from './NSelect.module.scss';

const theme = createTheme({
  components: {
    // Name of the component ⚛️
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          marginTop: 'auto',
        },
        option: {
          backgroundColor: '#fff !important',
          '&:hover': {
            backgroundColor: 'orange !important',
            color: 'white',
          },
        },
      },
    },
  },
});

const NSelect = ({
  control,
  id,
  name,
  getValues,
  setValue,
  error,
  setError,
  list,
  type,
  autoComplete,
  label = '',
  ...rest
}) => {
  const [openMenu, setOpenMenu] = useState(false);

  const [input, setInput] = useState(getValues(name)?.label);

  useEffect(() => {
    setInput(getValues(name)?.label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues(name)?.label]);

  useEffect(() => {
    if (input) {
      if (input.length > 0) {
        const foundSelectedOption = list.find(
          (e, i) => e.label === input || e.value === input
        );

        if (foundSelectedOption) {
          setValue(name, foundSelectedOption);
          setError(name, {});
          setOpenMenu(false);
        } else {
          // if input is not found by neither label nor value
          // then we give the user an error message

          if (!new RegExp('Select').test(input)) {
            setError(name, {
              type: 'custom',
              message: `Not Valid. Please select an option.`,
            });
            setValue(name, {
              label: 'Select..',
              value: false,
            });
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  return (
    <div className={styles.nselect}>
      <label>{label}</label>
      <Controller
        name={name}
        id={id}
        control={control}
        render={({ field: ControllerField }) => {
          return (
            <ThemeProvider theme={theme}>
              <Autocomplete
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onOpen={() => {
                  if (input) {
                    setOpenMenu(true);
                  }
                }}
                open={openMenu}
                type={type}
                label={label}
                name={name}
                id={id}
                options={list}
                getOptionLabel={option => option.label}
                autoSelect
                autoHighlight
                renderInput={params => {
                  //Input css and attributes
                  const inputProps = params.inputProps;
                  //SET AUTOCOMPLETE HERE
                  inputProps.autoComplete = autoComplete;
                  inputProps.className = styles.mui_input;
                  //label css and attributes
                  const inputLabel = params.InputLabelProps;
                  inputLabel.className = styles.mui_label;

                  const {
                    onBlur,
                    onChange,
                    value,
                    name,
                    ...ControllerRef
                  } = ControllerField;

                  return (
                    <div {...ControllerRef} tabIndex={'0'}>
                      <div
                        ref={params.InputProps.ref}
                        className={styles.mui_container}
                        data-testid={id}
                        onClick={() => {
                          setOpenMenu(true);
                        }}
                        style={
                          error
                            ? {
                                border: '1px solid red',
                              }
                            : {}
                        }
                      >
                        <input
                          {...params.inputProps}
                          value={input}
                          onInput={e => {
                            setInput(e.target.value);
                          }}
                        />
                        <div className={styles.right}>
                          <span></span>
                          <div className={styles.right_arrow_container}>
                            <Downarrow />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }}
                // if something changes upon select
                onChange={(_, data) => {
                  setOpenMenu(false);
                  if (data === null) {
                    ControllerField.onChange({ label: 'Select', value: false });
                  } else {
                    ControllerField.onChange(data);
                  }

                  if (data) {
                    setInput(data.label);
                  }
                  if (rest.onChange) rest.onChange(data);
                  // setError(`${name}`, {});
                }}
              />
            </ThemeProvider>
          );
        }}
      />
      {error ? (
        <span
          style={{ color: 'red', fontSize: '0.875rem' }}
          data-testid={'error.' + name}
        >
          {error}
        </span>
      ) : null}
    </div>
  );
};

export default NSelect;
