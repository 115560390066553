import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMedia } from 'react-use';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import CompanyForm, { companyInitialValues } from '../EntityForms/CompanyForm';
import PersonForm, { personInitialValues } from '../EntityForms/PersonForm';
import Button from 'components/Button';
import NextArrow from 'assets/images/next-arrow';
import LoadSpinner from 'assets/images/load-spinner';

import { getCompanySchema } from '../EntityForms/companySchema';
import { getPersonSchema } from '../EntityForms/personSchema';
import { useHistory } from 'react-router-dom';
import InvestmentQuestion from '../Question/InvestmentQuestion';

import get from 'lodash/get';
import { countriesOptions, stateOptionsUS } from 'helpers/country';

import { birthDays, birthMonths } from 'helpers/dates';
import { currentYear, range } from 'helpers/year';

import { formatIndividualOrEntity } from 'helpers/formatIndividualOrEntity';
import { createEntity } from 'redux/actions/investor.action';
import { createProspectInvestor } from 'redux/actions/formBodyActions';
import formatEntityObject from 'helpers/formatEntityObject';
import { injectDefaultValueCompany } from '../CompanyInvestor/CompanyInvestorSchema';
import { analytics } from 'helpers/gtm';
import CanadaModal from 'components/Modals/CanadaModal';

const TrustInvestor = ({
  investAsPrevious,
  questionPage,
  setQuestionPage,
  initialValues,
  prospectedByInvestorData,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isVerifiedTaxIdNumber, setVerifiedTaxIdNumber] = useState(false);
  let history = useHistory();

  const offeringName = useSelector(state => {
    return get(state, 'offering.data.issueName', null);
  });

  const showQuestionnaire = useSelector(state => {
    return get(state, 'offering.data.show_questionnaire', null);
  });

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  const redirectToPayment = () => {
    if (questionPage || !showQuestionnaire) {
      const query = initialValues.amount
        ? `?amount=${initialValues.amount}`
        : '';
      history.push(`/${offeringName}/payment${query}`);
    } else {
      return null;
    }
  };

  const isMaxWidth600px = useMedia('(max-width:600px)');

  const onSubmitLoader = useSelector(({ investor }) => {
    return get(investor, 'links.loading', false);
  });

  const onSubmitProspectLoader = useSelector(({ formBody }) => {
    return get(formBody, 'prospect.loading', false);
  });

  const onSubmit = async values => {
    if (!questionPage && showQuestionnaire) {
      if (offering?.payment_enabled)
        analytics({
          dataLayer: {
            event: 'interactionEvent',
            event_name: 'step_1_submit',
            email: values?.person?.email,
            phone: values?.person?.phone,
          },
        });
      return setQuestionPage(true);
    }
    const { trust } = values;

    let response = null;
    if (offering?.payment_enabled) {
      response = await dispatch(
        createEntity(
          formatIndividualOrEntity({
            ...values,
            type: 'trust',
            entity: trust,
            offeringType: offering.type,
          })
        )
      );

      analytics({
        dataLayer: {
          event: 'interactionEvent',
          event_name: 'step_2_submit',
          email: values?.person?.email,
          phone: values?.person?.phone,
        },
      });
    } else {
      const { person } = values;

      response = await dispatch(
        createProspectInvestor(
          {
            person: formatEntityObject(person, true),
            trust: formatEntityObject(trust, false, true),
            offering: offering._id.toString(),
            type: 'trust',
          },
          'trust'
        )
      );
    }

    if (response) return await redirectToPayment();
  };

  const schema = Yup.object({
    trust: getCompanySchema(
      (offering && offering.payment_enabled) || prospectedByInvestorData?.trust
    ),
    person: getPersonSchema(
      (offering && offering.payment_enabled) || prospectedByInvestorData?.trust
    ),
  });

  const { amount, ...restInitialValues } = initialValues;

  const {
    register,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    validate: ['onSubmit'],
    revalidate: ['onSubmit', 'onBlur', 'onChange'],
    defaultValues: injectDefaultValueCompany(
      restInitialValues,
      personInitialValues,
      companyInitialValues,
      prospectedByInvestorData,
      'trust'
    ),
    resolver: yupResolver(schema),
  });

  return (
    <>
      <CanadaModal country={watch('person.country')?.label} />
      {questionPage ? (
        <InvestmentQuestion
          isLoading={onSubmitLoader || onSubmitProspectLoader}
          setQuestionPage={setQuestionPage}
          register={register}
          watch={watch}
          errors={errors}
          control={control}
          setValue={setValue}
          handleSubmit={handleSubmit(onSubmit)}
        />
      ) : (
        <div>
          <h2 className="mt-12 mb-4">Trust Details</h2>
          <CompanyForm
            type="trust"
            investAsPrevious={investAsPrevious}
            register={register}
            watch={watch}
            errors={errors}
            control={control}
            setValue={setValue}
            setError={setError}
            getValues={getValues}
            isVerifiedTaxIdNumber={isVerifiedTaxIdNumber}
            setVerifiedTaxIdNumber={setVerifiedTaxIdNumber}
            isPresetProspected={prospectedByInvestorData?.trust}
          />
          <h2 className="mt-12 mb-4">Associated Person Details</h2>
          <PersonForm
            type="person"
            investAsPrevious={investAsPrevious}
            register={register}
            watch={watch}
            errors={errors}
            control={control}
            setValue={setValue}
            getValues={getValues}
            setError={setError}
          />
          <div
            className={cs({
              'mt-12': !isMaxWidth600px,
              'mt-10 mb-8 flex flex-col justify-center w-full relative': isMaxWidth600px,
            })}
          >
            <Button
              className={cs('float-right', {
                'flex items-center justify-center': isMaxWidth600px,
              })}
              onClick={handleSubmit(onSubmit)}
              data-testid="next"
              isDisabled={onSubmitLoader || onSubmitProspectLoader}
            >
              <h3>Next</h3>{' '}
              {onSubmitLoader || onSubmitProspectLoader ? (
                <LoadSpinner />
              ) : (
                <NextArrow />
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default TrustInvestor;
