// Importing all the dependencies
import { combineReducers } from 'redux';

import formBodyReducer from './formBodyReducer';
import offeringReducer from './offeringReducer';
import investorReducer from './investorReducer';
import prospectReducer from './prospectReducer';
import analyticsReducer from './analyticsReducer';
// Exporting combineReducer as rootReducer
const combinedReducer = combineReducers({
  formBody: formBodyReducer,
  offering: offeringReducer,
  investor: investorReducer,
  prospect: prospectReducer,
  analytics: analyticsReducer,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export default rootReducer;
