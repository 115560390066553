import React from 'react';
import { CheckCircle, File, AlertTriangle } from 'react-feather';
import LoadSpinner from '../../assets/images/load-spinner';
import { useMedia } from 'react-use';
import cs from 'classnames';
import styles from './verification-document.module.scss';
import { byteToReadableSize } from 'helpers/bytesToReadableSize';
const UploadedFiles = ({ files, deletedFiles, filesProgress }) => {
  const isMaxWidth455px = useMedia('(max-width:455px)');
  return files && files.length ? (
    <div className="flex flex-col items-center justify-center my-3 w-full xl:w-8/12">
      <h2 className="mb-3 text-brandblue text-medium font-bold w-full text-left">
        Uploaded Files{` (${files && files.length}) `}:
      </h2>
      <ul className="w-full">
        {files.map((f, i) => (
          <li
            key={i}
            className={cs(
              'py-4 flex w-full items-center px-5 mb-3 bg-white border border-brandorange shadow-md rounded-lg',
              styles.fileContainer,
              {
                'flex items-center justify-center': deletedFiles.some(
                  id => id === f.id
                ),

                [styles.fileContainerWait]: deletedFiles.length,
              }
            )}
          >
            {deletedFiles.some(id => id === f.id) ? (
              <LoadSpinner size={{ cx: 40, cy: 40, r: 35 }} />
            ) : (
              <>
                <div className="relative flex items-center justify-center">
                  {/*DUMMY COMPONENT */}
                  <File
                    className="mr-2 text-brandblue"
                    size={isMaxWidth455px ? 70 : 90}
                  />

                  <span
                    className={cs('absolute text-brandblue', styles.typeText, {
                      [styles.aml]: f.type === 'aml_check',
                      [styles.document]: f.type === 'accreditation',
                    })}
                  >
                    {f.type === 'aml_check' ? 'AML' : 'ACC'}
                  </span>
                </div>
                <div className="flex-1 overflow-hidden">
                  <div className="w-full mb-1 overflow-hidden text-brandblue text-lg flex-row flex justify-between">
                    <span
                      className={cs(
                        'text-brandblue flex-1 text-lg font-bold',
                        styles.fileName
                      )}
                    >
                      {f.file.name}
                    </span>
                    <div>
                      {filesProgress[f.id]?.percentage < 100 ? (
                        <LoadSpinner />
                      ) : null}
                      {filesProgress[f.id]?.percentage === 100 &&
                      filesProgress[f.id]?.uploaded ? (
                        //    DUMMY COMPONENT
                        <CheckCircle
                          className={cs('text-brandorange', styles.checkCircle)}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col items-end">
                    <div className="relative pt-1 w-full">
                      <div
                        className={cs(
                          'overflow-hidden mb-2 text-xs flex rounded bg-blue-200',
                          {
                            'h-2': isMaxWidth455px,
                            'h-3': !isMaxWidth455px,
                          }
                        )}
                      >
                        <div
                          style={{
                            width: `${filesProgress[f.id]?.percentage || 0}%`,
                          }}
                          className="shadow-none transition-all ease-in duration-75 flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                        />
                      </div>
                    </div>
                    {filesProgress[f.id]?.message ? (
                      <div
                        className={cs(
                          'flex mb-1 font-medium items-center w-full justify-start ',
                          {
                            'text-green-500': filesProgress[f.id]?.uploaded,
                            'text-brandred': !filesProgress[f.id]?.uploaded,
                          }
                        )}
                      >
                        <AlertTriangle size={isMaxWidth455px ? 15 : 20} />
                        &nbsp;
                        <span>Error: {filesProgress[f.id]?.message}</span>
                      </div>
                    ) : null}

                    {filesProgress[f.id]?.percentage ||
                    filesProgress[f.id]?.percentage === 0 ? (
                      <div className="w-full flex mb-2 items-center justify-between text-brandblue">
                        <div className="w-full text-brandgrey flex-1">
                          ({byteToReadableSize(f.file.size)})
                        </div>
                        <div className="flex items-center">
                          {`${filesProgress[f.id]?.percentage} %`}{' '}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default UploadedFiles;
