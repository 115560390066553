// Importing all of the dependencies
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import cs from 'classnames';
import styles from './investorInformation.module.scss';
import InvestorChoices from './InvestorChoices';
import LoadSpinner from '../../assets/images/load-spinner';
import InvestmentLayout from '../../components/InvestmentLayout';
import IndividualInvestor from './IndividualInvestor/IndividualInvestor';
import CompanyInvestor from './CompanyInvestor/CompanyInvestor';
import IRAInvestor from './IRAInvestor/IRAInvestor';
import TrustInvestor from './TrustInvestor/TrustInvestor';
import StepNumber from '../../components/StepNumber';
import useQuery from 'helpers/useQuery';
import TagManager from 'react-gtm-module';
import { useParams } from 'react-router-dom';

const InvestorInformation = () => {
  //hello world
  const { offeringName } = useParams();
  const [investAs, setInvestAs] = useState('person');
  const [loading, setLoading] = useState(false);
  const [investAsPrevious, setInvestAsPrevious] = useState(false);
  const [questionPage, setQuestionPage] = useState(false);

  const [collapse, setCollapse] = useState(false);

  const query = useQuery();

  const hasName = query.get('name') ? true : false;

  const firstName = hasName
    ? query.get('name').substring(0, query.get('name').indexOf(' '))
    : '';
  const lastName = hasName
    ? query.get('name').substring(query.get('name').indexOf(' ') + 1)
    : '';

  const initialValues = {
    phone: query.get('phone') || '',
    email: query.get('email') ? query.get('email')?.replace(/ /g, '+') : '',
    amount: query.get('amount'),

    firstName,
    lastName,

    country: {
      label: 'United States',
      value: 'US',
    },

    region: {
      label: 'Select State',
      value: false,
    },
  };

  const entitiesData = useSelector(({ formBody }) =>
    get(formBody, 'entities.data', {})
  );
  const entitiesLoading = useSelector(({ formBody }) =>
    get(formBody, 'entities.loading', {})
  );

  const prospectedByInvestorData = useSelector(({ prospect }) =>
    get(prospect, 'data', {})
  );

  useEffect(() => {
    if (prospectedByInvestorData?.type) {
      setInvestAs(
        prospectedByInvestorData?.type === 'joint'
          ? 'person'
          : prospectedByInvestorData?.type
      );

      if (
        prospectedByInvestorData?.type !== 'joint' &&
        prospectedByInvestorData?.type !== 'person'
      ) {
        setCollapse(true);
      }
    }
  }, [prospectedByInvestorData?.type]);

  const setNewInvest = text => {
    if (investAsPrevious) {
      return;
    }
    setInvestAs(text);
  };

  const isMaxWidth600px = useMedia('(max-width:600px)');

  useEffect(() => {
    const keys = Object.keys(entitiesData);
    if (keys && !keys.length) {
      setInvestAsPrevious(false);
    } else {
      //Set this as false as temporary hotfix to resolve odd disabled fields.
      setInvestAsPrevious(false);
    }
  }, [entitiesData]);
  const investorProspect = useSelector(state => {
    return get(state, 'formBody.investorProspect.data', false);
  });

  useLayoutEffect(() => {
    if (offeringName === 'reg_d_2') {
      TagManager.dataLayer({
        dataLayer: {
          event: 'interactionEvent',
          event_name: 'investor_info_start',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFormsBasedOnInvestor = () => {
    switch (investAs) {
      case 'person':
        return (
          <IndividualInvestor
            collapse={collapse}
            questionPage={questionPage}
            setQuestionPage={setQuestionPage}
            investAsPrevious={investAsPrevious}
            initialValues={initialValues}
            prospectedByInvestorData={
              prospectedByInvestorData?.type === 'person' ||
              prospectedByInvestorData?.type === 'joint'
                ? prospectedByInvestorData
                : {}
            }
          />
        );
      case 'company':
        return (
          <CompanyInvestor
            questionPage={questionPage}
            setQuestionPage={setQuestionPage}
            investAsPrevious={investAsPrevious}
            initialValues={initialValues}
            prospectedByInvestorData={
              prospectedByInvestorData?.type === 'company'
                ? prospectedByInvestorData
                : {}
            }
          />
        );
      case 'ira':
        return (
          <IRAInvestor
            questionPage={questionPage}
            setQuestionPage={setQuestionPage}
            investAsPrevious={investAsPrevious}
            initialValues={initialValues}
            prospectedByInvestorData={
              prospectedByInvestorData?.type === 'ira'
                ? prospectedByInvestorData
                : {}
            }
          />
        );
      case 'trust':
        return (
          <TrustInvestor
            questionPage={questionPage}
            setQuestionPage={setQuestionPage}
            investAsPrevious={investAsPrevious}
            initialValues={initialValues}
            prospectedByInvestorData={
              prospectedByInvestorData?.type === 'trust'
                ? prospectedByInvestorData
                : {}
            }
          />
        );
    }
  };

  return (
    <InvestmentLayout>
      <div className={styles.root}>
        <div className="relative h-full">
          {!questionPage && (
            <div
              className={cs(styles.titleHeader, {
                'shadow-sm': isMaxWidth600px,
              })}
            >
              {!entitiesLoading ? (
                <div className="flex flex-col">
                  <StepNumber />
                  <h1 className={styles.title}>Investor information</h1>
                </div>
              ) : null}
            </div>
          )}

          <>
            <div
              className={cs(
                styles.investmentOptions,
                { 'mt-4': !questionPage },
                { 'p-0': isMaxWidth600px && questionPage }
              )}
            >
              {!questionPage && (
                <p className={cs(styles.paragraph, 'mb-5')}>
                  I am Investing as
                </p>
              )}
              {!questionPage && (
                <InvestorChoices
                  collapse={collapse}
                  setCollapse={setCollapse}
                  setNewInvest={setNewInvest}
                  active={investAs}
                  investAsPrevious={investAsPrevious}
                />
              )}
            </div>

            {!loading ? renderFormsBasedOnInvestor() : <LoadSpinner />}
          </>
        </div>
      </div>
    </InvestmentLayout>
  );
};

export default InvestorInformation;
