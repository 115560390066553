import React, { useEffect } from 'react';
import { useMedia } from 'react-use';
import { CheckCircle } from 'react-feather';
import Input from 'components/Input/Input';
import Select from 'components/Select';
import styles from './investorForms.module.scss';
import { countriesOptions, stateOptionsUS } from 'helpers/country';
import AddressForm from './AddressForm';
import { InputMask } from 'components/Input/InputMask';
import Button from 'components/Button/Button';
import cs from 'classnames';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import NSelect from 'components/NSelect/NSelect';
export const companyInitialValues = {
  name: '',
  contact_name: '',
  email: '',
  tax_id_number: '',
  street_address_1: '',
  street_address_2: '',
  phone: '',
  city: '',
  region: {
    label: 'Select State',
    value: false,
  },
  region_formed_in: {
    label: 'Select State',
    value: false,
  },
  country: { label: 'United States', value: 'US' },
  postal_code: '',
};

export const iraInitialValue = {
  name: '',
  tax_id_number: '',
  street_address_1: '',
  street_address_2: '',
  phone: '',
  city: '',
  region: {
    label: 'Select State',
    value: false,
  },
  region_formed_in: {
    label: 'Select State',
    value: false,
  },
  country: { label: 'United States', value: 'US' },
  postal_code: '',
};

const CompanyForm = ({
  type,
  register,
  errors,
  watch,
  control,
  setValue,
  getValues,
  setError,
  /*isVerifiedTaxIdNumber,
  setVerifiedTaxIdNumber,*/
  isPresetProspected,
}) => {
  const media_query = useMedia('(max-width:600px)');

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });
  const watch_country = watch(type)?.country?.value;

  // AutoComplete fix for forienger fix
  useEffect(() => {
    if (watch_country !== 'US') {
      if (typeof watch(type)?.region_formed_in === 'object') {
        setValue(`${type}.region_formed_in`, '');
        setError(`${type}.region_formed_in`, '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_country]);

  return (
    <div className={styles.root}>
      <h2 className="mt-12 mb-4">Basic Details</h2>
      <div
        className={`grid mt-2  ${
          !media_query && watch(type)?.country.value === 'GB'
            ? 'grid-cols-2 gap-5'
            : media_query
            ? 'grid-cols-1 gap-5'
            : 'grid-cols-3 gap-10'
        } `}
      >
        <Input
          label={
            type === 'ira' ? 'Retirement Account Company Name' : `${type} Name`
          }
          name={`${type}.name`}
          register={register}
          error={errors[type]?.name ? errors[type]?.name?.message : null}
        />

        <NSelect
          autoComplete={'country'}
          control={control}
          label={
            type === 'ira' ? 'Retirement Account Company Country' : 'Country'
          }
          id={`${type}.country`}
          name={`${type}.country`}
          getValues={getValues}
          setError={setError}
          error={errors[type]?.country ? errors[type]?.country?.message : null}
          list={countriesOptions}
          setValue={setValue}
          type={type}
          onChange={option => {
            if (option) {
              // if you switch from US to pakistan back to us
              // region will need to be redefined  will prevent
              // mui bug where region will be undefined...
              if (option.value !== 'US') {
                setValue(`${type}.region`, '');
                setValue(`${type}.region_formed_in`, '');
              } else {
                setValue(`${type}.region`, {
                  label: 'Select State',
                  value: false,
                });
                setValue(`${type}.region_formed_in`, {
                  label: 'Select State',
                  value: false,
                });
              }
            }
          }}
        />

        <InputMask
          label={type === 'ira' ? 'Retirement Account Company Phone' : 'Phone'}
          id={`${type}.phone`}
          name={`${type}.phone`}
          error={errors[type]?.phone ? errors[type]?.phone?.message : null}
          control={control}
          format={
            watch(type)?.country?.value === 'US'
              ? '(###) ###-####'
              : '################'
          }
        />

        <Input
          label={type === 'ira' ? 'Retirement Account Company Email' : 'Email'}
          id="email"
          name={`${type}.email`}
          register={register}
          error={errors[type]?.email ? errors[type]?.email?.message : null}
        />

        {watch(type)?.country?.value === 'US' ? (
          <NSelect
            autoComplete={'address-level1'}
            control={control}
            label={type === 'ira' ? 'State of Formation' : 'Region Formed In'}
            id={`${type}.region_formed_in`}
            name={`${type}.region_formed_in`}
            getValues={getValues}
            setError={setError}
            error={
              errors[type]?.region_formed_in
                ? errors[type]?.region_formed_in?.message
                : null
            }
            list={stateOptionsUS}
            setValue={setValue}
            type={type}
          />
        ) : (
          <Input
            label={type === 'ira' ? 'State of Formation' : 'Region Formed In'}
            id={`${type}.region_formed_in`}
            name={`${type}.region_formed_in`}
            register={register}
            error={
              errors[type]?.region_formed_in
                ? errors[type]?.region_formed_in?.message
                : null
            }
          />
        )}

        {offering?.payment_enabled && watch(type)?.country?.value === 'US' ? (
          <>
            <InputMask
              label="EIN"
              id={`${type}.tax_id_number`}
              name={`${type}.tax_id_number`}
              control={control}
              error={
                errors[type]?.tax_id_number
                  ? errors[type]?.tax_id_number?.message
                  : null
              }
              format="##-#######"
              maskChar=""
            />
          </>
        ) : null}
      </div>
      <AddressForm
        type={type}
        register={register}
        watch={watch}
        errors={errors}
        control={control}
        setError={setError}
        setValue={setValue}
        getValues={getValues}
      />
    </div>
  );
};

export default CompanyForm;
