import Modal from 'components/Modal';
import get from 'lodash/get';
import React from 'react';
import { XCircle } from 'react-feather';
import { useSelector } from 'react-redux';
import { BsInfoSquare } from 'react-icons/bs';
import OfferingSelectionModal from 'components/OfferingSelectionModal/OfferingSelectionModal';
import { useHistory, useLocation } from 'react-router-dom';

const OfferingModal = ({
  offeringData,
  setOfferingSelectionClick,
  onOfferingSelectionClick,
}) => {
  let history = useHistory();
  const [offeringError, setOfferingError] = React.useState(false);
  const account = useSelector(({ investor }) =>
    get(investor, 'account.data', null)
  );
  const OfferingErrorMessage = useSelector(state => {
    return get(state, 'offering.message', null);
  });
  const isApiOfferingError = useSelector(state => {
    return get(state, 'offering.error', null);
  });
  const location = useLocation();
  React.useEffect(() => {
    if (isApiOfferingError) {
      if (
        location.pathname.split('/')[1] === 'reg_d_2' ||
        location.pathname.split('/')[1] === 'reg_a_2'
      ) {
        setOfferingError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApiOfferingError]);

  return offeringError ? (
    <Modal isOpen={offeringData ? false : true} width="50%">
      <div className="flex flex-col items-center p-16">
        <br />
        <XCircle className="mb-1" width={40} height={40} color="red" />
        <h3 className="m-auto text-lg text-center mt-2">
          <div> {OfferingErrorMessage}</div>
          <br />
          Please click the offering below to see our current live offering.
        </h3>
        <br />
        <button
          onClick={() => {
            history.push(`/reg_d_2/investor-information`);
            setOfferingSelectionClick(!onOfferingSelectionClick);
          }}
          className={`block mx-auto mb-5 bg-brandblue hover:bg-brandorange text-xl text-white font-bold py-2 px-4 `}
        >
          <div
            style={{ fontStyle: 'italic' }}
            className="italic text-brandorange text-sm"
          >
            Now live! <div className="text-white">$10,000 minimum</div>
          </div>
          <span className="text-xl">Accredited Offering</span>
          <p className="block mb-0 mt-2 text-lg font-normal">
            ($1MM net worth or $200K annual income)
          </p>
        </button>

        <p className="text-sm font-bold text-brandorange mt-1 text-center">
          If this issue still persist. Please contact support team at{' '}
          <a className="underline" href="mailto:invest@boxabl.com">
            invest@boxabl.com
          </a>
        </p>
      </div>
    </Modal>
  ) : (
    <OfferingSelectionModal isOpen={offeringData ? false : true}>
      <div className="container">
        <div className="w-full  mx-auto  rounded pt-6 pb-6 px-10 mt-16 bg-white">
          <h2 className="text-2xl font-bold text-brandblue">
            Offering Selection
          </h2>
          <hr className="border-brandorange m-0 border-t-0 border-b-4 w-16" />

          <div className="text-center w-full mt-6">
            <p className="text-2xl mb-5">
              Which offering would you like to view?
            </p>

            <div className="mt-6 mb-4 text-center">
              {/* <button
                className={`block mx-auto mb-5 bg-brandblue hover:bg-brandorange text-xl text-white font-bold py-2 px-4`}
                onClick={() => {
                  history.push(`/reg_a_2/investor-information`);
                  setOfferingSelectionClick(!onOfferingSelectionClick);
                }}
              >
                <div
                  style={{ fontStyle: 'italic' }}
                  className="italic text-brandorange text-sm"
                >
                  Now live! <div className="text-white">$1,000 minimum</div>
                </div>
                <span className="text-xl">Regulation A Crowdfund Offering</span>
                <p className="block mb-0 mt-2 text-lg font-normal">
                  (everyone)
                </p>
              </button>

              <p className="pt-6 pb-4 text-lg">- or -</p> */}

              <button
                onClick={() => {
                  history.push(`/reg_d_2/investor-information`);
                  setOfferingSelectionClick(!onOfferingSelectionClick);
                }}
                className={`block mx-auto mb-5 bg-brandblue hover:bg-brandorange text-xl text-white font-bold py-2 px-4 `}
              >
                <div
                  style={{ fontStyle: 'italic' }}
                  className="italic text-brandorange text-sm"
                >
                  Now live! <div className="text-white">$10,000 minimum</div>
                </div>
                <span className="text-xl">Accredited Offering</span>
                <p className="block mb-0 mt-2 text-lg font-normal">
                  ($1MM net worth or $200K annual income)
                </p>
              </button>

              <a
                target="_blank"
                className="underline text-sm text-brandorange hover:text-brandblue align-middle"
                href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3"
                rel="noreferrer"
              >
                <span className="text-brandblue text-lg">
                  What is an accredited investor?
                </span>

                <BsInfoSquare className="ml-2 w-5 h-5 inline align-text-bottom" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </OfferingSelectionModal>
  );
};

export default OfferingModal;
