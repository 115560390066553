import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './ErrorPaymentOverlay.module.scss';

export const ErrorPaymentOverlay = ({
  error,
  setValue,
  transactionTypes,
  isRegAOverOneHundredThousand,
}) => {
  let history = useHistory();

  return error ? (
    <div className={styles.overlay}>
      {isRegAOverOneHundredThousand ? (
        <>
          <div className={styles.overlay__title}>
            {' '}
            It Looks Like You Are Investing Over $100,000 In Our Reg A <br />
            (Crowd Funding Offering). <br />
            Click{' '}
            <span
              onClick={() => {
                history.push(`/reg_d_2/payment`);
                window.location.reload();
              }}
              style={{
                textDecoration: 'underline',
                color: 'orange',
                cursor: 'pointer',
              }}
            >
              Here
            </span>{' '}
            To Switch To Our Reg D Accredited Offering To Invest Over $100,000.
          </div>
        </>
      ) : (
        <>
          <div className={styles.overlay__title}>
            {transactionTypes === 'credit_card' ? 'Credit Card ' : null}
            {transactionTypes === 'ach' ? 'Ach ' : null}
            investment cannot be greater than
            {transactionTypes === 'credit_card' ? ' $5,000' : null}
            {transactionTypes === 'ach' ? ' $100,000' : null}
          </div>

          <div className={styles.overlay__subtitle}>
            If you want to invest more than
            {transactionTypes === 'credit_card' ? ' $5,000 ' : null}
            {transactionTypes === 'ach' ? ' $100,000 ' : null}
            switch to{' '}
            {transactionTypes !== 'ach' && (
              <span onClick={() => setValue('transactionTypes', 'ach')}>
                Ach
              </span>
            )}
            ,{' '}
            <span onClick={() => setValue('transactionTypes', 'check')}>
              Mail a Check
            </span>{' '}
            or{' '}
            <span onClick={() => setValue('transactionTypes', 'wire')}>
              Wire
            </span>
          </div>
        </>
      )}
    </div>
  ) : null;
};
