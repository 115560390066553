const selectedAccountType = type => {
  switch (type) {
    case 'individual':
      return 'Individual';
    case 'company':
      return 'Entity';
    case 'trust':
      return 'Entity';
    case 'ira':
      return 'IRA';
    default:
      return null;
  }
};

const selectedEntityType = type => {
  switch (type) {
    case 'individual':
      return 'Individual';
    case 'trust':
      return 'Trust';
    default:
      return null;
  }
};

export const formatLink = ({ partyId }, { accountId, type }) => {
  return {
    firstEntryType: 'Account',
    firstEntry: accountId,
    relatedEntryType: type === 'Individual' ? 'IndivACParty' : 'EntityACParty',
    relatedEntry: partyId,
    primary_value: '1',
    linkType: 'owner',
  };
};

export const formatEntity = (
  type,
  entity,
  offeringType,
  us_citizen_or_resident
) =>
  type !== 'individual'
    ? {
        entity: {
          ...(entity?.country?.value === 'US'
            ? { ein: entity?.tax_id_number.replace(/-/g, '') }
            : {}),
          ...(offeringType === 'reg_d'
            ? { ownersAI: 'Yes' }
            : { ownersAI: 'No' }),
          entityName: entity?.name,
          domicile:
            entity?.country?.value === 'US' && us_citizen_or_resident
              ? 'U.S. Citizen'
              : 'non-resident',
          entityDesc: type,
          primCity: entity?.city,
          primZip: entity?.postal_code,
          primState:
            entity?.country?.value === 'US'
              ? entity?.region?.value
              : entity?.region,
          primCountry: entity?.country?.value,
          primAddress1: entity?.street_address_1,
          emailAddress: entity?.email,
          phone: entity?.phone?.replace(/[^\w\s]/gi, '').replace(/ /g, ''),
          KYCstatus: 'Pending',
          AMLstatus: 'Pending',
        },
      }
    : null;

export const formatParty = (
  {
    country,
    firstName,
    lastName,
    tax_id_number,
    birth_month,
    birth_date,
    birth_year,
    street_address_1,
    city,
    region,
    postal_code,
    email,
    phone,
    us_citizen_or_resident,
    annual_income,
    net_worth,
    accredited,
  },
  offeringType
) => {
  return {
    ...(country.value === 'US'
      ? { socialSecurityNumber: tax_id_number }
      : null),
    ...(offeringType === 'reg_d' && !accredited
      ? { currentAnnIncome: annual_income, householdNetworth: net_worth }
      : {}),
    domicile:
      country.value === 'US' && us_citizen_or_resident
        ? 'U.S. Citizen'
        : 'non-resident',

    firstName,
    lastName,
    dob: `${birth_month.value}-${birth_date.value}-${birth_year.value}`,
    primCity: city,
    primZip: postal_code,
    primCountry: country.value, // this can be changed
    primAddress1: street_address_1,
    primState: country.value === 'US' ? region.value : region,
    emailAddress: email,
    phone: phone.replace(/[^\w\s]/gi, '').replace(/ /g, ''),
    KYCstatus: 'Pending',
    AMLstatus: 'Pending',
  };
};
export const formatJoint = (joint_person, person, offeringType) => {
  return {
    parties: [
      {
        ...formatParty(person, offeringType),
        primary_value: 1,
        linkType: 'owner',
      },

      {
        ...formatParty(joint_person, offeringType),
        linkType: 'member',
        primary_value: 0,
      },
    ],
  };
};

export const formatIndividualOrEntity = values => {
  const { person } = values;

  const {
    type, // Type is etheir individual or entity
    is_joint,
    joint_person,
    joint_type,
    entity,
    offeringType,
    person: {
      country,
      firstName,
      lastName,
      street_address_1,
      city,
      region,
      postal_code,
      email,
      phone,
      us_citizen_or_resident,
    },
  } = values;

  return {
    // add entity
    ...formatEntity(type, entity, offeringType, us_citizen_or_resident),
    // add entityLink
    ...(type === 'individual'
      ? null
      : {
          entityLink: { relatedEntryType: 'EntityACParty', linkType: 'member' },
        }),
    // add joint parties or party
    ...(is_joint
      ? formatJoint(joint_person, person, offeringType)
      : {
          party: formatParty(person, offeringType),
        }),

    //Account
    account: {
      //Type
      type: is_joint ? joint_type.value : selectedAccountType(type),
      //entityType
      ...(is_joint
        ? { entityType: 'Joint' }
        : {
            ...(selectedEntityType(type)
              ? { entityType: selectedEntityType(type) }
              : {}),
          }),

      //for JTWROS must be firstName lastName JTWROS
      accountRegistration: is_joint
        ? `${firstName} ${lastName} and ${joint_person.firstName} ${joint_person.lastName}  ${joint_type.value}`
        : `${firstName} ${lastName}`,
      domesticYN:
        country.value === 'US' && us_citizen_or_resident
          ? 'domestic_account'
          : 'international_account',

      streetAddress1: street_address_1,
      city: city,
      state: country.value === 'US' ? region.value : region,
      zip: postal_code,
      country: country.value,
      AccreditedStatus:
        offeringType === 'reg_d' ? 'Self Accredited' : 'Not Accredited',
      email: email,
      phone: phone.replace(/[^\w\s]/gi, '').replace(/ /g, ''),
      KYCstatus: 'Pending',
      AMLstatus: 'Pending',
      approvalStatus: 'Pending',
      field2: type,
      investedAs: type,
    },
    // Link
    link: {
      relatedEntryType: 'IndivACParty',
      linkType: 'owner',
    },
  };
};
