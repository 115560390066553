import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import cs from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMedia } from 'react-use';

import PersonForm from '../EntityForms/PersonForm';
import Button from 'components/Button';
import Select from 'components/Select';
import Input from 'components/Input/Input';
import NextArrow from 'assets/images/next-arrow';
import LoadSpinner from 'assets/images/load-spinner';
import { useHistory } from 'react-router-dom';
import InvestmentQuestion from '../Question/InvestmentQuestion';

// import { countriesOptions, stateOptionsUS } from 'helpers/country';
import getIndividualInvestorSchema, {
  getIndividualInvestorDefaultValues,
  injectDefaultValueIndividual,
} from './individualInvestorSchema';
import get from 'lodash/get';

import { formatIndividualOrEntity } from 'helpers/formatIndividualOrEntity';

import { createIndividual } from 'redux/actions/investor.action';
import { createProspectInvestor } from 'redux/actions/formBodyActions';
import formatEntityObject from 'helpers/formatEntityObject';
import { analytics } from 'helpers/gtm';
import CanadaModal from 'components/Modals/CanadaModal';

const jointInvestorOptions = [
  { label: 'Joint', value: 'Joint' },
  {
    label: 'Joint Tenants with Right of Survivorship',
    value: 'JTWROS',
  },
  { label: 'Joint Tenants in Common', value: 'TIC' },
];

const IndividualInvestor = ({
  collapse,
  investAsPrevious,
  setQuestionPage,
  questionPage,
  initialValues,
  prospectedByInvestorData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isMaxWidth600px = useMedia('(max-width:600px)');
  const isMaxWidth525px = useMedia('(max-width:525px)');

  const offeringName = useSelector(state => {
    return get(state, 'offering.data.issueName', null);
  });

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  const showQuestionnaire = useSelector(state => {
    return get(state, 'offering.data.show_questionnaire', null);
  });

  const redirectToPayment = () => {
    if (questionPage || !showQuestionnaire) {
      const query = initialValues.amount
        ? `?amount=${initialValues.amount}`
        : '';
      history.push(`/${offeringName}/payment${query}`);
    } else {
      return null;
    }
  };

  const onSubmitLoader = useSelector(({ investor }) => {
    return get(investor, 'links.loading', false);
  });

  const onSubmitProspectLoader = useSelector(({ formBody }) => {
    return get(formBody, 'prospect.loading', false);
  });

  const onSubmit = async values => {
    if (!questionPage && showQuestionnaire) {
      if (offering?.payment_enabled)
        analytics({
          dataLayer: {
            event: 'interactionEvent',
            event_name: 'step_1_submit',
            email: values?.person?.email,
            phone: values?.person?.phone,
          },
        });
      return setQuestionPage(true);
    }
    const { is_joint } = values;

    let response = null;
    if (offering?.payment_enabled) {
      response = await dispatch(
        createIndividual(
          formatIndividualOrEntity({
            ...values,
            type: 'individual',
            offeringType: offering.type,
          }),
          is_joint
        )
      );
      analytics({
        dataLayer: {
          event: 'interactionEvent',
          event_name: 'step_2_submit',
          email: values?.person?.email,
          phone: values?.person?.phone,
        },
      });
    } else {
      const { person, joint_person } = values;

      response = await dispatch(
        createProspectInvestor(
          {
            offering: offering._id.toString(),
            person: formatEntityObject({ ...person }, true),
            ...(values.is_joint
              ? {
                  joint_person: formatEntityObject({ ...joint_person }, true),
                  joint_type: values.joint_type ? values.joint_type.value : '',
                  type: 'joint',
                }
              : {
                  person: formatEntityObject(person, true),
                  type: 'person',
                }),
          },
          //TYPE
          values.is_joint ? 'joint' : 'individual'
        )
      );
    }

    if (response) {
      return await redirectToPayment();
    }
  };
  const { amount, ...restInitialValues } = initialValues;

  const {
    register,
    watch,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({
    validate: ['onSubmit'],
    revalidate: ['onSubmit', 'onBlur', 'onChange'],

    defaultValues: injectDefaultValueIndividual(
      restInitialValues,
      prospectedByInvestorData
    ),
    resolver: yupResolver(
      getIndividualInvestorSchema(offering && offering.payment_enabled)
    ),
  });

  const isJointInvestor = watch('is_joint', false);

  useEffect(() => {
    const isProspectHasData =
      typeof prospectedByInvestorData === 'object'
        ? Object.keys(prospectedByInvestorData).length
        : false;

    /*
    During initial Render prospectInvestorData will be null 

    after fetch prospect via api there will be some Data present

    We need to re-render the page to show the new default values 

    if you delete this than it wont render.
    */

    if (isProspectHasData) {
      reset(
        injectDefaultValueIndividual(
          restInitialValues,
          prospectedByInvestorData
        )
      );
    }
    if (prospectedByInvestorData?.joint_type) {
      const findLabel = jointInvestorOptions.find(
        opt => opt.value === prospectedByInvestorData?.joint_type
      );

      setValue('is_joint', true);
      setValue('joint_type', findLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectedByInvestorData?.joint_type, prospectedByInvestorData]);

  return (
    <>
      <CanadaModal country={watch('person.country')?.label} />

      {questionPage ? (
        <InvestmentQuestion
          isLoading={onSubmitLoader || onSubmitProspectLoader}
          setQuestionPage={setQuestionPage}
          register={register}
          watch={watch}
          errors={errors}
          control={control}
          setValue={setValue}
          handleSubmit={handleSubmit(onSubmit)}
        />
      ) : (
        <div className="relative">
          {collapse ? (
            <label className="flex justify-start items-center w-fit-content mt-8">
              <Input
                type="checkbox"
                name="is_joint"
                data-testid="is_joint"
                wrapperClassName={isMaxWidth525px && 'extra-small-text'}
                label={'Are you investing jointly (i.e. with another person)?'}
                register={register}
                value={isJointInvestor}
                checked={isJointInvestor}
                disabled={investAsPrevious}
              />
            </label>
          ) : null}

          {isJointInvestor ? (
            <Select
              type={'joint_type'}
              wrapperClassName="mt-4"
              label="Joint Account Type:"
              id="joint_type"
              name="joint_type"
              control={control}
              error={errors?.joint_type ? errors.joint_type.message : null}
              options={jointInvestorOptions}
              disabled={investAsPrevious}
              setError={setError}
            />
          ) : null}
          <PersonForm
            type="person"
            investAsPrevious={investAsPrevious}
            register={register}
            getValues={getValues}
            watch={watch}
            errors={errors}
            control={control}
            setValue={setValue}
            setFocus={setFocus}
            setError={setError}
            isPresetProspected={prospectedByInvestorData.person}
          />
          {isJointInvestor ? (
            <div className="mt-10">
              <h2>Joint Investor Details</h2>
              <PersonForm
                showEmail
                type="joint_person"
                investAsPrevious={investAsPrevious}
                register={register}
                watch={watch}
                errors={errors}
                control={control}
                setValue={setValue}
                getValues={getValues}
                setError={setError}
              />
            </div>
          ) : null}
          <div
            className={cs({
              'mt-12': !isMaxWidth600px,
              'mt-10 mb-8 flex flex-col justify-center w-full relative': isMaxWidth600px,
            })}
          >
            <Button
              className={cs('float-right', {
                'flex items-center justify-center': isMaxWidth600px,
              })}
              data-testid="next"
              onClick={handleSubmit(onSubmit)}
              isDisabled={onSubmitLoader || onSubmitProspectLoader}
            >
              <h3>Next</h3>{' '}
              {onSubmitLoader || onSubmitProspectLoader ? (
                <LoadSpinner />
              ) : (
                <NextArrow />
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default IndividualInvestor;
