import * as Yup from 'yup';
import valid from 'card-validator';

const investmentInformationSchema = minimumAmount =>
  Yup.object({
    amount: Yup.number()
      .min(minimumAmount, `Minimum investment is ${minimumAmount} USD`)
      .required('Amount is Required'),
    units: Yup.number()
      .min(790, 'Minimum investment is 790 Units')
      .required('Units are Required'),
  });

const getCardType = number => {
  // visa
  const re = new RegExp('^4');
  if (number.match(re) != null) return 'VI';

  // Mastercard
  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number.replace(/-/g, '')
    )
  ) {
    return 'MC';
  }

  return '';
};

export const CreditCardFormSchema = Yup.object({
  billing_name: Yup.string()
    .required('Name is required.')
    .test(
      'ValidHolderName',
      'Not a valid Cardholder Name',
      val => valid.cardholderName(val).isValid
    )
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),

  credit_card_number: Yup.string()
    .required('Card number is required.')
    .test(
      'testCardMinLength',
      'Card number can not be less than 13 numbers',
      value => value && value.replace(/-/g, '').trim().length >= 13
    )
    .test(
      'testCardMaxLength',
      'Card number can not be longer than 16 numbers',
      value => value && value.replace(/-/g, '').trim().length <= 16
    )
    .test(
      'testCardMaxLength',
      'Only Visa & Mastercard is allowed',
      value => value && getCardType(value)
    )
    .test(
      'ValidCardNumber',
      'Not a valid Credit card number',
      val => valid.number(val).isValid
    ),
  credit_card_cvv: Yup.string()
    .required('CVV is required.')
    .test(
      'testCvvNumsOnly',
      'CVV must only contain numbers',
      value => value && /^[0-9]{1,45}$/.test(value)
    )
    .test(
      'testCvvLength',
      'CVV must be 3 numbers in length',
      value => value && value.length === 3
    )
    .test(
      'isValidCvvNumber',
      'Not a valid CVV number',
      value => value && valid.cvv(value).isValid
    ),
  credit_card_exp_month_year: Yup.string()
    .required('Expiration date is required.')
    .test('testMonthNumsOnly', 'Month must only contain numbers.', value => {
      const val = value && value.split('/')[0];
      return val && /^[0-9]{1,45}$/.test(val);
    })
    .test(
      'testMonthLength',
      'Expiration month can not be longer than 2 numbers',
      value => {
        const val = value && value.split('/')[0];
        return val && val.length < 3;
      }
    )
    .test(
      'testMonthMax',
      'Expiration month can not be larger than 12',
      value => {
        const val = value && value.split('/')[0];
        return val && parseInt(val) < 13;
      }
    )
    .test('testMonthMax', 'Year must only contain numbers.', value => {
      const val = value && value.split('/')[1];
      return val && /^[0-9]{1,45}$/.test(val);
    })
    .test('expiration-date', 'Not a valid expiration date', value => {
      const val = value && value.split('/')[1];
      return val && valid.expirationDate(value).isValid;
    })
    .test(
      'testMonthMax',
      'Expiration year must be exactly 2 or 4 number long.',
      value => {
        const val = value && value.split('/')[1];
        return val && [2, 4].includes(val.length);
      }
    ),
});

const isValidRoutingNumber = routingNumber => {
  if (!routingNumber) {
    // all 0's is technically a valid routing number, but it's inactive
    return false;
  }

  let routing = routingNumber.toString();
  while (routing.length < 9) {
    routing = `0${routing}`; // I refuse to import left-pad for this
  }

  const match = routing.match('^\\d{9}$');
  if (!match) {
    return false;
  }

  const firstTwo = parseInt(routing.substring(0, 2));
  const firstTwoValid =
    (firstTwo >= 0 && firstTwo <= 12) ||
    (firstTwo >= 21 && firstTwo <= 32) ||
    (firstTwo >= 61 && firstTwo <= 72) ||
    firstTwo === 80;
  if (!firstTwoValid) {
    return false;
  }

  const weights = [3, 7, 1];
  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum += parseInt(routing[i]) * weights[i % 3];
  }
  return (10 - (sum % 10)) % 10 === parseInt(routing[8]);
};

const isValidAccountNumber = accNumber => {
  if (!accNumber) {
    return false;
  }

  let acc = accNumber.toString();
  if (acc.length >= 6 && acc.length <= 17) {
    return true;
  }

  const match = acc.match('^[0-9]{6,17}$');
  if (!match) {
    return false;
  }
  return false;
};

export const ACHFormSchema = Yup.object({
  bank_name: Yup.string()
    .required('Bank name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  account_number: Yup.string()
    .required('Account Number is required.')
    .test(
      'testAccountNumberLength',
      'Account number can be from 6 to 17 digits',
      value => isValidAccountNumber(value)
    ),
  account_type: Yup.string().required('Account type is required.'),
  name_on_account: Yup.string()
    .required('Name is required.')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
  routing_number: Yup.string()
    .required('Routing Number is required.')
    .test('testRoutingNumberLength', 'Enter valid routing number', value =>
      isValidRoutingNumber(value)
    ),
});

export const CryptoFormSchema = Yup.object({
  payment_method: Yup.string().required('Please select crypto currency.'),
});

export default investmentInformationSchema;
