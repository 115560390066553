import BackArrowMobile from 'assets/images/back-arrow-mobile';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

import ReactCanvasConfetti from 'react-canvas-confetti';
import { CheckCircle } from 'react-feather';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { reset } from 'redux/actions/investor.action';
import { showSnackBar } from 'redux/actions/snackbar';
import styles from './InvestmentRefAndStatus.module.scss';
import cs from 'classnames';
import axios from 'axios';
import useQuery from 'helpers/useQuery';
import Prospect from './Prospect';
import { analytics } from 'helpers/gtm';
const canvasStyles = {
  position: 'absolute',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: '15%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
};

const InvestmentRefAndStatus = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const isCode = query.get('code');

  const [offeringIssueNameLoader, setOfferingIssueNameLoader] = useState(false);
  const [offeringIssueName, setOfferingIssueName] = useState(null);
  const fundMovement = useSelector(state =>
    get(state, 'investor.fundMovement.data', null)
  );
  const offeringName = useSelector(state => {
    return get(state, 'offering.data.issueName', null);
  });

  const account = useSelector(state => {
    return get(state, 'investor.account.data', null);
  });

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  const analyticsOnNewInvestor = useSelector(state => {
    return get(state, 'analytics.investment_success_on_new_investor', false);
  });

  let animationInstance;

  const makeShot = (particleRatio, opts) => {
    animationInstance &&
      animationInstance({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  };
  const getInstance = instance => {
    animationInstance = instance;
  };

  const fire = () => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };
  const handlerFire = () => {
    fire();
  };

  useEffect(() => {
    handlerFire();

    if (offering?.payment_enabled && analyticsOnNewInvestor) {
      analytics({
        dataLayer: {
          event: 'interactionEvent',
          event_name: 'investment_success',
          email: account?.email,
          phone: account?.phone,
          investor_id: account?.accountId,
          investmentAmount: fundMovement?.trade?.transactionAmount,
          investment_id: fundMovement?.trade?.tradeId,
          method: fundMovement?.trade?.transactionType,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsOnNewInvestor]);

  const getOfferingById = async data => {
    try {
      setOfferingIssueNameLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/offerings/getOfferingById`,
        data
      );

      setOfferingIssueName(response?.data?.issueName);
      setOfferingIssueNameLoader(false);
    } catch (err) {
      showSnackBar(
        'Please Contact invest@boxabl.com. Something went wrong with getting offeringName for mail/wire',
        'error'
      );
      setOfferingIssueNameLoader(false);
    }
  };

  //get real offeringName
  useEffect(() => {
    if (
      fundMovement?.trade?.transactionType === 'CHECK' ||
      fundMovement?.trade?.transactionType === 'WIRE'
    ) {
      if (fundMovement?.trade?.offeringId) {
        getOfferingById({
          offeringId: fundMovement?.trade?.offeringId,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      <div className={styles.backButton}>
        {offering?.payment_enabled && (
          <div
            className={styles.backButton__click}
            onClick={() => {
              dispatch(reset());
              history.push(`/${offeringName}/investor-information`);
            }}
          >
            <BackArrowMobile />
            <div className={styles.backButton__text}>Invest Again</div>
          </div>
        )}

        <div className={styles.backButton__mobileStatus}>Success!</div>
        <CheckCircle className={styles.checkCircle} />
      </div>
      {offering?.payment_enabled === false && <Prospect />}
      {offering?.payment_enabled && (
        <>
          <div className={styles.backButton__desktopStatus}>
            {isCode ? 'Verification' : 'Success!'}
          </div>
          {/* INVESTMENT REFERENCE AND DETAILS */}
          <div
            className={styles.paymentStatus}
            style={
              isCode
                ? {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }
                : {}
            }
          >
            <div className={styles.mobileVerification}>Verification</div>
            <div className={styles.investmentReference}>
              Investment ID: <span>{fundMovement?.trade?.tradeId}</span>
            </div>
            <div
              className={styles.paymentInfo}
              style={
                isCode
                  ? {
                      marginTop: '0px',
                    }
                  : {}
              }
            >
              {isCode ? null : (
                <div className={styles.paymentInfo__details}>
                  Your investment has been submitted. If you are paying by check
                  or wire transfer, instructions for sending funds will be sent
                  to the email address you provided. Credit card and ACH
                  payments are typically drawn from your account account in 1-2
                  days. You will be sent another email when your funds have been
                  received. If you have any questions or need further assistance
                  in funding your commitment, please contact{' '}
                  <a
                    href="mailto:invest@boxabl.com"
                    className="text-brandorange underline"
                  >
                    invest@boxabl.com
                  </a>
                </div>
              )}

              {offeringIssueNameLoader ? (
                <div className={styles.loaderContainer}>
                  <div className={styles.loaderContainer__text}>
                    <div className={styles.loaderContainer__text__title}>
                      Please Wait
                    </div>
                    <div className={styles.loaderContainer__text__sub}>
                      For Mailing instructions
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {isCode
                    ? null
                    : fundMovement?.trade?.transactionType === 'CHECK' && (
                        <div className="flex-col mt-4">
                          <h1
                            className={cs(
                              'text-brandblue mb-3',
                              styles.transferTitle
                            )}
                          >
                            Check Mailing Instructions
                          </h1>
                          <p className="text-brandblue mb-0 mt-2">
                            Check Mailing Address
                          </p>

                          <p className={styles.text}>
                            NORTH CAPITAL PRIVATE SECURITIES
                            <br />
                            623 E FORT UNION BLVD, SUITE 101
                            <br />
                            MIDVALE, UT 84047
                          </p>
                          <p className="text-brandblue mb-0 mt-2">
                            Check Mailing Instructions
                          </p>
                          <div className={styles.text}>
                            Please make checks payable to "The North Capital
                            Private Securities, FBO {offeringIssueName}" and
                            write your full name in the memo line.
                            <br />
                            <br />
                            {/* <div>
                              <div>
                                {' '}
                                Name: {account?.accountRegistration} &nbsp;{' '}
                                {'  '}
                              </div>
                              Investment ID: {fundMovement?.trade?.tradeId}
                              <div> Phone: {account?.phone} </div>
                              <div> Email: {account?.email}</div>
                            </div> */}
                          </div>
                        </div>
                      )}

                  {isCode
                    ? null
                    : fundMovement?.trade?.transactionType === 'WIRE' && (
                        <div className="flex-col mt-4">
                          <h1
                            className={cs(
                              'text-brandblue mb-3 underline',
                              styles.transferTitle
                            )}
                          >
                            Wire Instructions
                          </h1>
                          <p className="text-brandblue mb-0 mt-2">Bank Name:</p>

                          <p className={styles.text}>
                            TRISTATE CAPITAL BANK
                            <br />
                            ONE OXFORD CENTRE, SUITE 2700
                            <br />
                            301 GRANT STREET PITTSBURGH, PA 15219
                            <br />
                            (877) 304-0304
                          </p>
                          <br />
                          <br />
                          <div>
                            <div className="text-brandblue ">ACCOUNT NAME:</div>
                            {'   '}
                            <div>
                              NORTH CAPITAL PRIVATE SECURITIES
                              <br />
                              623 E FORT UNION BLVD, SUITE 101 MIDVALE, UT 84047
                              <br />
                              (888) 625-7768
                            </div>
                          </div>
                          <br />
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div className="text-brandblue ">
                              Account Number:
                            </div>
                            {'   '}
                            <div>&nbsp; 0220003339</div>
                          </div>

                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div className="text-brandblue ">
                              Routing Number:
                            </div>
                            {'   '}
                            <div>&nbsp; 043019003</div>
                          </div>
                          <br />

                          <br />
                          <div>
                            <div className="text-brandblue ">
                              For Further Credit To: &nbsp;
                              <span style={{ fontWeight: '600' }}>
                                {offeringIssueName}
                              </span>
                              ,&nbsp; {account?.accountRegistration}
                            </div>
                          </div>
                          <br />
                        </div>
                      )}
                </>
              )}

              {fundMovement?.trade?.transactionType === 'ACH' &&
                fundMovement?.externalAccount && (
                  <div className={styles.ach}>
                    <div className={styles.ach__title}>
                      ACH Transfer Details
                    </div>
                    <div className={styles.ach__detail}>
                      Bank Name:{' '}
                      <span> {fundMovement?.externalAccount?.ExtBankname}</span>{' '}
                    </div>

                    <div className={styles.ach__detail}>
                      Name on Bank Account:{' '}
                      <span>
                        {' '}
                        {fundMovement?.externalAccount?.ExtAccountfullname}
                      </span>{' '}
                    </div>
                    <div className={styles.ach__detail}>
                      Account Number:{' '}
                      <span>
                        {' '}
                        ********
                        {fundMovement?.externalAccount?.accountnumber}
                      </span>{' '}
                    </div>
                    <div className={styles.ach__detail}>
                      Routing Number:{' '}
                      <span>
                        {' '}
                        ********
                        {fundMovement?.externalAccount?.routingnumber}
                      </span>{' '}
                    </div>
                    <div className={styles.ach__detail}>
                      Amount:{' '}
                      <span> ${fundMovement?.externalFundMove?.amount}</span>{' '}
                    </div>
                  </div>
                )}
              {isCode ? null : (
                <>
                  <div className={styles.paymentInfo__sincerely}>
                    Sincerely,
                  </div>

                  <div
                    className={styles.paymentInfo__paolo_tiraman}
                    style={{ marginBottom: '60px' }}
                  >
                    {' '}
                    Paolo Tiramani
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InvestmentRefAndStatus;
