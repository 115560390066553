import React from 'react';
import cs from 'classnames';
import DollarSign from '../../assets/images/dollar-sign';
import { NumberMask } from 'components/Input/NumberMask';

import styles from './investorInformation.module.scss';
import { useMedia } from 'react-use';

import Share from './Share';
import useQuery from 'helpers/useQuery';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const Amount = ({
  unitPrice,
  errors,
  control,
  minimumInvestment,
  handleChange,
  share,
  usersInvestment,
  transactionTypes,
  amount,
}) => {
  const fundMovementData = useSelector(state =>
    get(state, 'investor.fundMovement.data', null)
  );

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });
  const query = useQuery();
  // Media query (600px)
  const isMaxWidth600px = useMedia('(max-width:600px)');

  // Media query (1555px)
  const isMaxWidth1555px = useMedia('(max-width:1555px)');

  return (
    <div
      className={`${styles.investmentInformation_inputInvestments} ${
        !isMaxWidth600px ? 'mt-8' : 'mt-4'
      }`}
    >
      <div className="flex-col">
        {query.get('code') ? (
          <div style={{ color: '#174271', fontSize: '18px' }}>
            Please Resubmit Your Payment For Our{' '}
            {fundMovementData?.trade?.offeringName}. Offering.
          </div>
        ) : (
          <p
            className={cs(
              errors?.amount
                ? styles.investmentInformation_paragraph_red
                : styles.investmentInformation_paragraph,

              'mb-3'
            )}
          >
            {offering?.payment_enabled
              ? 'How much do you wish to invest in this offering? (USD)'
              : 'How much would you like to invest in a future offering.'}
          </p>
        )}

        <div
          className={`flex flex-col ${isMaxWidth1555px &&
            'flex-wrap'} lg:flex-row items-end`}
        >
          {query.get('code') ? null : (
            <div
              className={cs(
                'border m-0',
                styles.investmentInformation_inputFields,
                {
                  [styles.investmentInformation_inputFields__hasError]:
                    errors?.amount?.message,
                }
              )}
              onClick={() => {
                if (document.getElementById('amount')) {
                  document.getElementById('amount').focus();
                }
              }}
            >
              <DollarSign />

              <NumberMask
                data-testid={'user_amount'}
                id="amount"
                name="amount"
                type="text"
                thousandSeparator
                placeholder={
                  minimumInvestment ? minimumInvestment : 10000 || 10000
                }
                className={`flex-1 outline-none`}
                inputClassname={`flex-1`}
                control={control}
                onValueChange={handleChange}
              />
              <p
                className={cs(styles.investmentInformation_minimum, {
                  [styles.investmentInformation_minimum__hasError]: errors?.amount
                    ? errors.amount.message
                    : '',
                })}
              >
                {offering?.payment_enabled
                  ? 'Minimum investment is '
                  : 'Minimum commitment '}
                {minimumInvestment ? minimumInvestment : 10000 || 10000} USD
              </p>
            </div>
          )}

          <Share
            share={share}
            usersInvestment={usersInvestment}
            transactionTypes={transactionTypes}
            minimumInvestment={minimumInvestment}
          />
        </div>
      </div>
    </div>
  );
};

export default Amount;
