import React from 'react';
import cs from 'classnames';
import styles from './question.module.scss';
import AnnualIncome from './AnualIncome';
import { useMedia } from 'react-use';
import Button from 'components/Button';
import NextArrow from 'assets/images/next-arrow';
import BackArrow from 'assets/images/back-arrow';
import BackArrowMobile from 'assets/images/back-arrow-mobile';
import LoadSpinner from 'assets/images/load-spinner';
import StepNumber from 'components/StepNumber/StepNumber';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import formatEntityObject from 'helpers/formatEntityObject';
import { createProspectInvestor } from 'redux/actions/formBodyActions';
import { showSnackBar } from 'redux/actions/snackbar';

const InvestmentQuestion = ({
  setQuestionPage,
  isLoading,
  register,
  watch,
  errors,
  control,
  setValue,
  handleSubmit,
}) => {
  let history = useHistory();
  const isAccredited = watch('person.accredited');

  const media_query = useMedia('(max-width:600px)');
  const dispatch = useDispatch();

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });
  //hello worlds
  return (
    <div className={styles.root}>
      {isLoading ? (
        <div className="w-full h-full flex justify-center">
          <div className="mt-10 w-full h-full flex items-center flex-col">
            <LoadSpinner />
            <span className="text-brandgrey mt-3">
              Please wait. We are redirecting you to the payment page.
            </span>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`${styles.titleHeader} ${media_query && 'shadow-sm'}`}
          >
            <Button
              className={styles.backBtn}
              onClick={() => {
                setQuestionPage(false);
                setValue('person.accredited', true);
              }}
            >
              {media_query ? <BackArrowMobile /> : <BackArrow />}
              <h3>Back</h3>
            </Button>
            <div>
              <StepNumber />
              <h1 className={cs(styles.titleText, { 'mb-3': !media_query })}>
                Investor Questionnaire
              </h1>
            </div>
          </div>
          <div style={media_query ? { marginBottom: '75px' } : {}}>
            <p className={styles.paragraph}>
              This information is required for us to comply with SEC and state
              securities regulations. We ask the following questions to
              determine if the amount you may invest is limited by law.
            </p>
            <div className={`${styles.container} mt-3`}>
              <p className={styles.mainParagraph}>
                Are you an accredited investor (you earn over $200,000 per year,
                have a net worth of $1m or more, or are an institutional
                investor)?&nbsp;
                <a
                  target="_blank"
                  href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3"
                  rel="noopener noreferrer"
                  className="underline text-brandorange"
                >
                  Learn more here.
                </a>
              </p>

              <p className={styles.mainParagraph}>
                We will ask you to submit documents proving that you are an
                accredited investor after you complete your investment.
              </p>

              <div className={styles.containerAccredited}>
                <input
                  type="radio"
                  id="accredited"
                  name="accredited"
                  value="yes"
                  /*label="Yes"*/
                  control={control}
                  checked={watch('person')?.accredited === true}
                  onChange={() => {
                    setValue('person.accredited', true);
                    setValue(
                      'person.accreditation_type',
                      'individual_net_worth'
                    );
                    setValue('person.annual_income', '0');
                    setValue('person.net_worth', '0');
                  }}
                />
                <label htmlFor="accredited">Yes</label>

                <div className="mx-3">
                  <input
                    type="radio"
                    id="not_accredited"
                    name="accredited"
                    control={control}
                    checked={watch('person')?.accredited === false}
                    onChange={() => {
                      setValue('person.accredited', false);
                      setValue('person.accreditation_type', null);
                    }}
                  />
                  <label htmlFor="not_accredited">No</label>
                </div>
              </div>
            </div>

            {!isAccredited && (
              <div className={styles.notAccredited}>
                <div>
                  This offering is open to accredited investors only. Click the
                  button below to switch offerings if you are not accredited.
                </div>
                <div className={styles.notAccreditedButton}>
                  <Button
                    onClick={async () => {
                      const { invested_as, person, ...values } = watch();
                      let response = null;
                      if (invested_as === 'individual') {
                        response = await dispatch(
                          createProspectInvestor(
                            {
                              offering: offering._id.toString(),
                              person: formatEntityObject({ ...person }, true),
                              ...(values.is_joint
                                ? {
                                    joint_person: formatEntityObject(
                                      { ...values.joint_person },
                                      true
                                    ),
                                    joint_type: values.joint_type
                                      ? values.joint_type.value
                                      : '',
                                    type: 'joint',
                                  }
                                : {
                                    person: formatEntityObject(person, true),
                                    type: 'person',
                                  }),
                            },
                            //TYPE
                            values.is_joint ? 'joint' : 'individual'
                          )
                        );
                      }

                      if (invested_as !== 'individual') {
                        response = await dispatch(
                          createProspectInvestor(
                            {
                              person: formatEntityObject(person, true),
                              [`${invested_as}`]: formatEntityObject(
                                values[`${invested_as}`],
                                false,
                                true
                              ),
                              offering: offering._id.toString(),
                              type: invested_as,
                            },
                            invested_as
                          )
                        );
                      }

                      if (response) {
                        history.push(`/reg_a_ttw/payment`);
                        return;
                      }

                      showSnackBar('Something went wrong', 'error');
                    }}
                    style={{ textAlign: 'center' }}
                  >
                    Go to CrowdFunding Offering
                  </Button>

                  <div className={styles.notAccreditedButton__subtext}>
                    (open to everyone)
                  </div>
                </div>
              </div>
            )}

            <Button
              style={
                isAccredited
                  ? {}
                  : {
                      backgroundColor: '#EBEBE4',
                      transition: 'all .3s ease-in',
                    }
              }
              data-testid={'next-question'}
              className={cs('float-right', styles.nextBtn)}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Next {isLoading ? <LoadSpinner /> : <NextArrow />}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default InvestmentQuestion;
