import React from 'react';
import { Tooltip } from 'react-tippy';
import { CheckCircle, Info } from 'react-feather';
import NumberFormat from 'react-number-format';
import cs from 'classnames';
import { useMedia } from 'react-use';
import styles from './investorInformation.module.scss';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const Share = ({
  share,
  usersInvestment,
  transactionTypes,
  minimumInvestment,
}) => {
  const isMaxWidth1555px = useMedia('(max-width:1555px)');

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  return (
    share &&
    usersInvestment &&
    usersInvestment >= minimumInvestment && (
      <div
        className={`flex flex-col lg:flex-row w-full items-end ${!isMaxWidth1555px &&
          'ml-3'}  mt-3 lg:mt-3`}
      >
        <React.Fragment>
          <div className="flex-col w-full">
            <div className="text-brandblue mb-1">
              Your investment {offering?.payment_enabled ? null : 'commitment '}
              has been rounded to the nearest 100 shares.
              <Tooltip
                arrow
                title={`Shares of Boxabl can only be purchased in increments of 100.
                `}
                position="top"
              >
                <span className="ml-1">
                  <Info className="inline text-brandorange" />
                </span>
              </Tooltip>
            </div>

            <div
              className={cs(
                'flex  w-full justify-between border border-brandorange bg-white py-1 px-4 shadow-md rounded'
              )}
            >
              <div className="flex-col">
                <div
                  className={styles.share}
                  style={
                    offering?.payment_enabled === false
                      ? {} //IF PAYMENT NOT ENABLED THEN SHOW NO RED COLOR ON SHARE
                      : (transactionTypes === 'credit_card' &&
                          usersInvestment > 5000) ||
                        (transactionTypes === 'ach' &&
                          usersInvestment >= 100000)
                      ? { color: 'red' }
                      : {}
                  }
                >
                  $
                  <NumberFormat
                    data-testid={'amount'}
                    value={usersInvestment}
                    displayType={'text'}
                    thousandSeparator={true}
                  />
                </div>
                <div className="w-100 text-sm text-brandgrey">
                  <NumberFormat
                    data-testid={'units'}
                    value={share}
                    displayType={'text'}
                    thousandSeparator={true}
                  />{' '}
                  Shares
                </div>
              </div>
              <div className="flex items-center ml-3">
                <CheckCircle className={styles.fundTransfering_checkIcon} />
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    )
  );
};

export default Share;
