import useQuery from 'helpers/useQuery';
import React from 'react';

const AccreditedDocumentText = ({ account }) => {
  const query = useQuery();

  const scrollTo = query.get('scrollTo');

  return scrollTo !== 'ai' ? (
    <div className="mt-4">
      <h3 className="text-brandorange">Document Acceptance Criteria:</h3>
      <ul style={{ listStyle: 'inside' }}>
        <li className="text-brandblue my-3">
          W2's, 1099's or tax returns for the past two years to show your
          individual income exceeds $200,000 (or joint income exceeds $300,000)
          for each year.
        </li>
        <li className="text-brandblue my-3">
          Provide recent account statements or third party appraisals that show
          the value of your assets exceed $1,000,000 excluding your primary
          residence. (Must be dated within the last 3 months)
        </li>

        <li className="text-brandblue my-3">
          Provide an official written communication from any of the following
          stating that the professional service provider has a reasonable belief
          that you are an Accredited Investor. (Must be dated within the last 3
          months)
        </li>

        <ul className="text-brandblue my-3" style={{ listStyle: 'inside' }}>
          <li
            style={{
              marginLeft: '40px',
              fontSize: '15px',
              marginTop: '5px',
              listStyleType: 'square',
            }}
          >
            Must be from a licensed CPA, Attorney, Investment Advisor, or a
            Registered broker-dealer.
          </li>
        </ul>
      </ul>
    </div>
  ) : account?.reviewBy === 'Boxabl' && account?.aiNotes === null ? (
    <div className="mt-4">
      <h3 className="text-brandorange">Document Acceptance Criteria:</h3>
      <ul style={{ listStyle: 'inside' }}>
        <li className="text-brandblue my-3">
          W2's, 1099's or tax returns for the past two years to show your
          individual income exceeds $200,000 (or joint income exceeds $300,000)
          for each year.
        </li>
        <li className="text-brandblue my-3">
          Provide recent account statements or third party appraisals that show
          the value of your assets exceed $1,000,000 excluding your primary
          residence. (Must be dated within the last 3 months)
        </li>

        <li className="text-brandblue my-3">
          Provide an official written communication from any of the following
          stating that the professional service provider has a reasonable belief
          that you are an Accredited Investor. (Must be dated within the last 3
          months)
        </li>

        <ul className="text-brandblue my-3" style={{ listStyle: 'inside' }}>
          <li
            style={{
              marginLeft: '40px',
              fontSize: '15px',
              marginTop: '5px',
              listStyleType: 'square',
            }}
          >
            Must be from a licensed CPA, Attorney, Investment Advisor, or a
            Registered broker-dealer.
          </li>
        </ul>
      </ul>
    </div>
  ) : null;
};

export default AccreditedDocumentText;
