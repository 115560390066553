import {
  achInvestment,
  agreement,
  checkInvestment,
  createInvestor,
  creditCardInvestment,
  cryptoInvestment,
  getEntityById,
  getInvestorById,
  uploadDocuments,
  wireInvestment,
  createProspect,
  updateProspect,
  validatePaymentCode,
} from '../api/investmentApi';
import { v4 as uuidv4 } from 'uuid';

import { validateProspectedCode } from 'redux/api/investmentApi';
import { toast } from 'react-toastify';
import { showSnackBar } from './snackbar';
import isObject from 'lodash/isObject';
import { indentifyInLogRocket } from 'helpers/logrocket';
export const PAYMENT_DETAILS = 'PAYMENT_DETAILS';
export const PRICE_VALUE = 'PRICE_VALUE';
export const SET_INVESTOR_INFO = 'SET_INVESTOR_INFO';
export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO';
export const SET_INVESTMENT_INFO = 'SET_INVESTMENT_INFO';
export const SET_ADDRESS_INFO = 'SET_ADDRESS_INFO';
export const SET_INVESTMENT_CRITERIA_INFO = 'SET_INVESTMENT_CRITERIA_INFO';
export const CREATE_INVESTOR_INPROGRESS = 'CREATE_INVESTOR_INPROGRESS';
export const CREATE_INVESTOR_SUCCESS = 'CREATE_INVESTOR_SUCCESS';
export const CREATE_INVESTOR_FAILURE = 'CREATE_INVESTOR_FAILURE';
export const CREATE_INVESTMENT_INPROGRESS = 'CREATE_INVESTMENT_INPROGRESS';
export const CREATE_INVESTMENT_SUCCESS = 'CREATE_INVESTMENT_SUCCESS';
export const CREATE_INVESTMENT_FAILURE = 'CREATE_INVESTMENT_FAILURE';
export const CREATE_AGREEMENT_INPROGRESS = 'CREATE_AGREEMENT_INPROGRESS';
export const CREATE_AGREEMENT_SUCCESS = 'CREATE_AGREEMENT_SUCCESS';
export const CREATE_AGREEMENT_FAILURE = 'CREATE_AGREEMENT_FAILURE';
export const GET_PREVIOUS_ENTITIES_INPROGRESS =
  'GET_PREVIOUS_ENTITIES_INPROGRESS';
export const GET_PREVIOUS_ENTITIES_SUCCESS = 'GET_PREVIOUS_ENTITIES_SUCCESS';
export const GET_PREVIOUS_ENTITIES_FAILURE = 'GET_PREVIOUS_ENTITIES_FAILURE';
export const FETCH_ENTITY_INPROGRESS = 'FETCH_ENTITY_INPROGRESS';
export const FETCH_ENTITY_SUCCESS = 'FETCH_ENTITY_SUCCESS';
export const FETCH_ENTITY_FAILURE = 'FETCH_ENTITY_FAILURE';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_INVESTOR_INPROGRESS = 'FETCH_INVESTOR_INPROGRESS';
export const FETCH_INVESTOR_SUCCESS = 'FETCH_INVESTOR_SUCCESS';
export const FETCH_INVESTOR_FAILURE = 'FETCH_INVESTOR_FAILURE';
export const GET_OFFERING_QUERY = 'GET_OFFERING_QUERY';
export const SET_OFFERING_SLUG = 'SET_OFFERING_SLUG';
export const FETCH_OFFERING_INPROGRESS = 'FETCH_OFFERING_INPROGRESS';
export const FETCH_OFFERING_SUCCESS = 'FETCH_OFFERING_SUCCESS';
export const FETCH_OFFERING_FAILURE = 'FETCH_OFFERING_FAILURE';
export const SET_CARD_ERROR = 'SET_CARD_ERROR';
export const UPLOAD_DOCUMENTS_INPROGRESS = 'UPLOAD_DOCUMENTS_INPROGRESS';
export const UPLOAD_DOCUMENTS_SUCCESS = 'UPLOAD_DOCUMENTS_SUCCESS';
export const UPLOAD_DOCUMENTS_FAILURE = 'UPLOAD_DOCUMENTS_FAILURE';
export const UPDATE_DOCUMENT_LOADER = 'UPDATE_DOCUMENT_LOADER';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const CREATE_PROSPECT_INPROGRESS = 'CREATE_PROSPECT_INPROGRESS';
export const CREATE_PROSPECT_SUCCESS = 'CREATE_PROSPECT_SUCCESS';
export const CREATE_PROSPECT_FAILURE = 'CREATE_PROSPECT_FAILURE';
export const UPDATE_PROSPECT_INPROGRESS = 'UPDATE_PROSPECT_INPROGRESS';
export const UPDATE_PROSPECT_SUCCESS = 'UPDATE_PROSPECT_SUCCESS';
export const UPDATE_PROSPECT_FAILURE = 'UPDATE_PROSPECT_FAILURE';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAILURE = 'VALIDATE_TOKEN_FAILURE';
export const FETCH_PROSPECT_BY_INVESTOR_DETAILS_INPROGRESS =
  'FETCH_PROSPECT_BY_INVESTOR_DETAILS_INPROGRESS';
export const FETCH_PROSPECT_BY_INVESTOR_DETAILS_SUCCESS =
  'FETCH_PROSPECT_BY_INVESTOR_DETAILS_SUCCESS';
export const FETCH_PROSPECT_BY_INVESTOR_DETAILS_FAILURE =
  'FETCH_PROSPECT_BY_INVESTOR_DETAILS_FAILURE';

export const VALIDATE_TOKEN_BY_PAYMENT_INPROGRESS =
  'VALIDATE_TOKEN_BY_PAYMENT_INPROGRESS';
export const VALIDATE_TOKEN_BY_PAYMENT_SUCCESS =
  'VALIDATE_TOKEN_BY_PAYMENT_SUCCESS';
export const VALIDATE_TOKEN_BY_PAYMENT_FAILURE =
  'VALIDATE_TOKEN_BY_PAYMENT_FAILURE';

export const RESET_FORM_BODY_DETAILS = 'RESET_FORM_BODY_DETAILS';
export const SET_REFERRAL_DETAILS = 'SET_REFERRAL_DETAILS';

// Set error for card payment if you get an error
export const setCardError = () => ({
  type: SET_CARD_ERROR,
});

// Payment choice (Funds Transfering)
export const updatePaymentDetails = data => ({
  type: PAYMENT_DETAILS,
  data,
});

export const createWireInvestment = data => async dispatch => {
  try {
    dispatch({
      type: CREATE_INVESTMENT_INPROGRESS,
    });

    const resp = await wireInvestment(data);

    if (resp.status) {
      dispatch({
        type: CREATE_INVESTMENT_SUCCESS,
        data: resp.data,
      });
    } else {
      dispatch({
        type: CREATE_INVESTMENT_FAILURE,
      });
      toast('Something went wrong!', { type: 'error' });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: CREATE_INVESTMENT_FAILURE,
      message: 'Something went wrong!',
    });
    toast('Something went wrong!', { type: 'error' });
  }
};

export const createCheckInvestment = data => async dispatch => {
  try {
    dispatch({
      type: CREATE_INVESTMENT_INPROGRESS,
    });
    const resp = await checkInvestment(data);

    if (resp.status) {
      dispatch({
        type: CREATE_INVESTMENT_SUCCESS,
        data: resp.data,
      });
    } else {
      dispatch({
        type: CREATE_INVESTMENT_FAILURE,
      });
      toast('Something went wrong!', { type: 'error' });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: CREATE_INVESTMENT_FAILURE,
      message: 'Something went wrong!',
    });
    toast('Something went wrong!', { type: 'error' });
  }
};

export const createAchInvestment = data => async dispatch => {
  try {
    dispatch({
      type: CREATE_INVESTMENT_INPROGRESS,
    });
    const resp = await achInvestment(data);

    if (resp.status) {
      dispatch({
        type: CREATE_INVESTMENT_SUCCESS,
        data: resp.data,
      });
    } else {
      dispatch({
        type: CREATE_INVESTMENT_FAILURE,
      });
      toast('Something went wrong!', { type: 'error' });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: CREATE_INVESTMENT_FAILURE,
      message: 'Something went wrong!',
    });
    toast('Something went wrong!', { type: 'error' });
  }
};

export const createCryptoInvestment = data => async dispatch => {
  try {
    dispatch({
      type: CREATE_INVESTMENT_INPROGRESS,
    });
    const resp = await cryptoInvestment(data);

    if (resp.status) {
      dispatch({
        type: CREATE_INVESTMENT_SUCCESS,
        data: resp.data,
      });
    } else {
      dispatch({
        type: CREATE_INVESTMENT_FAILURE,
      });
      toast('Something went wrong!', { type: 'error' });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: CREATE_INVESTMENT_FAILURE,
      message: 'Something went wrong!',
    });
    toast('Something went wrong!', { type: 'error' });
  }
};
//CREDIT CARD
export const createCreditCardInvestment = data => async dispatch => {
  try {
    dispatch({
      type: CREATE_INVESTMENT_INPROGRESS,
    });
    const resp = await creditCardInvestment(data);
    if (resp.status) {
      dispatch({
        type: CREATE_INVESTMENT_SUCCESS,
        data: resp.data,
      });
    } else {
      if (isObject(resp.errors)) {
        let message = '';

        Object.values(resp.errors).forEach((e, i) => {
          for (const [key, value] of Object.entries(e)) {
            if (isNaN(key)) {
              // some object values are arrays
              if (key === 'credit_card_number') {
                message += 'Your credit card number' + ' ';
              } else {
                message += key + ' ';
              }
            }
            if (value === 'must be at least 18-years-old') {
              message +=
                'From your age input in STEP 1. You must be at least 18 years old';
            } else {
              message += value + ' ';
            }
          }
        });
        showSnackBar(message, 'error');
      } else {
        showSnackBar(JSON.stringify(resp.errors), 'error');
      }
    }
    return resp;
  } catch (error) {
    showSnackBar(JSON.stringify(error.response), 'error');
  }
};

export const createAgreement = data => async dispatch => {
  try {
    dispatch({
      type: CREATE_AGREEMENT_INPROGRESS,
    });
    const resp = await agreement(data);

    if (resp.status) {
      dispatch({
        type: CREATE_AGREEMENT_SUCCESS,
        data: resp.data,
      });
    } else {
      dispatch({
        type: CREATE_AGREEMENT_FAILURE,
      });
      toast('Something went wrong!', { type: 'error' });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: CREATE_AGREEMENT_FAILURE,
      message: 'Something went wrong!',
    });
    toast('Something went wrong!', { type: 'error' });
  }
};

export const fetchEntity = id => async dispatch => {
  try {
    dispatch({
      type: FETCH_ENTITY_INPROGRESS,
    });
    const resp = await getEntityById(id);
    if (resp.status) {
      dispatch({
        type: FETCH_ENTITY_SUCCESS,
        data: resp.data,
      });
    } else {
      dispatch({
        type: FETCH_ENTITY_FAILURE,
      });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: FETCH_ENTITY_FAILURE,
      message: 'Something went wrong!',
    });
  }
};

export const fetchInvestor = id => async dispatch => {
  try {
    dispatch({
      type: FETCH_INVESTOR_INPROGRESS,
    });
    const resp = await getInvestorById(id);
    if (resp.status) {
      dispatch({
        type: FETCH_INVESTOR_SUCCESS,
        data: resp.data,
      });
    } else {
      dispatch({
        type: FETCH_INVESTOR_FAILURE,
      });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: FETCH_INVESTOR_FAILURE,
      message: 'Something went wrong!',
    });
  }
};

export const createEntityAndInvestor = (data, type) => async dispatch => {
  try {
    dispatch({
      type: CREATE_INVESTOR_INPROGRESS,
    });
    const resp = await createInvestor(data, type);
    if (resp && resp.status) {
      dispatch({
        type: CREATE_INVESTOR_SUCCESS,
        data: resp.data.investor,
      });
      dispatch({
        type: FETCH_ENTITY_SUCCESS,
        data: resp.data.entity,
      });
      dispatch({
        type: FETCH_USER_SUCCESS,
        data: resp.data.user,
      });

      indentifyInLogRocket(resp.data.user._id, resp.data.entity);
    } else {
      indentifyInLogRocket(uuidv4(), {
        name: data.person.name,
        email: data.person.email,
        phone: data.person.phone,
        type: type,
      });

      dispatch({
        type: CREATE_INVESTOR_FAILURE,
      });
      if ('tax_id_number' in resp.errors) {
        showSnackBar(
          'Your Social Security Number or EIN number may be incorrect',
          'error'
        );
      } else {
        showSnackBar(JSON.stringify(resp.errors), 'error');
      }
    }
    return resp;
  } catch (error) {
    indentifyInLogRocket(uuidv4(), {
      name: data.person.name,
      email: data.person.email,
      phone: data.person.phone,
      type: type,
    });
    dispatch({
      type: CREATE_INVESTOR_FAILURE,
      message: 'Something went wrong!',
    });

    showSnackBar('Email invest@boxabl.com', 'error');
  }
};

export const uploadVerificationDocuments = (
  data,
  callback,
  requestCallback,
  uuid
) => async dispatch => {
  try {
    dispatch({
      type: UPLOAD_DOCUMENTS_INPROGRESS,
    });
    const resp = await uploadDocuments(data, callback);
    requestCallback(resp);

    if (resp && resp.status) {
      dispatch({
        type: UPLOAD_DOCUMENTS_SUCCESS,
        data: {
          uuid,
          data: resp.data[0],
        },
      });
    } else {
      dispatch({
        type: UPLOAD_DOCUMENTS_FAILURE,
      });
      requestCallback({ status: 0, message: 'Something went wrong!' });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: UPLOAD_DOCUMENTS_FAILURE,
      message: 'Something went wrong!',
    });
    requestCallback({ status: 0, message: 'Something went wrong!' });
  }
};

export const updateDocumentLoader = (id, data) => ({
  type: UPDATE_DOCUMENT_LOADER,
  data: {
    id,
    data,
  },
});

export const createProspectInvestor = (data, type) => async dispatch => {
  try {
    dispatch({
      type: CREATE_PROSPECT_INPROGRESS,
    });

    indentifyInLogRocket(uuidv4(), {
      name: `${data?.person?.firstName} ${data?.person?.lastName}`,
      email: data?.person?.email,
      phone: data?.person?.phone,
      type: type,
      is: 'prospect',
      paymentEnabled: 'no',
    });
    const resp = await createProspect(data, type);

    if (resp && resp.status) {
      dispatch({
        type: CREATE_PROSPECT_SUCCESS,
        data: resp.data,
      });
    } else {
      showSnackBar(resp.message, 'error');
      dispatch({
        type: CREATE_PROSPECT_FAILURE,
      });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: CREATE_PROSPECT_FAILURE,
      message: 'Something went wrong!',
    });
  }
};

export const updateProspectData = (id, data) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_PROSPECT_INPROGRESS,
    });
    const resp = await updateProspect(id, data);
    if (resp && resp.status) {
      dispatch({
        type: UPDATE_PROSPECT_SUCCESS,
        data: resp.data,
      });
    } else {
      dispatch({
        type: UPDATE_PROSPECT_FAILURE,
      });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: UPDATE_PROSPECT_FAILURE,
      message: 'Something went wrong!',
    });
  }
};

export const fetchValidatePayment = code => async dispatch => {
  {
    try {
      dispatch({
        type: VALIDATE_TOKEN_BY_PAYMENT_INPROGRESS,
      });

      const resp = await validatePaymentCode(code);

      if (resp.status) {
        if (resp.error) {
          dispatch({
            type: VALIDATE_TOKEN_BY_PAYMENT_FAILURE,
            data: resp?.message,
          });

          return;
        }
        dispatch({
          type: VALIDATE_TOKEN_BY_PAYMENT_SUCCESS,
          data: resp.data,
        });
      } else {
        dispatch({
          type: VALIDATE_TOKEN_BY_PAYMENT_FAILURE,
          data: resp?.message,
        });
      }
      return resp;
    } catch (e) {
      dispatch({
        type: VALIDATE_TOKEN_BY_PAYMENT_FAILURE,
        message: 'Something went wrong!',
      });
    }
  }
};

export const fetchValidateProspectedCode = code => async dispatch => {
  try {
    dispatch({
      type: FETCH_PROSPECT_BY_INVESTOR_DETAILS_INPROGRESS,
    });
    const resp = await validateProspectedCode(code);

    if (resp.status) {
      dispatch({
        type: FETCH_PROSPECT_BY_INVESTOR_DETAILS_SUCCESS,
        data: resp.data,
      });
    } else {
      dispatch({
        type: FETCH_PROSPECT_BY_INVESTOR_DETAILS_FAILURE,
        data: resp.data,
      });
      toast(resp?.message || 'Something went wrong!', { type: 'error' });
    }
    return resp;
  } catch (e) {
    dispatch({
      type: FETCH_PROSPECT_BY_INVESTOR_DETAILS_FAILURE,
      message: 'Something went wrong!',
    });
    toast('Something went wrong!', { type: 'error' });
  }
};

export const setReferralCode = referral => dispatch => {
  dispatch({
    type: SET_REFERRAL_DETAILS,
    data: referral,
  });
};

export const reSetFormBodyData = () => async dispatch => {
  dispatch({
    type: RESET_FORM_BODY_DETAILS,
    data: {},
  });
};
