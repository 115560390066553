import React from 'react';
import Modal from 'components/Modal/Modal';
import { useMedia } from 'react-use';
import Button from 'components/Button';
import styles from './CanadaModal.module.scss';
import { useParams } from 'react-router-dom';
const CanadaModal = ({ country }) => {
  const { offeringName } = useParams();
  const [showModal, setShowModal] = React.useState(false);

  const isMobileView = useMedia('(max-width:600px)');

  React.useEffect(() => {
    // only applies to reg_a_ttw
    if (offeringName !== 'reg_a_ttw') return;

    if (country === 'Canada') {
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <Modal
      handleCloseClick={() => {
        setShowModal(false);
      }}
      isModalOutsideClose={true}
      isOpen={showModal}
      width={isMobileView ? '100%' : '50%'}
    >
      <div style={{ padding: '15px' }} className="flex flex-col ">
        <br />

        <div>
          <h2 className="text-2xl font-bold text-brandblue">
            Canada-Only Offering
          </h2>
          <hr className="border-brandorange m-0 border-t-0 border-b-4 w-16" />
        </div>
        <h3 className="m-auto text-lg text-center mt-2">
          <div> </div>
          <br />
          Switch to our exclusive Canadian offering hosted by FrontFundr.
        </h3>
        <br />
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              window.open('https://www.frontfundr.com/boxabl', '_blank');
            }}
            className={styles.button}
          >
            Go To Canada Offering
          </Button>
          <p
            onClick={() => {
              setShowModal(false);
            }}
          >
            Go back
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default CanadaModal;
