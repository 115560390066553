import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import useQuery from 'helpers/useQuery';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const query = useQuery();

  let location = useLocation();

  const accountData = useSelector(({ investor }) => {
    return get(investor, 'account.data', null);
  });
  const prospectData = useSelector(state => {
    return get(state, 'formBody.prospect.data', null);
  });

  const fundMovementData = useSelector(({ investor }) => {
    return get(investor, 'fundMovement.data', null);
  });

  const offeringName = useSelector(state => {
    return get(state, 'offering.data.issueName', null);
  });

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  const hasEnoughData = offering?.payment_enabled ? accountData : prospectData;

  const hasFundMovementData = offering?.payment_enabled
    ? fundMovementData
    : prospectData;

  return (
    <Route {...rest}>
      {(!hasEnoughData && rest.path === '/:offeringName/payment') ||
      ((!hasFundMovementData || query.get('code')) &&
        rest.path === '/:offeringName/verification-documents') ? (
        rest.path === '/:offeringName/verification-documents' ||
        (query.get('code') && rest.path === '/:offeringName/payment') ? (
          <Component />
        ) : (
          <Redirect
            exact
            to={{
              pathname: `/${offeringName}/investor-information`,
              state: { from: location.pathname },
            }}
          />
        )
      ) : (
        <Component />
      )}
    </Route>
  );
};
