import React from 'react';

const BackArrowMobile = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M0.27497 11.3363C0.27525 11.336 0.275485 11.3357 0.275813 11.3354L5.17444 6.46044C5.54142 6.09523 6.135 6.09659 6.5003 6.46362C6.86555 6.83061 6.86414 7.42419 6.49716 7.78944L3.20822 11.0624L23.0625 11.0624C23.5803 11.0624 24 11.4822 24 11.9999C24 12.5177 23.5803 12.9374 23.0625 12.9374L3.20827 12.9374L6.49711 16.2104C6.86409 16.5757 6.8655 17.1693 6.50025 17.5362C6.13495 17.9033 5.54133 17.9046 5.17439 17.5394L0.275765 12.6644C0.275485 12.6642 0.27525 12.6638 0.274922 12.6635C-0.0922503 12.2971 -0.0910773 11.7016 0.27497 11.3363Z"
        fill="#93B0C8"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="matrix(-1 8.74228e-08 8.74228e-08 1 24 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default BackArrowMobile;
