import React, { useState } from 'react';
import { useMedia } from 'react-use';
import cs from 'classnames';
import { CheckCircle, Circle } from 'react-feather';

import styles from './investorInformation.module.scss';

const InvestorChoices = ({ active, setNewInvest, collapse, setCollapse }) => {
  // Media query (1450 pixels)
  const isMaxWidth1450px = useMedia('(max-width:1450px)');

  const expandObjects = [
    {
      title: 'IRA',
      subtitle: 'a custodial account such as a self-directed IRA, 401(k)',
      value: 'ira',
    },
    {
      title: 'Trust',
      subtitle: 'a custodial account such as a trust',
      value: 'trust',
    },
    {
      title: 'Company',
      subtitle: 'a Company (Corporation, LLC, etc.)',
      value: 'company',
    },
  ];

  const options = [
    {
      title: 'Individual(s)',
      subtitle: 'an Individual or Individuals',
      value: 'person',
    },

    collapse ? [...expandObjects] : {},
  ]?.reduce((acc, val) => acc.concat(val), []);

  return (
    <div className={`${!isMaxWidth1450px && 'flex'}`}>
      {options
        .filter(n => Object.keys(n).length)
        .map((opt, index) => (
          <div
            key={index}
            className={cs(
              'shadow-md border border-transparent hover:border-brandorange',
              styles.investmentTypeCard,
              {
                'mb-8': isMaxWidth1450px,
                'border border-brandorange': active === opt.value,
              }
            )}
            onClick={() => setNewInvest(opt.value)}
          >
            <div className="m-5">
              <div className="flex justify-between items-center">
                <h3 className={styles.cardTitle}>{opt.title}</h3>
                {active === opt.value ? (
                  <CheckCircle className={cs(styles.checkIcon)} />
                ) : (
                  <Circle className={cs(styles.uncheckedIcon)} />
                )}
              </div>
              <p className={styles.cardSubtext}>{opt.subtitle}</p>
            </div>
          </div>
        ))}
      {!collapse ? (
        <span
          style={{
            marginTop: 'auto',
            color: '#243b53',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
          className="cursor-pointer"
          onClick={() => setCollapse(true)}
        >
          More options...
        </span>
      ) : null}
    </div>
  );
};

export default InvestorChoices;
