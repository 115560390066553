import React, { useEffect } from 'react';
import { useMedia } from 'react-use';
import Input from 'components/Input/Input';

import { stateOptionsUS } from 'helpers/country';

import get from 'lodash/get';
import NSelect from 'components/NSelect/NSelect';
const AddressForm = ({
  type,
  register,
  errors,
  watch,
  control,
  setError,
  setValue,
  getValues,
}) => {
  const media_query = useMedia('(max-width:600px)');

  const watch_postal_code = watch(type)?.postal_code;
  const watch_country = watch(type)?.country?.value;
  // remove leading and trailing spaces from POSTAL CODE
  useEffect(() => {
    setValue(
      `${type}.postal_code`,
      watch_postal_code.replace(/^\s+|\s+$/g, '')
    );
  }, [watch_postal_code, type, setValue]);

  // AutoComplete fix for forienger fix
  useEffect(() => {
    if (watch_country !== 'US') {
      if (typeof watch(type)?.region === 'object') {
        setValue(`${type}.region`, '');
        setError(`${type}.region`, '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_country]);

  return (
    <>
      <h2 className="mt-12 mb-4">Address</h2>
      <div
        className={`grid mt-2 ${
          media_query ? 'pt-2 grid-cols-1 gap-5' : 'grid-cols-2 gap-5'
        } `}
      >
        <>
          <Input
            label={
              type === 'ira'
                ? 'Retirement Account Company Street Address'
                : 'Street Address'
            }
            id={`${type}.street_address_1`}
            name={`${type}.street_address_1`}
            register={register}
            error={
              errors[type]?.street_address_1
                ? errors[type]?.street_address_1?.message
                : null
            }
          />
          <Input
            label="Apt or Unit # (Optional)"
            id={`${type}.street_address_2`}
            name={`${type}.street_address_2`}
            register={register}
            error={
              errors[type]?.street_address_2
                ? errors[type]?.street_address_2?.message
                : null
            }
          />
        </>

        <Input
          label={'City'}
          id={`${type}.city`}
          name={`${type}.city`}
          register={register}
          error={errors[type]?.city ? errors[type]?.city?.message : null}
        />
        {watch(type)?.country?.value === 'US' ? (
          <NSelect
            autoComplete={'address-level1'}
            control={control}
            label={'State'}
            id={`${type}.region`}
            name={`${type}.region`}
            getValues={getValues}
            setError={setError}
            error={errors[type]?.region ? errors[type]?.region?.message : null}
            list={stateOptionsUS}
            setValue={setValue}
            type={type}
          />
        ) : (
          <Input
            autoComplete={'address-level1'}
            label="State"
            id={`${type}.region`}
            name={`${type}.region`}
            register={register}
            error={errors[type]?.region ? errors[type]?.region?.message : null}
          />
        )}
        <Input
          label={
            watch(type)?.country?.value === 'US' ? 'Zip Code' : 'Postal Code'
          }
          id={`${type}.postal_code`}
          name={`${type}.postal_code`}
          register={register}
          error={
            errors[type]?.postal_code
              ? errors[type]?.postal_code?.message
              : null
          }
        />
      </div>
    </>
  );
};

export default AddressForm;
