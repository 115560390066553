// Importing all of the dependencies
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import get from 'lodash/get';
import InvestmentLayout from '../../components/InvestmentLayout';
import CanAccess from 'views/VerificationDocuments/Wrapper';

import styles from './verification-document.module.scss';

import { toast } from 'react-toastify';

import AccreditionDocumentTitle from './AccreditionDocumentTitle';
import AccreditedDocumentText from './AccreditedDocumentText';
import DropAccreditionDocument from './DropAccreditionDocument';
import UploadedFiles from './UploadedFiles';

import InvestmentRefAndStatus from './InvestmentRefAndStatus';

import KYCAML from './KYCAML';
import { postUploadDocument } from 'redux/api/investor.api';
import { setUploadLoader } from 'redux/actions/investor.action';
import useQuery from 'helpers/useQuery';
import { indentifyInLogRocket } from 'helpers/logrocket';
import BoxablAmlMessage from './BoxablAmlMessage';

const VerificationDocument = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const isCode = query.get('code');

  //STATE SELECTORS
  const [files, setFiles] = useState([]);

  const [fileprogress, setFileProgress] = useState({});
  const [deletedFiles, setDeletedFiles] = useState([]);

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  const account = useSelector(({ investor }) =>
    get(investor, 'account.data', null)
  );

  const fundMovement = useSelector(({ investor }) =>
    get(investor, 'fundMovement.data', null)
  );

  //FUNCTIONS
  const uploadCallback = (id, investorId, uploadedTo) => progressEvent => {
    const { loaded, total } = progressEvent;
    const percentage = Math.round((100 * loaded) / total);

    setFileProgress({
      ...fileprogress,
      [id]: {
        percentage: percentage > 90 ? 90 : percentage,
        uploaded: false,
        investorId,
        uploadedTo,
      },
    });
  };

  const requestCallback = (id, investorId) => upload => {
    setFileProgress({
      ...fileprogress,
      [id]: {
        percentage: 100,
        uploaded: upload.status,
        message: upload.status ? null : upload.message,
        investorId,
      },
    });
  };

  const onDrop = async (
    acceptedFiles,
    rejectedFiles,
    type,
    investorId,
    uploadedTo
  ) => {
    if (rejectedFiles && rejectedFiles.length) {
      const fileNames = rejectedFiles.map(f => f.file.name).join(',');
      return toast(
        `${fileNames} ${rejectedFiles.length > 1 ? 'are' : 'is'} not ${
          rejectedFiles.length > 1 ? '' : 'a'
        } supported ${rejectedFiles.length > 1 ? 'documents' : 'document'}`,
        { type: 'error' }
      );
    }

    if (!account?.accountId) {
      return toast(
        `Investor account not found. Please contact invest@boxabl.com`,
        { type: 'error' }
      );
    }

    if (!fundMovement?.trade?.tradeId) {
      return toast(`TradeId does not exist! Please contact invest@boxabl.com`, {
        type: 'error',
      });
    }

    const filesid = uuidv4();
    const newFiles = [
      ...acceptedFiles.map(file => ({
        id: filesid,
        file,
        type,
        investorId,
        fileid: uuidv4(),
      })),
    ];

    setFiles([...files, ...newFiles]);
    const data = new FormData();
    newFiles.forEach(async obj => {
      const { file } = obj;
      data.append(`files`, file);
    });
    data.append(`type`, type);
    data.append('nanoId', account.nanoId);
    data.append('investorId', investorId);
    data.append('uploadedTo', uploadedTo);
    data.append('tradeId', fundMovement?.trade?.tradeId.toString());

    try {
      const upload = await postUploadDocument(
        data,
        uploadCallback(filesid, investorId, uploadedTo)
      );
      requestCallback(
        filesid,
        investorId,
        uploadedTo
      )({
        status: true,
        message: upload.data.message,
      });
    } catch (err) {
      toast(
        `${JSON.stringify(
          err.response.data
        )} .Please message invest@boxabl.com`,
        { type: 'error' }
      );
      requestCallback(
        filesid,
        investorId,
        uploadedTo
      )({
        status: false,
        message: JSON.stringify(err.response.data),
      });
    }

    // window.scrollTo({
    //   left: 0,
    //   top: document.body.scrollHeight,
    //   behavior: 'smooth',
    // });
  };

  const accreditedDropZone = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) =>
      onDrop(
        acceptedFiles,
        rejectedFiles,
        'accredited',
        account?.accountId,
        'account'
      ),
    accept: 'image/jpeg, image/png, application/pdf',
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const lastUploadProgess = Object.values(fileprogress)[
      Object.values(fileprogress).length - 1
    ];

    if (lastUploadProgess) {
      const { investorId, uploaded, uploadedTo } = lastUploadProgess;
      dispatch(setUploadLoader({ investorId, uploaded }));

      if (uploadedTo === 'account') {
        if (!uploaded)
          window.scrollTo({
            left: 0,
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
      }
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileprogress]);
  useEffect(() => {
    if (account && isCode) {
      indentifyInLogRocket(uuidv4(), {
        name: account?.accountRegistration,
        email: account?.email,
        phone: account?.phone,
        type: account?.investedAs,
        is: 'documents',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <CanAccess>
      <InvestmentLayout>
        <div className={styles.root}>
          <InvestmentRefAndStatus />

          {offering?.payment_enabled ? (
            <div>
              <div className={styles.successInvestment}>
                {/* Accredited Title */}
                <BoxablAmlMessage />
                {account?.aiRequestStatus !== 'Approved'
                  ? offering?.type === 'reg_d' && <AccreditionDocumentTitle />
                  : null}
                <div className={styles.successContainer}>
                  {/* Accredited Document Requirements */}
                  {(account?.aiRequestStatus !== 'Approved' &&
                    account?.aiNotes === null) ||
                  (account?.aiNotes && account?.aiNotes.length === 0)
                    ? offering?.type === 'reg_d' && (
                        <>
                          <div className="text-2xl text-center text-brandblue font-bold block">
                            Need more time to upload your documents?{' '}
                          </div>
                          <div className="text-center text-lg text-brandorange">
                            We will email you a link to come back to this page.
                          </div>
                          {account?.aiRequestStatus !== 'Approved' && (
                            <AccreditedDocumentText account={account} />
                          )}
                        </>
                      )
                    : null}

                  <div className="w-full flex flex-col items-center justify-center">
                    <div className="w-full flex flex-col items-center">
                      {/* Accredited Document Drop A Document */}
                      {account?.aiRequestStatus !== 'Approved'
                        ? offering?.type === 'reg_d' && (
                            <DropAccreditionDocument
                              DocumentDropzone={accreditedDropZone}
                              account={account}
                            />
                          )
                        : null}
                      {isCode && (
                        <KYCAML
                          onDrop={onDrop}
                          files={files}
                          fileprogress={fileprogress}
                        />
                      )}
                    </div>

                    <UploadedFiles
                      files={files}
                      deletedFiles={deletedFiles}
                      filesProgress={fileprogress}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </InvestmentLayout>
    </CanAccess>
  );
};
export default VerificationDocument;
