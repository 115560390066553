import { month } from 'helpers/month';

export const injectDefaultValueCompany = (
  restInitialValues,
  personInitialValues,
  companyInitialValues,
  prospectedByInvestorData,
  investAs
) => {
  const isProspectHasData =
    typeof prospectedByInvestorData === 'object'
      ? Object.keys(prospectedByInvestorData).length
      : false;

  const birthDay = isProspectHasData
    ? (() => {
        const splitedBirthDay = prospectedByInvestorData?.person?.date_of_birth.split(
          '-'
        );

        const birthYear = splitedBirthDay[0];

        const foundMonth = month.find((e, i) => e.value === splitedBirthDay[1]);

        const birthMonth = foundMonth
          ? foundMonth
          : {
              label: 'Select Month',
              value: false,
            };

        const birth_day = splitedBirthDay[2];

        return {
          birth_date: {
            label: birth_day,
            value: birth_day,
          },
          birth_month: birthMonth,
          birth_year: {
            label: birthYear,
            value: birthYear,
          },
        };
      })()
    : {
        birth_date: {
          label: 'Select Day',
          value: false,
        },
        birth_month: {
          label: 'Select Month',
          value: false,
        },
        birth_year: {
          label: 'Select Year',
          value: false,
        },
      };

  return {
    invested_as: investAs,

    [`${investAs}`]: {
      ...companyInitialValues,
      ...(isProspectHasData
        ? {
            ...prospectedByInvestorData[`${investAs}`],
          }
        : {}),
    },

    person: {
      ...birthDay,
      ...personInitialValues,
      ...restInitialValues,
      ...(prospectedByInvestorData.person
        ? {
            ...prospectedByInvestorData.person,
            country: prospectedByInvestorData.person.country,
            region: prospectedByInvestorData.person.region,
          }
        : {}),
    },
  };
};
