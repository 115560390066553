import React from 'react';
import styles from './verification-document.module.scss';
import { useMedia } from 'react-use';
import cs from 'classnames';
// DUMMY ICON
import { UploadCloud, Upload, CheckCircle } from 'react-feather';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { CompanyText, IndividualText, TrustText } from './AMLDocumentsText';
import useQuery from 'helpers/useQuery';
import LoadSpinner from 'assets/images/load-spinner';

const DropAMLDocuments = ({ onDrop }) => {
  const isMaxWidth455px = useMedia('(max-width:455px)');
  const query = useQuery();
  const scrollTo = query.get('scrollTo');
  const ref = React.useRef(null);
  const parties = useSelector(({ investor }) =>
    get(investor, 'parties.data', [
      {
        amlStatus: 'Approved',
        kycStatus: 'Approved',
      },
    ])
  );

  const entity = useSelector(({ investor }) =>
    get(investor, 'entity.data', {
      AMLstatus: 'Approved',
      KYCstatus: 'Approved',
    })
  );

  const isEntityNorthCap =
    entity?.reviewBy === 'NorthCapital'
      ? entity?.AMLstatus === 'Disapproved' ||
        entity?.KYCstatus === 'Disapproved' ||
        entity?.AMLstatus === 'Need More Info' ||
        entity?.KYCstatus === 'Need More Info'
      : false;

  const isEntityBoxabl =
    entity?.reviewBy === 'Boxabl'
      ? entity?.AMLstatus === 'Disapproved' ||
        entity?.KYCstatus === 'Disapproved' ||
        entity?.AMLstatus === 'Need More Info' ||
        entity?.KYCstatus === 'Need More Info' ||
        entity?.AMLstatus === 'Pending' ||
        entity?.KYCstatus === 'Pending'
      : false;

  const isEntityKycAmlApproved =
    isEntityNorthCap ||
    isEntityBoxabl ||
    // if boxabl wants to force user to upload
    entity?.overrideUpload;

  //isEntityKycAmlApproved

  React.useEffect(() => {
    if (scrollTo === 'amlEntity') {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const needsUploadParties = parties
    ? parties.filter((e, i) => e?.isUploadKYCAML === false).length > 0
      ? parties.filter((e, i) => {
          const isUploadBoxabl =
            e?.reviewBy === 'Boxabl'
              ? e?.isUploadKYCAML === false ||
                (e?.amlStatus === 'Disapproved' &&
                  e?.kycStatus === 'Disapproved')
                ? true
                : false
              : false;

          const isBoxablCitizenPending =
            e?.reviewBy === 'Boxabl' && e?.domicile === 'U.S. Citizen'
              ? e?.amlStatus === 'Pending' || e?.kycStatus === 'Pending'
              : false;

          const isUploadNorthCapital =
            e?.reviewBy === 'NorthCapital'
              ? e?.isUploadKYCAML === false &&
                e?.kycStatus !== 'Pending' &&
                e?.amlStatus !== 'Pending'
                ? true
                : false
              : false;

          return (
            (isUploadBoxabl && !isBoxablCitizenPending) || isUploadNorthCapital
          );
        })
      : null
    : null;

  const isEntityBoxablUpload =
    entity?.reviewBy === 'Boxabl'
      ? entity?.isUploadKYCAML === false
        ? true
        : false
      : false;

  const isEntityNorthCapitalUpload =
    entity?.reviewBy === 'NorthCapital'
      ? entity?.isUploadKYCAML === false &&
        entity?.KYCstatus !== 'Pending' &&
        entity?.AMLstatus !== 'Pending'
        ? true
        : false
      : false;

  const needsUploadEntity =
    isEntityBoxablUpload || isEntityNorthCapitalUpload ? entity : null;

  const scrollToInvestorNeedsUpload = (investorId, event) => {
    event.stopPropagation();
    const element = document.getElementById(investorId);
    element.scrollIntoView();
  };

  return (
    <>
      {parties
        ? parties
            .filter((e, i) => {
              const isNorthCapital =
                e?.reviewBy === 'NorthCapital'
                  ? e?.amlStatus === 'Disapproved' ||
                    e?.kycStatus === 'Disapproved' ||
                    e?.amlStatus === 'Need More Info' ||
                    e?.kycStatus === 'Need More Info'
                  : false;

              const isBoxabl =
                e?.reviewBy === 'Boxabl'
                  ? e?.amlStatus === 'Disapproved' ||
                    e?.kycStatus === 'Disapproved' ||
                    e?.amlStatus === 'Need More Info' ||
                    e?.kycStatus === 'Need More Info' ||
                    e?.amlStatus === 'Pending' ||
                    e?.kycStatus === 'Pending' ||
                    e?.overrideUpload
                  : false;

              const isBoxablCitizenPending =
                e?.reviewBy === 'Boxabl' && e?.domicile === 'U.S. Citizen'
                  ? e?.amlStatus === 'Pending' || e?.kycStatus === 'Pending'
                  : false;

              return (
                isNorthCapital ||
                (isBoxabl && !isBoxablCitizenPending) ||
                // if boxabl wants to force user to upload
                e?.overrideUpload
              );
            })
            .map((e, i) => {
              return (
                <div className="mt-2 w-full" key={i} id={e?.partyId}>
                  <div>
                    <IndividualText
                      country={e?.primCountry}
                      notes={e?.notes}
                      firstName={e?.firstName}
                      lastName={e?.lastName}
                    />
                  </div>

                  <div className="w-full flex flex-col items-center">
                    <Dropzone
                      onDrop={(acceptedFiles, rejectedFiles) =>
                        onDrop(
                          acceptedFiles,
                          rejectedFiles,
                          'aml',
                          e.partyId,
                          'party'
                        )
                      }
                      accept={'image/jpeg, image/png, application/pdf'}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          className={cs(
                            styles.reactDropzone,
                            'w-full xl:w-8/12'
                          )}
                          {...getRootProps()}
                        >
                          <div
                            className={`p-8 shadow-md w-full transition border-dashed duration-500 ease-in-out border-2 rounded-lg flex flex-col items-center justify-center ${
                              isDragActive
                                ? 'bg-brandblue text-white border-white'
                                : 'bg-white border-brandorange'
                            }`}
                          >
                            <span
                              className={cs(
                                'mb-2 relative my-3 flex items-center justify-center',
                                {
                                  'text-white': isDragActive,
                                  'text-brandblue': !isDragActive,
                                }
                              )}
                            >
                              {e?.isUploadLoading ? (
                                <>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      fontSize: '18px',
                                    }}
                                  >
                                    <LoadSpinner />
                                    <div>Please wait!...</div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {e?.isUploadIcon ? (
                                    <>
                                      {' '}
                                      <UploadCloud
                                        size={isMaxWidth455px ? 30 : 40}
                                        className={cs(styles.icon_uploadCloud, {
                                          [styles.active]: isDragActive,
                                        })}
                                      />
                                      <Upload
                                        size={isMaxWidth455px ? 30 : 40}
                                        className={cs(styles.icon_upload, {
                                          [styles.notActive]: isDragActive,
                                        })}
                                      />
                                    </>
                                  ) : (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '18px',
                                      }}
                                    >
                                      <CheckCircle
                                        color="green"
                                        size={'30px'}
                                      />
                                      <div> Upload Successful!</div>

                                      {needsUploadParties ||
                                      needsUploadEntity ? (
                                        <div
                                          style={{ textAlign: 'center' }}
                                          onClick={event =>
                                            event.stopPropagation()
                                          }
                                        >
                                          <br />
                                          You Have More Documents To Upload.
                                          <br />
                                          Click below!
                                        </div>
                                      ) : null}

                                      {needsUploadParties &&
                                        needsUploadParties.map((e, i) => (
                                          <div
                                            key={i}
                                            style={{
                                              textAlign: 'center',
                                              cursor: 'pointer',
                                              padding: '20px',
                                            }}
                                            onClick={event =>
                                              scrollToInvestorNeedsUpload(
                                                e?.partyId,
                                                event
                                              )
                                            }
                                          >
                                            <br />

                                            <span
                                              style={{
                                                color: 'red',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                              }}
                                            >
                                              Scroll To {e?.firstName}{' '}
                                              {e?.lastName}
                                            </span>
                                          </div>
                                        ))}

                                      {needsUploadEntity && (
                                        <div
                                          onClick={event =>
                                            scrollToInvestorNeedsUpload(
                                              needsUploadEntity?.partyId,
                                              event
                                            )
                                          }
                                          style={{
                                            textAlign: 'center',
                                            cursor: 'pointer',

                                            padding: '20px',
                                          }}
                                        >
                                          <br />

                                          <span
                                            style={{
                                              color: 'red',
                                              textDecoration: 'underline',
                                              cursor: 'pointer',
                                            }}
                                          >
                                            Scroll To{' '}
                                            {needsUploadEntity?.entityName}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </span>
                            <p
                              className={cs(
                                'mb-0 text-center mt-4 transition duration-500 ease-in-out',
                                {
                                  'text-medium': isMaxWidth455px,
                                  'text-xl': !isMaxWidth455px,
                                  'text-white': isDragActive,
                                  'text-brandblue': !isDragActive,
                                }
                              )}
                            >
                              <span style={{ color: 'orange' }}>
                                {e?.notes && e?.notes.length > 0
                                  ? ''
                                  : e?.primCountry === 'US'
                                  ? 'Drop a valid driver’s license or valid passport here.'
                                  : 'Drop a valid passport only!'}{' '}
                              </span>
                              <br />
                              <br />
                              Drop AML documents here!
                              <br />
                              <span
                                style={{
                                  color: '#ffa400',
                                  fontWeight: '600',
                                  textDecoration: 'underline',
                                }}
                              >
                                {e?.firstName} {e?.lastName}
                              </span>
                              , or{' '}
                              <span
                                className={cs('cursor-pointer font-bold ', {
                                  'text-white': isDragActive,
                                  'text-brandblue': !isDragActive,
                                })}
                              >
                                click here
                                <input
                                  className="opacity-0 absolute z-10 top-0 right-0 bottom-0 left-0"
                                  {...getInputProps()}
                                />
                              </span>
                            </p>
                            <p
                              className={cs(
                                'mb-0 text-center transition duration-500 ease-in-out',
                                {
                                  'text-sm': isMaxWidth455px,
                                  'text-medium': !isMaxWidth455px,
                                  'text-white': isDragActive,
                                  'text-brandblue': !isDragActive,
                                }
                              )}
                            >
                              Supports: JPG, PNG, PDF
                            </p>
                            {e?.primCountry !== 'US' && (
                              <>
                                <br />
                                <p
                                  className={cs(
                                    'mb-0 text-center transition duration-500 ease-in-out',
                                    {
                                      'text-sm': isMaxWidth455px,
                                      'text-medium': !isMaxWidth455px,
                                      'text-white': isDragActive,
                                      'text-brandblue': !isDragActive,
                                    }
                                  )}
                                >
                                  (English language Documents Only)
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              );
            })
        : null}

      {isEntityKycAmlApproved && (
        <>
          <div className="mt-2 w-full" ref={ref} id={entity?.partyId}>
            <div className={styles.amlTextContainer}>
              Please Drop Your{' '}
              <span style={{ color: '#ffa400', fontWeight: '600' }}>
                {' ' + entity?.entityName + ` `}
              </span>{' '}
              AML Documents In The{' '}
              <span
                style={{
                  color: '#ffa400',
                  fontWeight: '600',
                  textDecoration: 'underline',
                }}
              >
                Correct Box
              </span>
              {'. '}
              {entity?.notes && entity?.notes.length > 0 && (
                <span>
                  {' '}
                  Please
                  <span
                    style={{
                      color: 'orange',
                      textDecoration: 'underline',
                      fontWeight: '600',
                    }}
                  >
                    {' '}
                    Read Our Notes Below To Upload Required Documents To
                    Complete Your Investment
                  </span>
                  .
                </span>
              )}
            </div>

            <>
              {entity?.entityDesc === 'ira' && (
                <IndividualText
                  type={entity?.entityDesc === 'ira'}
                  country={entity?.primCountry}
                  notes={entity?.notes}
                  firstName={entity?.entityName}
                />
              )}
            </>
            <>
              {entity?.entityDesc === 'company' && (
                <CompanyText
                  country={entity?.primCountry}
                  notes={entity?.notes}
                  entityName={entity?.entityName}
                />
              )}
            </>
            <>
              {entity?.entityDesc === 'trust' && (
                <TrustText
                  country={entity?.primCountry}
                  notes={entity?.notes}
                  entityName={entity?.entityName}
                />
              )}
            </>
          </div>

          <Dropzone
            onDrop={(acceptedFiles, rejectedFiles) =>
              onDrop(
                acceptedFiles,
                rejectedFiles,
                'aml',
                entity.partyId,
                'entity'
              )
            }
            accept={'image/jpeg, image/png, application/pdf'}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                className={cs(styles.reactDropzone, 'w-full xl:w-8/12')}
                {...getRootProps()}
              >
                <div
                  className={`p-8 shadow-md w-full transition border-dashed duration-500 ease-in-out border-2 rounded-lg flex flex-col items-center justify-center ${
                    isDragActive
                      ? 'bg-brandblue text-white border-white'
                      : 'bg-white border-brandorange'
                  }`}
                >
                  <span
                    className={cs(
                      'mb-2 relative my-3 flex items-center justify-center',
                      {
                        'text-white': isDragActive,
                        'text-brandblue': !isDragActive,
                      }
                    )}
                  >
                    {entity?.isUploadLoading ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '18px',
                          }}
                        >
                          <LoadSpinner />
                          <div>Please wait!...</div>
                        </div>
                      </>
                    ) : (
                      <>
                        {entity?.isUploadIcon ? (
                          <>
                            {' '}
                            <UploadCloud
                              size={isMaxWidth455px ? 30 : 40}
                              className={cs(styles.icon_uploadCloud, {
                                [styles.active]: isDragActive,
                              })}
                            />
                            <Upload
                              size={isMaxWidth455px ? 30 : 40}
                              className={cs(styles.icon_upload, {
                                [styles.notActive]: isDragActive,
                              })}
                            />
                          </>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              fontSize: '18px',
                            }}
                          >
                            <CheckCircle color="green" size={'30px'} />
                            <div> Upload Successful!</div>

                            {needsUploadParties || needsUploadEntity ? (
                              needsUploadParties ? (
                                needsUploadParties.length === 0 ? null : (
                                  <div
                                    style={{ textAlign: 'center' }}
                                    onClick={event => event.stopPropagation()}
                                  >
                                    <br />
                                    You Have More Documents To Upload.
                                    <br />
                                    Click below!
                                  </div>
                                )
                              ) : null
                            ) : null}

                            {needsUploadParties &&
                              needsUploadParties.map((e, i) => (
                                <div
                                  key={i}
                                  style={{
                                    textAlign: 'center',
                                    cursor: 'pointer',

                                    padding: '20px',
                                  }}
                                  onClick={event =>
                                    scrollToInvestorNeedsUpload(
                                      e?.partyId,
                                      event
                                    )
                                  }
                                >
                                  <br />

                                  <span
                                    style={{
                                      color: 'red',
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    Scroll To {e?.firstName} {e?.lastName}
                                  </span>
                                </div>
                              ))}

                            {needsUploadEntity && (
                              <div
                                onClick={event =>
                                  scrollToInvestorNeedsUpload(
                                    needsUploadEntity?.partyId,
                                    event
                                  )
                                }
                                style={{
                                  textAlign: 'center',
                                  cursor: 'pointer',

                                  padding: '20px',
                                }}
                              >
                                <br />

                                <span
                                  style={{
                                    color: 'red',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                  }}
                                >
                                  Scroll To {needsUploadEntity?.entityName}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </span>

                  <p
                    className={cs(
                      'mb-0 text-center mt-4 transition duration-500 ease-in-out',
                      {
                        'text-medium': isMaxWidth455px,
                        'text-xl': !isMaxWidth455px,
                        'text-white': isDragActive,
                        'text-brandblue': !isDragActive,
                      }
                    )}
                  >
                    Drop your{' '}
                    <span
                      style={{
                        color: '#ffa400',
                        fontWeight: '600',
                        textDecoration: 'underline',
                      }}
                    >
                      {entity?.entityDesc}{' '}
                    </span>
                    {'  '}
                    AML documents here <br />, or{' '}
                    <span
                      className={cs('cursor-pointer font-bold ', {
                        'text-white': isDragActive,
                        'text-brandblue': !isDragActive,
                      })}
                    >
                      click here
                      <input
                        className="opacity-0 absolute z-10 top-0 right-0 bottom-0 left-0"
                        {...getInputProps()}
                      />
                    </span>
                  </p>
                  <p
                    className={cs(
                      'mb-0 text-center transition duration-500 ease-in-out',
                      {
                        'text-sm': isMaxWidth455px,
                        'text-medium': !isMaxWidth455px,
                        'text-white': isDragActive,
                        'text-brandblue': !isDragActive,
                      }
                    )}
                  >
                    Supports: JPG, PNG, PDF
                  </p>
                  {entity?.primCountry !== 'US' && (
                    <>
                      <br />
                      <p
                        className={cs(
                          'mb-0 text-center transition duration-500 ease-in-out',
                          {
                            'text-sm': isMaxWidth455px,
                            'text-medium': !isMaxWidth455px,
                            'text-white': isDragActive,
                            'text-brandblue': !isDragActive,
                          }
                        )}
                      >
                        (English language Documents Only)
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </Dropzone>
        </>
      )}
    </>
  );
};

export default DropAMLDocuments;
