import Request from './request';

export const wireInvestment = async data =>
  Request.call({
    url: '/invest/wire',
    method: 'POST',
    data,
  });

export const checkInvestment = async data =>
  Request.call({
    url: '/invest/debt_check',
    method: 'POST',
    data,
  });

export const achInvestment = async data =>
  Request.call({
    url: '/invest/ach',
    method: 'POST',
    data,
  });

export const cryptoInvestment = async data =>
  Request.call({
    url: '/invest/crypto',
    method: 'POST',
    data,
  });

export const creditCardInvestment = async data =>
  Request.call({
    url: '/invest/credit_card',
    method: 'POST',
    data,
  });

export const agreement = async data =>
  Request.call({
    url: '/invest/agreement',
    method: 'POST',
    data,
  });

export const getEntityById = async id =>
  Request.call({
    url: `/funding/entities/${id}`,
    method: 'GET',
  });

export const getInvestorById = async id =>
  Request.call({
    url: `/funding/investors/${id}`,
    method: 'GET',
  });

export const createInvestor = async (data, type) =>
  Request.call({
    url: `/funding/${type}`,
    method: 'POST',
    data,
  });

export const uploadDocuments = async (data, uploadCallback) =>
  Request.call({
    url: `/documents/`,
    method: 'POST',
    data,
    onUploadProgress: uploadCallback,
  });

export const getOfferingBySlug = async slug =>
  Request.call({
    url: `/offerings/slug/${slug}`,
    method: 'GET',
  });

export const createProspect = async (data, type) =>
  Request.call({
    url: `/prospect/${type}`,
    method: 'POST',
    data,
  });

export const updateProspect = async (id, data) =>
  Request.call({
    url: `/prospect/${id}`,
    method: 'PUT',
    data,
  });

export const validatePaymentCode = async code =>
  Request.call({
    url: '/invest/validate_payment',
    method: 'POST',
    data: {
      code: code,
    },
  });

export const validateProspectedCode = async code =>
  Request.call({
    url: '/prospect/verify-code',
    method: 'POST',
    data: {
      code: code,
    },
  });
