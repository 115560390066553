import LogRocket from 'logrocket';
//phone name, email, type
export const indentifyInLogRocket = (
  mongoId,
  { name, email, phone, type, is, paymentEnabled = 'yes' }
) => {
  if (
    process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'test'
  ) {
    LogRocket.identify(mongoId, {
      name,
      email,
      phone,
      invested_as: type,
      is,
      paymentEnabled,
    });
  } else {
    return;
  }
};
