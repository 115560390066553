import React from 'react';

import styles from './load-spinner.module.scss';

const LoadSpinner = ({ size }) => (
  <svg
    className={`${styles.spinner} ${size?.cx &&
      size.cy &&
      size.r &&
      styles.spinner_xl}`}
  >
    <circle cx={size?.cx || 15} cy={size?.cy || 15} r={size?.r || 13} />
  </svg>
);

export default LoadSpinner;
