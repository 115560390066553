import * as Yup from 'yup';
export const getCompanySchema = hasPaymentEnabled =>
  Yup.object({
    name: Yup.string().required('Name is Required'),
    phone: Yup.string().required('Phone is Required'),
    payment_enabled: Yup.boolean(),
    ...(hasPaymentEnabled
      ? {
          tax_id_number: Yup.string().when('country', {
            is: val => val && val.value === 'US',
            then: Yup.string()
              .required('Tax ID is Required')
              .length(10, 'Please enter valid tax ID')
              .test('is-digits', 'Please enter valid tax ID', v =>
                v ? !isNaN(parseInt(v.replace(/-/g, ''))) : !isNaN(v)
              ),
          }),
        }
      : {}),

    region_formed_in: Yup.object()
      .required('Region / State is required')
      .when('country', val => {
        if (val && val.value !== 'US') {
          return Yup.string()
            .required('State is required')
            .matches(
              /^[aA-zZ\s]+$/,
              'Only alphabets are allowed for this field '
            );
        }
      })
      .test(
        'company_region_formed',
        'Region / State is required',
        ({ value }) => {
          if (value === false) {
            return false;
          } else {
            return true;
          }
        }
      ),

    email: Yup.string()
      .required('Email is required')
      .email('Please enter valid Email'),

    street_address_1: Yup.string()
      .required('Street Address is required')
      .test('PObox', 'PO Boxes cannot be used to make an investment', value => {
        if (
          //https://stackoverflow.com/questions/5680050/po-box-regular-expression-validation
          new RegExp(
            '\\b[P|p]*(OST|ost)*\\.*\\s*[O|o|0]*(ffice|FFICE)*\\.*\\s*[B|b][O|o|0][X|x]\\b'
          ).test(value)
        ) {
          return false;
        } else {
          return true;
        }
      }),
    street_address_2: Yup.string().when('country', {
      is: val => val && val.value !== 'GB',
      then: Yup.string()
        .default('')
        .nullable(),
    }),
    city: Yup.string()
      .required('City is required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    region: Yup.object()
      .required('Please enter a valid region')
      .when('country', val => {
        if (val && val.value !== 'US') {
          return Yup.string()
            .required('State is required')
            .matches(
              /^[aA-zZ\s]+$/,
              'Only alphabets are allowed for this field '
            );
        }
      })
      .test('company_region', 'Please enter a valid region', ({ value }) => {
        if (value === false) {
          return false;
        } else {
          return true;
        }
      }),

    country: Yup.object()
      .nullable()
      .required('Country is required')
      .test('iscountry', 'Please enter a valid country', ({ value }) => {
        if (value === false) {
          return false;
        } else {
          return true;
        }
      }),
    postal_code: Yup.string().required('Postal Code is required'),
  });
