import React from 'react';
import styles from './verification-document.module.scss';
import cs from 'classnames';

// DUMMY_COMPONENT

/*

*/

//INDIVIDUAL TEXT
export const IndividualText = ({
  type = 'individual',
  country = 'US',
  notes,
  firstName,
  lastName,
}) => {
  const [showText, setShowText] = React.useState(false);

  React.useEffect(() => {
    if (notes && notes.length > 0) {
      setShowText(false);
    } else {
      setShowText(true);
    }
  }, [notes]);

  return (
    <>
      <h3 className={cs('text-brandorange', styles.titleCriteria)}>
        {type === 'individual' ? ' Individual Investment' : 'Ira Investment'}
      </h3>

      {country !== 'US' && (
        <>
          <h2 className="text-brandorange text-lg mt-2">
            {firstName ? `${firstName} ${lastName ? lastName : ``}. ` : ``}{' '}
            Please upload only a valid passport{' '}
            {notes && notes.length > 0 && ', and any notes left below'}.
          </h2>
          <br />
        </>
      )}
      {country === 'US' && (
        <h2 className="text-brandorange text-lg mt-2">
          {notes && notes.length > 0
            ? ''
            : `${
                firstName ? `${firstName} ${lastName ? lastName : ``}. ` : ``
              } Please upload any of the following, if you hold.`}
        </h2>
      )}

      {notes && notes.length > 0 && (
        <div style={{ fontSize: '18px' }}>
          <p>Please Upload Required Documents From the Notes below:</p>

          <div>Notes: </div>
          <br />
          <p>
            {' "'}
            {firstName} {lastName ? lastName : ``}, {notes} {' "'}
          </p>
        </div>
      )}

      {country === 'US' && (
        <div className="text-brandblue my-3">
          {notes && notes.length > 0 && (
            <div>
              Click
              <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setShowText(!showText)}
              >
                {' '}
                here{' '}
              </span>
              to preview required AML documents before notes.
            </div>
          )}

          {showText && (
            <>
              <ul
                style={{
                  listStyle: 'inside',
                }}
              >
                <li className="text-brandblue my-3">
                  Valid Driver's License / Valid Passport.
                </li>
                <li className="text-brandblue my-3">
                  Proof of Address
                  <ul
                    className={styles.criteriaPoints}
                    style={{ listStyle: 'inside' }}
                  >
                    <li>Mail item</li>
                    <li>Utility/phone bill</li>
                    <li>Bank statement</li>
                    <li>Insurance statement</li>
                    <li>Any/all items must be dated within the last 90 days</li>
                  </ul>
                </li>

                <li className="text-brandblue my-3">Social Security Card</li>
              </ul>
            </>
          )}
        </div>
      )}
    </>
  );
};

export const TrustText = ({ country = 'US', notes, entityName }) => {
  const [showText, setShowText] = React.useState(false);

  React.useEffect(() => {
    if (notes && notes.length > 0) {
      setShowText(false);
    } else {
      setShowText(true);
    }
  }, [notes]);
  return (
    <>
      <h3 className={cs('text-brandorange', styles.titleCriteria)}>
        Trust Investments
      </h3>

      {notes && notes.length > 0 && (
        <div style={{ fontSize: '18px' }}>
          <p>Please Upload Required Documents From the Notes below:</p>

          <div>Notes: </div>
          <br />
          <p>
            {' "'}
            {entityName} , {notes} {' "'}
          </p>
        </div>
      )}

      {notes && notes.length > 0 && (
        <div className="text-brandblue my-3">
          Click
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setShowText(!showText)}
          >
            {' '}
            here{' '}
          </span>
          to preview required Trust AML documents before notes.
        </div>
      )}
      {showText && (
        <>
          {' '}
          <h2 className="text-brandorange text-lg mt-2">
            Please upload any of the following documents if you hold.{' '}
          </h2>
          <ul style={{ listStyle: 'inside' }}>
            <li className="text-brandblue my-3">
              Either a signed FULL copy of the trust OR a signed Certificate of
              Trust
            </li>

            <li className="text-brandblue my-3">
              List of all trustees, grantors (if still living), and protectors,
              including their full legal name, DOB, SSN, and residential
              address.
            </li>

            <li className="text-brandblue my-3">
              {country === 'US'
                ? `For all U.S. individuals, please upload a driver s license or
                  valid passport.`
                : 'If you are a foreign investor, please upload a valid passport.'}
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export const CompanyText = ({ country = 'US', notes, entityName }) => {
  const [showText, setShowText] = React.useState(false);

  React.useEffect(() => {
    if (notes && notes.length > 0) {
      setShowText(false);
    } else {
      setShowText(true);
    }
  }, [notes]);

  return (
    <>
      <h3 className={cs('text-brandorange', styles.titleCriteria)}>
        Company Investments
      </h3>

      {notes && notes.length > 0 && (
        <div style={{ fontSize: '18px' }}>
          <p>Please Upload Required Documents From the Notes below:</p>

          <div>Notes: </div>
          <br />
          <p>
            {' "'}
            {entityName} , {notes} {' "'}
          </p>
        </div>
      )}

      {notes && notes.length > 0 && (
        <div className="text-brandblue my-3">
          Click
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => setShowText(!showText)}
          >
            {' '}
            here{' '}
          </span>
          to preview required Company AML documents before notes.
        </div>
      )}

      {showText && (
        <>
          <h2 className="text-brandorange text-lg mt-2">
            <span
              className="text-brandorange"
              style={{ fontWeight: '600', textDecoration: 'underline' }}
            >
              {' '}
              If your company is a LLC{' '}
            </span>
            .Please upload any of the following documents below.
          </h2>

          <ul style={{ listStyle: 'inside' }}>
            <li style={{ marginTop: '10px' }}>Certificate of formation.</li>
            <li style={{ marginTop: '10px' }}>Articles of organization.</li>
            <li style={{ marginTop: '10px' }}>Operating agreement.</li>

            <li style={{ marginTop: '10px' }}>
              Members’ list and authorized signers’ list (if not included in the
              documents).
            </li>
            <ul style={{ listStyle: 'inside' }}>
              <li
                style={{
                  marginLeft: '40px',
                  fontSize: '15px',
                  marginTop: '10px',
                  listStyleType: 'square',
                }}
              >
                For each member with a 20% or greater equity stake, as well as
                for the designated control person, we require their full legal
                name, DOB, SSN, and residential address.
              </li>

              <li
                style={{
                  marginLeft: '40px',
                  fontSize: '15px',
                  marginTop: '10px',
                  marginBottom: '10px',
                  listStyleType: 'square',
                }}
              >
                {country === 'US'
                  ? `For all U.S. individuals, please upload a driver s license or
                  valid passport.`
                  : 'If you are a foreign investor, please upload a valid passport.'}
              </li>
            </ul>
          </ul>

          <h2 className="text-brandorange text-lg mt-10">
            <span
              className="text-brandorange"
              style={{ fontWeight: '600', textDecoration: 'underline' }}
            >
              {' '}
              If your company is a Corporation{' '}
            </span>
            . Please upload any of the following documents below.
          </h2>

          <ul style={{ listStyle: 'inside' }}>
            <li style={{ marginTop: '10px' }}>By-laws.</li>
            <li style={{ marginTop: '10px' }}>Certificate of formation.</li>
            <li style={{ marginTop: '10px' }}>Articles of incorporation.</li>

            <li style={{ marginTop: '10px' }}>
              Shareholders’ list and authorized signers’ list (if not included
              in the documents).
            </li>
            <ul style={{ listStyle: 'inside' }}>
              <li
                style={{
                  marginLeft: '40px',
                  fontSize: '15px',
                  marginTop: '10px',
                  listStyleType: 'square',
                }}
              >
                {country === 'US'
                  ? `For all U.S. individuals, please upload a driver s license or
                  valid passport.`
                  : 'If you are a foreign investor, please upload a valid passport.'}
              </li>
            </ul>
          </ul>
        </>
      )}
    </>
  );
};
