export const checkOffering = (users_input, offers) => {
  // There will be 4 offering sorted
  // [{id:1, offer:1000},  {id:,offer:1200}, {id:,offer:1300}, {id:,offer:1400}]
  const sortedAmountOfferings = offers.sort(
    (a, b) => a.min_value - b.min_value
  );
  // get Index of closet offering uses the userinput and matches it to the proper offering
  // if user_input is 1000 it will match to {id:1,offer:1000} so the index is 1
  const indexClosestOffering = getIndexOfClosetOffering(
    users_input,
    sortedAmountOfferings
  );

  if (indexClosestOffering > -1) {
    const offeringToCheck = sortedAmountOfferings[indexClosestOffering];
    const equitySharePrice = +offeringToCheck?.unitPrice || 0.79;
    const shareValue = users_input / equitySharePrice;
    const intShareValue = Math.ceil(shareValue / 100) * 100;

    const option = {
      usersInvestment: Number((intShareValue * equitySharePrice).toFixed(2)),
      share: intShareValue,
      offeringId: offeringToCheck.offeringId,
    };
    return option;
  } else {
    return null;
  }
};

export const getIndexOfClosetOffering = (users_input, sorted_offering) => {
  const users_input_number = +users_input;
  let indexClosestOffering = -1;
  let lastDiff = null;

  for (let i = 0; i < sorted_offering.length; i++) {
    const diff = users_input_number - sorted_offering[i].min_value;

    if (diff >= 0 && (!lastDiff || (lastDiff && lastDiff > diff))) {
      indexClosestOffering = i;
      lastDiff = diff;
    }
  }

  return indexClosestOffering;
};
