const initialState = {
  loading: false,
  error: false,
  data: null,
  message: '',
};

const prospectReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PROSPECT_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_PROSPECT_SUCCESS':
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
      };
    case 'FETCH_PROSPECT_FAILURE':
      return {
        ...state,
        data: null,
        error: true,
        loading: false,
      };

    default:
      return state;
  }
};

export default prospectReducer;
