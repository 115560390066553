import React, { useState } from 'react';
import { useMedia } from 'react-use';

import Input from 'components/Input/Input';
import styles from './ACHForm.module.scss';
const ACHForm = ({
  register,
  setValue,
  setError,
  errors,
  watch,
  control,
  usersInvestment,
}) => {
  const media_query_1100 = useMedia('(max-width:1100px)');

  const {
    ach: { account_type, check_type },
  } = watch();

  return (
    usersInvestment < 100000 && (
      <div className="mt-8">
        <div className="relative">
          <h2 className="text-brandblue">Enter your account details here:</h2>

          <div
            className={`grid mt-2 ${
              media_query_1100 ? 'pt-2 grid-cols-1 gap-5' : 'grid-cols-3 gap-10'
            } `}
          >
            <div className={styles.account}>
              <label
                htmlFor="account_type"
                // className="block text-brandblue"
                className={styles.account__title}
              >
                Account type
              </label>
              <div className={styles.account__radios}>
                <div className={styles.account__radios__saving}>
                  <input
                    type="radio"
                    id="ach.savings"
                    name="ach.account_type"
                    value="ach.savings"
                    control={control}
                    checked={account_type === 'Savings'}
                    onChange={() => {
                      setValue('ach.account_type', 'Savings');
                      setError('ach.account_type', {});
                    }}
                  />
                  <label
                    htmlFor="savings"
                    className="block cursor-pointer ml-2 text-brandblue"
                    onClick={() => {
                      setValue('ach.account_type', 'Savings');
                      setError('ach.account_type', {});
                    }}
                  >
                    Savings
                  </label>
                </div>
                <div className={styles.account__radios__checking}>
                  <input
                    type="radio"
                    id="ach.checking"
                    name="ach.account_type"
                    value="ach.checking"
                    control={control}
                    checked={account_type === 'Checking'}
                    onChange={() => {
                      setValue('ach.account_type', 'Checking');
                      setError('ach.account_type', {});
                    }}
                  />
                  <label
                    htmlFor="checking"
                    className="block cursor-pointer  ml-1 text-brandblue"
                    onClick={() => {
                      setValue('ach.account_type', 'Checking');
                      setError('ach.account_type', {});
                    }}
                  >
                    Checking
                  </label>
                </div>
              </div>
              {errors?.ach?.account_type ? (
                <span className="text-brandred  text-sm">
                  {errors?.ach?.account_type?.message}
                </span>
              ) : null}
            </div>

            <Input
              label="Bank Name"
              id="ach.bank_name"
              name="ach.bank_name"
              register={register}
              error={errors?.ach?.bank_name?.message}
            />

            <Input
              label="Name On Account"
              id="ach.name_on_account"
              name="ach.name_on_account"
              register={register}
              error={errors?.ach?.name_on_account?.message}
            />
            {}
          </div>
          <div
            className={`grid mt-2 ${
              media_query_1100 ? 'pt-2 grid-cols-1 gap-5' : 'grid-cols-2 gap-10'
            } `}
          >
            <Input
              label="Account Number"
              id="ach.account_number"
              name="ach.account_number"
              register={register}
              error={errors?.ach?.account_number?.message}
            />
            <Input
              label="Routing Number"
              id="ach.routing_number"
              name="ach.routing_number"
              register={register}
              error={errors?.ach?.routing_number?.message}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default ACHForm;
