import React from 'react';
import { toast } from 'react-toastify';
import { AlertTriangle, CheckCircle, Bell } from 'react-feather';
import styles from './snackbar.module.scss';
export const showSnackBar = (message, type) => {
  const renderToaster = () => {
    switch (type) {
      case 'error':
        return toast.error(
          <div className={styles.container}>
            <p>
              Error from <span>your input</span>.
              <br />
              The server responded with.
              <br />
              <br />
              <span>{message}</span>
              <br />
              <br />
              Please try again
            </p>
          </div>,
          {
            autoClose: 40000,
          }
        );

      case 'info':
        return toast.info(
          <div className={styles.container}>
            <div>{message}</div>
          </div>,
          {
            autoClose: 40000,
          }
        );

      case 'success':
        return toast.success(
          <div className="flex items-center">
            <CheckCircle className="icon" />
            <p>Success: {message}</p>
          </div>
        );

      default:
        return toast(
          <div className="flex items-center">
            <AlertTriangle className="icon" />
            <p>Notification: {message}</p>
          </div>
        );
    }
  };

  return renderToaster();
};
