import React from 'react';
import cs from 'classnames';
import { useMedia } from 'react-use';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import styles from './SubscriptionAgreement.module.scss';
const SubscriptionAgreementUrl = () => {
  const offeringTerms = useSelector(state => {
    return get(state, 'offering.data.terms', null);
  });

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  return offering?.payment_enabled ? (
    <div className={cs('mt-3 text-sm text-brandgrey', styles.container)}>
      By clicking the "submit" button, you agree to electronically sign the{' '}
      <a
        className="underline"
        href={offeringTerms}
        target="_blank"
        rel="noreferrer"
      >
        subscription agreement
      </a>{' '}
      and agree to our &nbsp;
      <a
        className="underline"
        href={
          'https://storage.googleapis.com/north-cap-terms/Boxabl%20Terms%20and%20conditions.pdf'
        }
        target="_blank"
        rel="noreferrer"
      >
        electronic funds transfer terms
      </a>
      &nbsp; and you certify that your investment does not exceed 10% of your
      annual income or net worth.
    </div>
  ) : null;
};

export default SubscriptionAgreementUrl;
