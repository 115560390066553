import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import LogRocket from 'logrocket';
import TagManager from 'react-gtm-module';
import setupLogRocketReact from 'logrocket-react';
import { ToastContainer } from 'react-toastify';
import { ProtectedRoute } from './routes';
import InvestorInformation from '../src/views/InvestorInformation';
import InvestmentInformation from '../src/views/InvestmentInformation';
import VerificationDocuments from '../src/views/VerificationDocuments';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);
  setupLogRocketReact(LogRocket);
}

const tagManagerArgs = {
  gtmId: 'GTM-WJ9HWCJ',
};

TagManager.initialize(tagManagerArgs);

export const App = () => {
  const offeringName = useSelector(state => {
    return get(state, 'offering.data.issueName', null);
  });

  return (
    <>
      <ToastContainer
        theme="colored"
        newestOnTop
        hideProgressBar
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
      <Router>
        <Switch>
          <ProtectedRoute
            exact
            path="/:offeringName/investor-information"
            component={InvestorInformation}
          />
          <ProtectedRoute
            exact
            path="/:offeringName/payment"
            component={InvestmentInformation}
          />

          <ProtectedRoute
            exact
            path="/:offeringName/verification-documents"
            component={VerificationDocuments}
          />

          <Route
            exact
            path="*"
            render={() => (
              <Redirect to={`/${offeringName}/investor-information`} />
            )}
          />
        </Switch>
      </Router>
    </>
  );
};

export default App;
