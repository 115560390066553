// Importing all of the dependencies
import { v4 as uuidv4 } from 'uuid';
import { indentifyInLogRocket } from 'helpers/logrocket';
import React, { useEffect } from 'react';
import * as Yup from 'yup';

import { useMedia } from 'react-use';
import Button from '../../components/Button';
import InvestmentLayout from '../../components/InvestmentLayout';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import styles from './investorInformation.module.scss';

import BackArrow from '../../assets/images/back-arrow';
import BackArrowMobile from '../../assets/images/back-arrow-mobile';

import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ACHFormSchema,
  CreditCardFormSchema,
} from './InvestmentInformationSchema';
import StepNumber from 'components/StepNumber';
import useQuery from 'helpers/useQuery';

import Amount from './Amount';

import TransactionTypes from './TransactionTypes';
import CreditCardForm, { getCardType } from './Payments/CreditCardForm';

import SubmitInvestment from './SubmitInvestment';
import axios from 'axios';
import {
  fundMovement,
  accreditedEmail,
  updatePayment,
  analyticsMovement,
} from 'redux/actions/investor.action';
import ACHForm from './Payments/ACHForm';

import { ErrorPaymentOverlay } from './ErrorPaymentOverlay';
import { checkOffering } from './InvestmentUtlis';

import { showSnackBar } from 'redux/actions/snackbar';
import { updateProspectData } from 'redux/actions/formBodyActions';

const scheme = (minimumInvestment, hasPaymentEnabled) =>
  Yup.object({
    amount: Yup.number()
      .typeError('Amount is required')
      .test(
        'testUserInvestment',
        'share must be greater than min amount',
        (value, ctx) => {
          if (value && ctx.parent.usersInvestment >= minimumInvestment) {
            return true;
          } else {
            return false;
          }
        }
      ),
    share: Yup.number().required('Share is required'),
    usersInvestment: Yup.number().required('users Investment is required'),
    offeringId: Yup.string().required('An offering is required'),

    ...(hasPaymentEnabled
      ? {
          accountId: Yup.string().required('An Account Id is required'),

          createdIpAddress: Yup.string(),

          transactionTypes: Yup.string().required(
            'Transaction Type is required'
          ),

          creditCard: Yup.object().when('transactionTypes', {
            is: value => value && value === 'credit_card',
            then: CreditCardFormSchema,
            otherwise: Yup.object().shape({}),
          }),
          ach: Yup.object().when('transactionTypes', {
            is: value => value && value === 'ach',
            then: ACHFormSchema,
            otherwise: Yup.object().shape({}),
          }),
        }
      : {
          // add here validation of prospect data
        }),
  });

const InvestmentInformation = () => {
  let history = useHistory();
  const query = useQuery();

  const account = useSelector(({ investor }) =>
    get(investor, 'account.data', null)
  );

  const accountId = useSelector(({ investor }) =>
    get(investor, 'account.data.accountId', null)
  );

  const offeringName = useSelector(state => {
    return get(state, 'offering.data.issueName', null);
  });

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  const minimumInvestment = useSelector(state => {
    return get(state, 'offering.data.minimumInvestment', null);
  });

  const unitPrice = useSelector(state => {
    return get(state, 'offering.data.unitPrice', 0);
  });

  const fundMovementData = useSelector(state =>
    get(state, 'investor.fundMovement.data', null)
  );

  const prospect = useSelector(state => {
    return get(state, 'formBody.prospect.data', null);
  });

  const dispatch = useDispatch();

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    // setFocus,
    // getValues,
    register,
    setError,
    formState: { errors },
  } = useForm({
    validate: ['onSubmit'],
    revalidate: ['onSubmit', 'onBlur', 'onChange'],
    defaultValues: {
      // tnc_accepted: false,
      amount: '',
      transactionTypes:
        offering?.minimumInvestment > 5000 ? 'ach' : 'credit_card',
      share: null,
      usersInvestment: null,
      offeringId: null,
      accountId: null,
      createdIpAddress: '111.111.111.111',

      creditCard: {
        billing_name: '',
        credit_card_number: '',
        credit_card_cvv: '',
        credit_card_exp_month_year: '',
        credit_card_exp_month: '',
        credit_card_exp_year: '',
      },
      ach: {
        bank_name: '',
        account_number: '',
        account_type: '',
        name_on_account: '',
        routing_number: '',
      },
    },
    resolver: yupResolver(scheme(minimumInvestment, offering?.payment_enabled)),
  });

  const isMaxWidth600px = useMedia('(max-width:600px)');

  const handleChange = ({ value }, event) => {
    if (!offering) return null;
    if (!event || event.source === 'event') {
      const isOffering = checkOffering(value, offering.offerings);
      if (isOffering) {
        setValue('offeringId', isOffering.offeringId);
        setValue('share', isOffering.share);
        setValue('usersInvestment', isOffering.usersInvestment);
      } else {
        setValue('offeringId', '');
        setValue('share', null);
        setValue('usersInvestment', null);
      }
    }
  };

  useEffect(() => {
    const queryAmount = query.get('amount');
    if (queryAmount) {
      handleChange({ value: queryAmount });
      setValue('amount', queryAmount);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/');
    setValue('createdIpAddress', res.data.IPv4);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });

    setValue('accountId', accountId);

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { share, usersInvestment, transactionTypes, amount } = watch();

  const isRegAOverOneHundredThousand =
    offering?.type === 'reg_a' && usersInvestment >= 100000;

  const checkKeyDown = e => {
    if (e.code === 'Enter') {
      window.scrollTo(0, document.body.scrollHeight);
      e.preventDefault();
    }
  };

  const onSubmit = async value => {
    const {
      share,
      transactionTypes,
      usersInvestment,
      amount,
      creditCard: {
        billing_name,
        credit_card_cvv,
        credit_card_exp_month,
        credit_card_exp_year,
        credit_card_number,
      },
      ach: {
        bank_name,
        account_number,
        account_type,
        name_on_account,
        routing_number,
      },
      accountId,
      offeringId,
      createdIpAddress,
    } = value;
    let fund = null;

    if (offering?.payment_enabled === false) {
      await dispatch(
        updateProspectData(prospect._id.toString(), {
          investment: {
            usersInvestment,
            amount,
            share,
          },
          offering: offering._id.toString(),
        })
      );
      return history.push(`/${offeringName}/verification-documents`);
    }

    // magic link if payment fails
    if (query.get('code')) {
      if (transactionTypes === 'credit_card') {
        fund = await dispatch(
          updatePayment({
            transactionTypes: transactionTypes,
            trade: fundMovementData?.trade,
            findTrade: {
              nanoId: account?.nanoId,
              tradeId: fundMovementData?.trade?.tradeId.toString(),
              urlGeneratedNanoId: query.get('code'),
            },
            paymentInformation: {
              creditCardName: billing_name,
              creditCardNumber: credit_card_number.replace(/-/g, ''),
              expirationDate: credit_card_exp_month + credit_card_exp_year,
              cvvNumber: credit_card_cvv,
              cardType: getCardType(credit_card_number),
              updatedIpAddress: createdIpAddress,
            },
          })
        );
      }

      if (transactionTypes === 'ach') {
        fund = await dispatch(
          updatePayment({
            transactionTypes: transactionTypes,
            trade: fundMovementData?.trade,
            amount: fundMovementData?.trade?.transactionAmount,
            offeringId: fundMovementData?.trade?.offeringId,
            findTrade: {
              nanoId: account?.nanoId,
              tradeId: fundMovementData?.trade?.tradeId.toString(),
              urlGeneratedNanoId: query.get('code'),
              updatedIpAddress: createdIpAddress,
            },
            paymentInformation: {
              types: 'Account',
              ExtBankname: bank_name,
              ExtAccountfullname: name_on_account,
              ExtRoutingnumber: routing_number,
              ExtAccountnumber: account_number,
              Extnickname: name_on_account,
              accountType: account_type,
              description: offering.issueName,
            },
          })
        );
      }

      if (fund) {
        dispatch(analyticsMovement());
        return history.push(`/${offeringName}/verification-documents`);
      }
      return null;
    }

    // NEW INVESTORS
    if (transactionTypes === 'credit_card') {
      fund = await dispatch(
        fundMovement({
          transactionTypes: transactionTypes,
          trade: {
            offeringId: offeringId,
            accountId: accountId,
            transactionUnits: share.toString(),
            createdIpAddress: createdIpAddress,
            offeringName: offeringName,
            offeringType: offering?.type,
          },
          paymentInformation: {
            creditCardName: billing_name,
            creditCardNumber: credit_card_number.replace(/-/g, ''),
            expirationDate: credit_card_exp_month + credit_card_exp_year,
            cvvNumber: credit_card_cvv,
            cardType: getCardType(credit_card_number),
            createdIpAddress: createdIpAddress,
          },
        })
      );
    }

    if (transactionTypes === 'ach') {
      fund = await dispatch(
        fundMovement({
          transactionTypes: transactionTypes,
          trade: {
            offeringId: offeringId,
            accountId: accountId,
            transactionUnits: share.toString(),
            createdIpAddress: createdIpAddress,
            offeringName: offeringName,
            offeringType: offering?.type,
          },
          paymentInformation: {
            types: 'Account',
            ExtBankname: bank_name,
            ExtAccountfullname: name_on_account,
            ExtRoutingnumber: routing_number,
            ExtAccountnumber: account_number,
            Extnickname: name_on_account,
            accountType: account_type,
            description: offering.issueName,
          },
        })
      );
    }

    if (transactionTypes === 'wire' || transactionTypes === 'check') {
      fund = await dispatch(
        fundMovement({
          transactionTypes: transactionTypes,
          trade: {
            offeringId: offeringId,
            offeringName: offeringName,
            accountId: accountId,
            transactionUnits: share.toString(),
            createdIpAddress: createdIpAddress,
            offeringType: offering?.type,
          },
        })
      );
    }

    if (fund) {
      if (offeringName === 'reg_d_2') {
        dispatch(
          accreditedEmail({
            nanoId: account?.nanoId,
            offeringName: offeringName,
          })
        );
      }
      dispatch(analyticsMovement());

      return history.push(`/${offeringName}/verification-documents`);
    }

    return null;
  };

  //Load paymentFailed
  useEffect(() => {
    if (query.get('code') && fundMovementData) {
      const {
        trade: {
          transactionAmount: amount,
          offeringId,
          transactionUnits: share,
          transactionType,
          offeringName: tradeOfferingName,
        },
      } = fundMovementData;
      setValue('offeringId', offeringId);
      setValue('amount', amount);
      setValue('usersInvestment', amount);
      setValue('share', share);
      setValue(
        'transactionTypes',
        transactionType === 'CREDITCARD'
          ? 'credit_card'
          : transactionType === 'ACH'
          ? 'ach'
          : 'credit_card'
      );
      setValue('accountId', accountId);

      if (fundMovementData?.trade) {
        if (tradeOfferingName !== offeringName) {
          showSnackBar(
            'You did not invest in reg_a. You are currently investing in reg_d_2',
            'error'
          );
        }
      }

      if (account) {
        indentifyInLogRocket(uuidv4(), {
          name: account?.accountRegistration,
          email: account?.email,
          phone: account?.phone,
          type: account?.investedAs,
          is: 'payment',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get('code'), fundMovementData]);

  return (
    <InvestmentLayout>
      <div className={styles.root}>
        <div className={`${styles.container} ${styles.investmentInformation}`}>
          <div
            className={`${styles.titleHeader} ${isMaxWidth600px &&
              'shadow-sm'}`}
          >
            <Button
              className={styles.investorInformation_backButton}
              onClick={() => {
                history.push(`/${offeringName}/investor-information`);
              }}
            >
              {isMaxWidth600px ? <BackArrowMobile /> : <BackArrow />}
              <span>Back</span>
            </Button>
            <div className="flex flex-col">
              <StepNumber />
              <h1
                className={`${
                  styles.investmentInformation_titleText
                } ${!isMaxWidth600px && 'mb-3'}`}
              >
                Investment
              </h1>
            </div>
          </div>

          <div
            className={`${styles.personalInformation_container} flex flex-col`}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={e => checkKeyDown(e)}
            >
              <>
                {' '}
                <Amount
                  unitPrice={unitPrice}
                  errors={errors}
                  control={control}
                  minimumInvestment={minimumInvestment}
                  handleChange={handleChange}
                  share={share}
                  usersInvestment={usersInvestment}
                  transactionTypes={transactionTypes}
                  amount={amount}
                />
                {offering?.payment_enabled ? (
                  query.get('code') || isRegAOverOneHundredThousand ? null : (
                    <TransactionTypes
                      setValue={setValue}
                      control={control}
                      name={'transactionTypes'}
                    />
                  )
                ) : null}
              </>
              {offering?.payment_enabled ? (
                <>
                  {' '}
                  {transactionTypes === 'credit_card' &&
                    usersInvestment < 5000 && (
                      <CreditCardForm
                        usersInvestment={usersInvestment}
                        register={register}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        errors={errors}
                      />
                    )}
                  <ErrorPaymentOverlay
                    error={
                      (transactionTypes === 'credit_card' &&
                        usersInvestment > 5000) ||
                      (transactionTypes === 'ach' &&
                        usersInvestment >= 100000) ||
                      isRegAOverOneHundredThousand
                    }
                    isRegAOverOneHundredThousand={isRegAOverOneHundredThousand}
                    transactionTypes={transactionTypes}
                    setValue={setValue}
                  />
                  {transactionTypes === 'ach' && (
                    <ACHForm
                      usersInvestment={usersInvestment}
                      register={register}
                      setError={setError}
                      setValue={setValue}
                      control={control}
                      watch={watch}
                      errors={errors}
                    />
                  )}
                </>
              ) : null}

              {isRegAOverOneHundredThousand &&
              offering?.payment_enabled ? null : (
                <SubmitInvestment
                  register={register}
                  offering={offering}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  errors={errors}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </InvestmentLayout>
  );
};

export default InvestmentInformation;
