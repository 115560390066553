export const formatEntityObject = (
  data,
  hasBirthDate = false,
  hasRegionFormed = false
) => {
  const {
    tax_id_number,
    birth_date,
    birth_month,
    birth_year,
    country,
    region,
    region_formed_in,
    accredited,
    accreditation_type,
    annual_income,
    net_worth,
    us_citizen_or_resident,
    ...restData
  } = data;
  return {
    ...restData,
    ...(hasBirthDate
      ? {
          //for person only
          us_citizen_or_resident: country?.value === 'US' ? true : false,
          date_of_birth: `${birth_year.value}-${birth_month.value}-${birth_date.value}`,
        }
      : {}),
    ...(hasRegionFormed
      ? {
          region_formed_in: region_formed_in,
        }
      : {}),

    country: country,
    region: region ? region : null,
    tax_id_number: '',
  };
};

export default formatEntityObject;
