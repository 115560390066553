import { get } from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PaymentCard from './Payments/PaymentCard';
import styles from './TransactionTypes.module.scss';
const TransactionTypes = ({ setValue, control, name }) => {
  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  const paymentSelection = payment_type => {
    setValue(name, payment_type);
  };
  React.useEffect(() => {
    if (offering?.minimumInvestment > 5000) {
      paymentSelection('ach');
    } else {
      paymentSelection('credit_card');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Controller
        name={name}
        render={({ field: ControllerField }) => {
          const {
            onBlur: ControllerOnBlur,
            onChange: ControllerOnChange,
            value: ControllerValue,
            name: ControllerName,
            ...ControllerRef
          } = ControllerField;

          return (
            <div className={styles.TransactionTypes}>
              {offering?.minimumInvestment < 5000 && (
                <PaymentCard
                  title={'Credit Card'}
                  paragraph={'Visa & Mastercard'}
                  chosen_card={ControllerValue}
                  card_name={'credit_card'}
                  onClick={() => paymentSelection('credit_card')}
                />
              )}

              <PaymentCard
                title="ACH (Electronic Check)"
                paragraph="US Bank Accounts Only"
                chosen_card={ControllerValue}
                card_name={'ach'}
                onClick={() => paymentSelection('ach')}
              />
              <PaymentCard
                title="Mail a Check"
                card_name="check"
                chosen_card={ControllerValue}
                onClick={() => paymentSelection('check')}
              />
              <PaymentCard
                title="Wire Transfer"
                card_name="wire"
                chosen_card={ControllerValue}
                onClick={() => paymentSelection('wire')}
              />
            </div>
          );
        }}
        control={control}
      />
    </div>
  );
};

/*
      <PaymentTypeCard
        control={control}
        title="Credit Card"
        value="credit_card"
        name="transactionTypes"
        paragraph="Visa & Mastercard"
        active={false}
        setValue={setValue}
      />

      <PaymentTypeCard
        control={control}
        title="ACH (Electronic Check)"
        paragraph="US Bank Accounts Only"
        value="ach"
        active={false}
        changeTransferOption={() => setValue('transactionTypes', 'ach')}
      />

      <PaymentTypeCard
        control={control}
        title="Mail a Check"
        value="check"
        active={false}
        changeTransferOption={() => setValue('transactionTypes', 'check')}
      />

      <PaymentTypeCard
        control={control}
        title="Wire Transfer"
        value="wire"
        active={false}
        changeTransferOption={() => setValue('transactionTypes', 'wire')}
      /> */
export default TransactionTypes;
