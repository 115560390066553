import React, { useState, useEffect } from 'react';
import { useMedia } from 'react-use';
import { Info, CheckCircle, AlertTriangle } from 'react-feather';
import cs from 'classnames';
import Input from 'components/Input/Input';
import { InputMask } from 'components/Input/InputMask';
import { NumberMask } from 'components/Input/NumberMask';
import Select from 'components/Select';
import styles from './investorForms.module.scss';
import switchStyle from '../formBody.module.scss';
import { countriesOptions } from 'helpers/country';
import AddressForm from './AddressForm';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { isEmpty } from 'lodash';
import { indentifyInLogRocket } from 'helpers/logrocket';
import { v4 as uuidv4 } from 'uuid';
import { currentYear, range } from 'helpers/year';
import NSelect from 'components/NSelect/NSelect';
import { months } from 'helpers/months';
export const personInitialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  street_address_1: '',
  street_address_2: '',
  city: '',
  country: {
    label: 'United States',
    value: 'US',
  },
  region: {
    label: 'Select State',
    value: false,
  },
  postal_code: '',
  tax_id_number: '',
  accredited: true,
  accreditation_type: 'individual_net_worth',
  us_citizen_or_resident: true,
  annual_income: '0',
  net_worth: '0',
};
const PersonForm = ({
  investAsPrevious,
  register,
  type,
  errors,
  watch,
  control,
  setError,
  setValue,
  getValues,
  setFocus,
}) => {
  const [day, setDays] = useState(32);
  const media_query = useMedia('(max-width:600px)');

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  useEffect(() => {
    if (!isEmpty(errors)) {
      if (getValues('person').postal_code.length > 0) {
        if (
          isEmpty(errors?.person?.postal_code) &&
          isEmpty(errors?.person?.email) &&
          isEmpty(errors?.person?.phone) &&
          isEmpty(errors?.person?.name)
        ) {
          indentifyInLogRocket(uuidv4(), {
            name: getValues('person').name,
            email: getValues('person').email,
            phone: getValues('person').phone,
            type: type,
          });
        }
      }
    }
  }, [errors, getValues, type]);
  useEffect(() => {
    if (!isEmpty(errors)) {
      const personFocusOnly = Object.keys(errors).filter(
        (e, i) => e !== 'person'
      );

      if (personFocusOnly > 0) {
        if (errors.person) {
          let firstError = Object.keys(errors.person);

          if (firstError[0] === 'country') return;
          setFocus(`person.${firstError[0]}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <div className={styles.root}>
      <h2 className="mt-12 mb-4">Basic Details</h2>
      <div
        className={`grid mt-2 ${
          !media_query && watch(type)?.country?.value !== 'US'
            ? 'grid-cols-2 gap-5'
            : media_query
            ? 'grid-cols-1 gap-5'
            : 'grid-cols-2 gap-5'
        } `}
      >
        <Input
          autoComplete={'given-name'}
          type={'text'}
          label="First Name"
          name={`${type}.firstName`}
          register={register}
          error={
            errors[type]?.firstName ? errors[type]?.firstName?.message : null
          }
        />
        <Input
          autoComplete={'family-name'}
          type={'text'}
          label="Last Name"
          name={`${type}.lastName`}
          register={register}
          error={
            errors[type]?.lastName ? errors[type]?.lastName?.message : null
          }
        />
        <NSelect
          autoComplete={'country'}
          control={control}
          label={'Country'}
          id={`${type}.country`}
          name={`${type}.country`}
          getValues={getValues}
          setError={setError}
          error={errors[type]?.country ? errors[type]?.country?.message : null}
          list={countriesOptions}
          setValue={setValue}
          type={type}
          onChange={option => {
            if (option) {
              // if you switch from US to pakistan back to us
              // region will need to be redefined  will prevent
              // mui bug where region will be undefined...
              setValue(`${type}.region`, {
                label: 'Select State',
                value: false,
              });
              if (option && option.value !== 'US') {
                setValue(`${type}.tax_id_number`, '999-99-9999');
                setValue(`${type}.region`, '');
              } else {
                setValue(`${type}.tax_id_number`, '');
              }
            }
          }}
        />
        <InputMask
          type={'phone'}
          label="Phone Number"
          id={`${type}.phone`}
          name={`${type}.phone`}
          error={errors[type]?.phone ? errors[type]?.phone?.message : null}
          control={control}
          format={
            watch(type)?.country?.value === 'US'
              ? '(###) ###-####'
              : '################'
          }
        />
        <Input
          type={'email'}
          label="Email"
          id={`${type}.email`}
          name={`${type}.email`}
          register={register}
          error={errors[type]?.email ? errors[type]?.email?.message : null}
          disabled={investAsPrevious}
        />
        {watch(type)?.country?.value === 'US' && offering?.payment_enabled ? (
          <>
            <InputMask
              id={`${type}.tax_id_number`}
              name={`${type}.tax_id_number`}
              label="Social Security Number (SSN)"
              control={control}
              error={
                errors[type]?.tax_id_number
                  ? errors[type]?.tax_id_number?.message
                  : null
              }
              format="###-##-####"
              maskChar=""
            />

            <br />
          </>
        ) : null}
        <br />

        {watch(type)?.country?.value === 'US' && <div />}

        {offering?.payment_enabled === false &&
          watch(type)?.country?.value === 'US' && <div /> && (
            // Date Of Birth CSS fix
            // this will make the label align to left because this html is based on GRID
            <div />
          )}
        <label className="block text-brandblue mb-1">Date Of Birth </label>
        <div
          className={`grid ${
            !media_query
              ? 'grid-cols-3 gap-5 w-full col-span-2'
              : media_query
              ? 'grid-cols-1 gap-5'
              : 'grid-cols-3 gap-2'
          } `}
        >
          <NSelect
            label={'Month'}
            control={control}
            id={`${type}.birth_month`}
            name={`${type}.birth_month`}
            autoComplete={'bday-month'}
            getValues={getValues}
            setError={setError}
            error={
              errors[type]?.birth_month
                ? errors[type]?.birth_month?.message
                : null
            }
            list={months}
            setValue={setValue}
            onChange={e => {
              if (e) {
                if (e.value === '02') {
                  setDays(29);
                } else if (
                  e.value === '04' ||
                  e.value === '06' ||
                  e.value === '09' ||
                  e.value == '11'
                ) {
                  setDays(31);
                } else {
                  setDays(32);
                }
              }
            }}
          />

          <NSelect
            type={type}
            autoComplete={'bday-day'}
            id={`${type}.birth_date`}
            name={`${type}.birth_date`}
            control={control}
            label="Day"
            setError={setError}
            error={
              errors[type]?.birth_date
                ? errors[type]?.birth_date?.message
                : null
            }
            getValues={getValues}
            setValue={setValue}
            list={[...Array(day).keys()]
              .map((e, i) => {
                if (e == 0) {
                  return;
                } else {
                  if (e <= 9) {
                    return {
                      label: e.toString(),
                      value: '0' + e.toString(),
                    };
                  } else {
                    return {
                      label: e.toString(),
                      value: e.toString(),
                    };
                  }
                }
              })
              .filter(notUndefined => notUndefined !== undefined)}
          />

          <NSelect
            type={type}
            setValue={setValue}
            autoComplete={'bday-year'}
            id={`${type}.birth_year`}
            name={`${type}.birth_year`}
            control={control}
            label="Year"
            setError={setError}
            error={
              errors[type]?.birth_year
                ? errors[type]?.birth_year?.message
                : null
            }
            getValues={getValues}
            list={range(currentYear - 18, currentYear - 18 - 120, -1)}
          />
        </div>
        {errors[type] &&
        errors[type].type === 'dateOfBirthOverEighteen' &&
        errors[type].message ? (
          <span className="text-brandred -mt-2 text-sm font-medium flex items-center">
            <AlertTriangle size={18} />
            &nbsp;{errors[type].message}
          </span>
        ) : null}
      </div>

      <AddressForm
        register={register}
        watch={watch}
        errors={errors}
        control={control}
        investAsPrevious={investAsPrevious}
        type={type}
        setError={setError}
        setValue={setValue}
        getValues={getValues}
      />
    </div>
  );
};

export default PersonForm;
