import * as Yup from 'yup';
import { getPersonSchema } from '../EntityForms/personSchema';
import { personInitialValues } from '../EntityForms/PersonForm';
import { countriesOptions, stateOptionsUS } from 'helpers/country';
import { currentYear, range } from 'helpers/year';
import { month } from 'helpers/month';
const jointInvestorOptions = [
  { label: 'Joint', value: 'Joint' },
  {
    label: 'Joint Tenants with Right of Survivorship',
    value: 'JTWROS',
  },
  { label: 'Joint Tenants in Common', value: 'TIC' },
];

const getIndividualInvestorSchema = hasPaymentEnabled =>
  Yup.object({
    person: getPersonSchema(hasPaymentEnabled),
    is_joint: Yup.bool().default(false),
    joint_type: Yup.object()
      .nullable()
      .when('is_joint', {
        is: true,
        then: Yup.object()
          .nullable()
          .required('Joint Account Type is required'),
      }),
    joint_person: Yup.object().when('is_joint', {
      is: true,
      then: getPersonSchema(hasPaymentEnabled),
    }),
  });

export const getIndividualInvestorDefaultValues = (
  defaultValues,
  { joint_type = '', ...jointValues }
) => {
  return {
    invested_as: 'individual',
    person: {
      ...personInitialValues,
      type: 'person',
      ...defaultValues,
    },
    is_joint: false,
    joint_type: joint_type,
    joint_person: {
      ...personInitialValues,
      email: '',
      type: 'person',
      ...jointValues,
    },
  };
};

export const injectDefaultValueIndividual = (
  restInitialValues,
  prospectedByInvestorData
) => {
  // Does Prospect have data?
  const isProspectHasData =
    typeof prospectedByInvestorData === 'object'
      ? Object.keys(prospectedByInvestorData).length
      : false;

  const birthDay = isProspectHasData
    ? (() => {
        const splitedBirthDay = prospectedByInvestorData?.person?.date_of_birth.split(
          '-'
        );

        const birthYear = splitedBirthDay[0];

        const foundMonth = month.find((e, i) => e.value === splitedBirthDay[1]);

        const birthMonth = foundMonth
          ? foundMonth
          : {
              label: 'Select Month',
              value: false,
            };

        const birth_day = splitedBirthDay[2];
        return {
          birth_date: {
            label: birth_day,
            value: birth_day,
          },
          birth_month: birthMonth,
          birth_year: {
            label: birthYear,
            value: birthYear,
          },
        };
      })()
    : {
        birth_date: {
          label: 'Select Day',
          value: false,
        },
        birth_month: {
          label: 'Select Month',
          value: false,
        },
        birth_year: {
          label: 'Select Year',
          value: false,
        },
      };

  return {
    ...getIndividualInvestorDefaultValues(
      {
        ...birthDay,
        ...restInitialValues,
        ...(prospectedByInvestorData?.person
          ? {
              ...prospectedByInvestorData.person,
              phone: prospectedByInvestorData.person.phone,
              country: prospectedByInvestorData.person.country,
              region:
                prospectedByInvestorData.person.country === 'US'
                  ? stateOptionsUS.find(
                      data =>
                        data.value === prospectedByInvestorData.person.region
                    )
                  : prospectedByInvestorData.person.region,
            }
          : {}),
      },
      {
        ...birthDay,
        ...restInitialValues,
        ...(prospectedByInvestorData.joint_person
          ? {
              ...prospectedByInvestorData.joint_person,
              joint_type: jointInvestorOptions.find((e, i) => {
                if (e.value === 'TIC') {
                  if (prospectedByInvestorData.joint_type === 'jtc') {
                    return e;
                  }
                }

                return e.value === prospectedByInvestorData.joint_type;
              }),
            }
          : {}),
      }
    ),
  };
};

export default getIndividualInvestorSchema;
