import React from 'react';
import styles from './verification-document.module.scss';
import { useMedia } from 'react-use';
import cs from 'classnames';
// DUMMY ICON
import { UploadCloud, Upload, CheckCircle } from 'react-feather';
import LoadSpinner from 'assets/images/load-spinner';
const DropAccreditionDocument = ({ DocumentDropzone, account }) => {
  const isMaxWidth455px = useMedia('(max-width:455px)');

  return (
    <div
      className={cs(styles.reactDropzone, 'w-full xl:w-8/12')}
      {...DocumentDropzone.getRootProps()}
    >
      <div
        className={`p-8 shadow-md w-full transition border-dashed duration-500 ease-in-out border-2 rounded-lg flex flex-col items-center justify-center ${
          DocumentDropzone.isDragActive
            ? 'bg-brandblue text-white border-white'
            : 'bg-white border-brandorange'
        }`}
        data-testid="dragAML"
      >
        <span
          className={cs('mb-2 relative my-3 flex items-center justify-center', {
            'text-white': DocumentDropzone.isDragActive,
            'text-brandblue': !DocumentDropzone.isDragActive,
          })}
        >
          {account?.isUploadLoading ? (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '18px',
                }}
              >
                <LoadSpinner />
                <div>Please wait!...</div>
              </div>
            </>
          ) : (
            <>
              <UploadCloud
                size={isMaxWidth455px ? 30 : 40}
                className={cs(styles.icon_uploadCloud, {
                  [styles.active]: DocumentDropzone.isDragActive,
                })}
              />
              <Upload
                size={isMaxWidth455px ? 30 : 40}
                className={cs(styles.icon_upload, {
                  [styles.notActive]: DocumentDropzone.isDragActive,
                })}
              />
            </>
          )}
        </span>
        <p
          className={cs(
            'mb-0 text-center mt-4 transition duration-500 ease-in-out',
            {
              'text-medium': isMaxWidth455px,
              'text-xl': !isMaxWidth455px,
              'text-white': DocumentDropzone.isDragActive,
              'text-brandblue': !DocumentDropzone.isDragActive,
            }
          )}
        >
          Drop your{' '}
          <span
            style={{
              color: '#ffa400',
              fontWeight: '600',
              textDecoration: 'underline',
            }}
          >
            accreditation verification
          </span>{' '}
          documents here, or{' '}
          <span
            className={cs('cursor-pointer font-bold ', {
              'text-white': DocumentDropzone.isDragActive,
              'text-brandblue': !DocumentDropzone.isDragActive,
            })}
          >
            click here
            <input
              className="opacity-0 absolute z-10 top-0 right-0 bottom-0 left-0"
              {...DocumentDropzone.getInputProps()}
            />
          </span>
        </p>
        <p
          className={cs(
            'mb-0 text-center transition duration-500 ease-in-out',
            {
              'text-sm': isMaxWidth455px,
              'text-medium': !isMaxWidth455px,
              'text-white': DocumentDropzone.isDragActive,
              'text-brandblue': !DocumentDropzone.isDragActive,
            }
          )}
        >
          Supports: JPG, PNG, PDF
        </p>
      </div>
    </div>
  );
};

export default DropAccreditionDocument;
