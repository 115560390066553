import React, { useEffect } from 'react';
import { IndividualText } from './AMLDocumentsText';
import cs from 'classnames';
import styles from './KYCAMLText.module.scss';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import useQuery from 'helpers/useQuery';

const KYCAMLText = () => {
  const query = useQuery();
  const scrollTo = query.get('scrollTo');
  const ref = React.useRef(null);
  const latestParties = useSelector(({ investor }) =>
    get(investor, 'parties.data', [
      {
        amlStatus: 'Approved',
        kycStatus: 'Approved',
      },
    ])
  );

  const unApprovedParties = latestParties
    ? latestParties.reduce((acc, item) => {
        if (
          item?.amlStatus === 'Disapproved' ||
          item?.kycStatus === 'Disapproved' ||
          item?.amlStatus === 'Need More Info' ||
          item?.kycStatus === 'Need More Info'
        ) {
          acc.push(item);
        }
        return acc;
      }, [])
    : [
        {
          amlStatus: 'Approved',
          kycStatus: 'Approved',
        },
      ];

  useEffect(() => {
    if (scrollTo === 'aml') {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {' '}
      <div
        className="w-full flex justify-start"
        ref={ref}
        id={'amlDocumentTitleText'}
      >
        <h1 className={cs(styles.title, 'mt-5 mb-2')}>AML Documents</h1>
      </div>
      <div className={cs('mt-2 w-full')}>
        {/* {accountType === 'Trust' && <TrustText />} */}

        <div className={styles.amlTextContainer}>
          Please Drop Your AML Documents In The{' '}
          <span
            style={{
              color: '#ffa400',
              fontWeight: '600',
              textDecoration: 'underline',
            }}
          >
            Correct Box
          </span>
          .
          <span style={{ color: '#ffa400', fontWeight: '600' }}>
            {unApprovedParties.map(
              (e, i) =>
                ' ' +
                e?.firstName +
                ` ` +
                e?.lastName +
                ` ${unApprovedParties.length === 2 && i === 0 ? ' And ' : `. `}`
            )}
          </span>
          {unApprovedParties.map(
            (e, i) =>
              e?.notes &&
              e?.notes.length > 0 &&
              i === 0 && (
                <span key={i}>
                  {' '}
                  Please
                  <span
                    style={{
                      color: 'orange',
                      textDecoration: 'underline',
                      fontWeight: '600',
                    }}
                  >
                    {' '}
                    Read Our Notes Below To Upload Required Documents To
                    Complete Your Investment
                  </span>
                  .
                </span>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default KYCAMLText;
