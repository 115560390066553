import React, { useState } from 'react';
import { useMedia } from 'react-use';

import Cards from 'react-credit-cards';

import Input from 'components/Input/Input';
import { InputMask } from 'components/Input/InputMask';
import styles from './CreditCardForm.module.scss';
import { CreditCardOverlay } from 'views/InvestorInformation/Overlays/CreditCardOverlay';
export const getCardType = number => {
  // visa
  const re = new RegExp('^4');
  if (number.match(re) != null) return 'VI';

  // Mastercard

  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
      number.replace(/-/g, '')
    )
  ) {
    return 'MC';
  }

  return '';
};

const CreditCardForm = ({
  watch,
  control,
  register,
  setValue,
  errors,
  usersInvestment,
}) => {
  const [focused, setFocused] = useState(null);

  const media_query = useMedia('(max-width:600px)');
  const media_query_1470 = useMedia('(max-width:1565px)');

  const {
    creditCard: {
      credit_card_number,
      credit_card_cvv,
      credit_card_exp_month_year,
      billing_name,
    },
  } = watch();

  return (
    <div>
      <div
        className={`relative mt-4 py-4 ${
          media_query_1470 ? 'pt-2 grid-cols-1 gap-5' : 'grid-cols-3 gap-5'
        } `}
      >
        <div className="flex flex-col items-center justify-start col-span-1">
          <h2 className="my-3 font-medium" style={{ color: '#243b53' }}>
            Enter your credit card details here:
          </h2>

          <Cards
            acceptedCards={['visa', 'mastercard']}
            focused={focused}
            cvc={credit_card_cvv}
            expiry={credit_card_exp_month_year}
            name={billing_name}
            number={credit_card_number && credit_card_number.replace(/-/g, '')}
          />
        </div>
        <div
          className={`col-span-2 grid mt-6 ${
            media_query ? 'pt-2 grid-cols-1 gap-5' : 'grid-cols-2 gap-3'
          } `}
        >
          <div className="flex flex-col">
            <InputMask
              type={'cc-number'}
              format="####-####-####-####"
              name="creditCard.credit_card_number"
              id="creditCard.credit_card_number"
              label="Credit Card Number"
              control={control}
              error={errors?.creditCard?.credit_card_number?.message}
              onBlur={() => {
                setFocused(null);
              }}
              onFocus={() => {
                setFocused('number');
              }}
            />
          </div>
          <div className="flex flex-col">
            <InputMask
              type={'cc-cvc'}
              id="creditCard.credit_card_cvv"
              name="creditCard.credit_card_cvv"
              format="###"
              label="CVV"
              control={control}
              error={errors?.creditCard?.credit_card_cvv?.message}
              onBlur={() => {
                setFocused(null);
              }}
              onFocus={() => {
                setFocused('cvc');
              }}
            />
          </div>
          <Input
            label="Cardholder Name"
            id="creditCard.billing_name"
            name="creditCard.billing_name"
            register={register}
            error={errors?.creditCard?.billing_name?.message}
            type={'cc-name'}
            onBlur={() => {
              setFocused(null);
            }}
            onFocus={() => {
              setFocused('name');
            }}
          />
          <div className="flex flex-col">
            <InputMask
              type={'cc-exp'}
              id="creditCard.credit_card_exp_month_year"
              name="creditCard.credit_card_exp_month_year"
              format="##/##"
              label="Expiration Date"
              control={control}
              error={errors?.creditCard?.credit_card_exp_month_year?.message}
              onBlur={() => {
                setFocused(null);
              }}
              onFocus={() => {
                setFocused('expiry');
              }}
              onChange={event => {
                const value = event.target.value.split('/').map(v => v.trim());
                if (value[0] === '0' || value[0] === '00') {
                  value[0] = '01';
                } else if (value[0].split('')[0] > 1) {
                  value[0] = `0${value[0]}`;
                } else if (parseInt(value[0]) > 12) {
                  value[0] = 12;
                }
                setValue('creditCard.credit_card_exp_month', value[0]);
                setValue('creditCard.credit_card_exp_year', value[1]);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardForm;
