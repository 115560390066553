import React from 'react';

const NextArrow = () => (
  <svg
    width="22"
    height="12"
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5303 6.53033C21.8232 6.23743 21.8232 5.76256 21.5303 5.46967L16.7574 0.696698C16.4645 0.403804 15.9896 0.403804 15.6967 0.696698C15.4038 0.989591 15.4038 1.46446 15.6967 1.75736L19.9393 6L15.6967 10.2426C15.4038 10.5355 15.4038 11.0104 15.6967 11.3033C15.9896 11.5962 16.4645 11.5962 16.7574 11.3033L21.5303 6.53033ZM6.55671e-08 6.75L21 6.75L21 5.25L-6.55671e-08 5.25L6.55671e-08 6.75Z"
      fill="#243B53"
    />
  </svg>
);

export default NextArrow;
