import TagManager from 'react-gtm-module';

export const analytics = ({ dataLayer }) => {
  try {
    TagManager.initialize({ gtmId: 'GTM-WJ9HWCJ' });
    TagManager.dataLayer({ dataLayer });
  } catch (err) {
    console.error('Something went Wrong with GTM ');
  }
};
