const initialState = {
  investment_success_on_new_investor: false,
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INVESTMENT_SUCCESS_ON_NEW_INVESTOR':
      return {
        ...state,
        investment_success_on_new_investor: true,
      };

    default:
      return state;
  }
};

export default analyticsReducer;
