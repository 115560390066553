import React from 'react'; // useEffect

import { useSelector } from 'react-redux';
import get from 'lodash/get';

import KYCAMLText from './KYCAMLText';
import DropAMLDocuments from './DropAMLDocuments';

export const KYCAML = ({ onDrop }) => {
  const latestParties = useSelector(({ investor }) =>
    get(investor, 'parties.data', [
      {
        amlStatus: 'Approved',
        kycStatus: 'Approved',
      },
    ])
  );

  const latestEntity = useSelector(({ investor }) =>
    get(investor, 'entity.data', {
      AMLstatus: 'Approved',
      KYCstatus: 'Approved',
    })
  );

  const isAllPartiesKycAmlApproved = latestParties
    ? latestParties
        .map((e, i) => {
          const isNorthCapital =
            e?.reviewBy === 'NorthCapital'
              ? e?.amlStatus === 'Disapproved' ||
                e?.kycStatus === 'Disapproved' ||
                e?.amlStatus === 'Need More Info' ||
                e?.kycStatus === 'Need More Info'
              : false;

          const isBoxabl =
            e?.reviewBy === 'Boxabl'
              ? e?.amlStatus === 'Disapproved' ||
                e?.kycStatus === 'Disapproved' ||
                e?.amlStatus === 'Need More Info' ||
                e?.kycStatus === 'Need More Info' ||
                e?.amlStatus === 'Pending' ||
                e?.kycStatus === 'Pending' ||
                e?.overrideUpload
              : false;
          // dont show if boxabl citizen pending
          const isBoxablCitizenPending =
            e?.reviewBy === 'Boxabl' && e?.domicile === 'U.S. Citizen'
              ? e?.amlStatus === 'Pending' || e?.kycStatus === 'Pending'
              : false;

          return (
            isNorthCapital ||
            (isBoxabl && !isBoxablCitizenPending) ||
            // if boxabl wants to force user to upload
            e?.overrideUpload
          );
        })
        .every(v => v === false)
    : true;

  const isEntityNorthCap =
    latestEntity?.reviewBy === 'NorthCapital'
      ? latestEntity?.AMLstatus === 'Disapproved' ||
        latestEntity?.KYCstatus === 'Disapproved' ||
        latestEntity?.AMLstatus === 'Need More Info' ||
        latestEntity?.KYCstatus === 'Need More Info'
      : false;

  const isEntityBoxabl =
    latestEntity?.reviewBy === 'Boxabl'
      ? latestEntity?.AMLstatus === 'Disapproved' ||
        latestEntity?.KYCstatus === 'Disapproved' ||
        latestEntity?.AMLstatus === 'Need More Info' ||
        latestEntity?.KYCstatus === 'Need More Info' ||
        latestEntity?.AMLstatus === 'Pending' ||
        latestEntity?.KYCstatus === 'Pending'
      : false;

  const isEntityKycAmlApproved =
    isEntityNorthCap ||
    isEntityBoxabl ||
    // if boxabl wants to force user to upload
    latestEntity?.overrideUpload;

  return isAllPartiesKycAmlApproved && !isEntityKycAmlApproved ? null : (
    <>
      <KYCAMLText />
      <DropAMLDocuments onDrop={onDrop} />{' '}
    </>
  );
};
export default KYCAML;

/*
  isAllPartiesKycAmlApproved && !isEntityKycAmlApproved ? null : (
    <>
      <KYCAMLText />
      <DropAMLDocuments AMLDropzone={AMLDropzone} />{' '}
    </>
*/
