import * as actions from '../actions/authActions';

const initialState = {
  loading: false,
  error: false,
  data: null,
  message: '',
};

const offeringReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_OFFERING_INPROGRESS':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_OFFERING_SUCCESS':
      return {
        ...state,
        data: action.data,
        loading: false,
        error: false,
        message: 'Success',
      };
    case 'FETCH_OFFERING_FAILURE':
      return {
        ...state,
        data: null,
        error: true,
        loading: false,
        message: action?.data?.message ? action?.data?.message : action?.data,
      };

    default:
      return state;
  }
};

export default offeringReducer;
