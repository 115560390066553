import React from 'react';
import styles from './OfferingSelectionModal.module.scss';
import { X } from 'react-feather';

const OfferingSelectionModal = ({
  isOpen,

  children,
  title,
  hasCloseIcon,
  handleCloseClick,
  isModalOutsideClose,
  center,
}) => {
  const handleOutsideClick = () => {
    if (isModalOutsideClose) handleCloseClick();
  };

  const handleClickInside = event => {
    event.stopPropagation();
  };

  const handleModalCloseClick = () => {
    handleCloseClick();
  };

  return (
    <div onClick={handleOutsideClick}>
      {isOpen && (
        <div
          className={styles.overlay}
          style={
            center
              ? {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              : {}
          }
        >
          <div className={styles.modalContainer} onClick={handleClickInside}>
            <div className={styles.modal}>
              {title || hasCloseIcon ? (
                <div className={styles.modalHeader}>
                  {title ? <p className={styles.paragraph}>{title}</p> : null}
                  {hasCloseIcon ? (
                    <X
                      size={19}
                      className={styles.icon}
                      onClick={handleModalCloseClick}
                    />
                  ) : null}
                </div>
              ) : null}
              <div className={styles.modalContent}>{children}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

OfferingSelectionModal.defaultProps = {
  classNames: '',

  hasCloseIcon: false,
  isModalOutsideClose: false,
  center: false,
};

export default OfferingSelectionModal;
