import React from 'react';
import styles from './verification-document.module.scss';
import cs from 'classnames';
import { useMedia } from 'react-use';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const BoxablAmlMessage = () => {
  const isMaxWidth600px = useMedia('(max-width:600px)');
  const account = useSelector(({ investor }) =>
    get(investor, 'account.data', null)
  );

  return account?.kycAmlMessage && account?.kycAmlMessage.length > 0 ? (
    <div
      className={cs(styles.titleHeader, {
        'shadow-sm': isMaxWidth600px,
      })}
    >
      <div
        className={styles.titleHeaderTitleButton}
        style={{ display: 'block' }}
      >
        <div className="flex flex-col justify-end">
          <h1 className={cs(styles.title, 'mt-2 mb-2')}>
            AML Document Review Team Comments:
          </h1>
        </div>
        <div className={styles.notes}>
          Please read our notes below, and
          <span
            onClick={() => {
              const element = document.getElementById('amlDocumentTitleText');
              element.scrollIntoView();
            }}
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {' '}
            click here{' '}
          </span>
          to drop your aml documents
          <br />
          <br />
          <div style={{ color: 'orange', fontWeight: '600' }}>AML Notes :</div>
          <div
            style={{
              marginTop: '20px',
              marginBottom: '30px',
              color: '#ffa400',
              fontWeight: '600',
            }}
          >
            {' '}
            "{account?.kycAmlMessage}"
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default BoxablAmlMessage;
