const initialState = {
  links: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },
  linkEntity: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },

  parties: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },

  account: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },

  entity: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },

  fundMovement: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },

  paymentStatus: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },

  partyKycAml: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },

  entityKycAml: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },
  uploadDocuments: {
    loading: false,
    error: false,
    message: '',
    data: {},
  },

  uploadDocumentsLoader: {
    loading: false,
    error: false,
    message: '',
    data: {},
  },

  partyEntityLoader: {
    loading: false,
    error: false,
  },
  magicLinkLoader: {
    loading: false,
    error: false,
  },
};

const investorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ON_PARTY_LOADER':
      return {
        ...state,
        account: {
          ...state.account,
          data: state.account.data
            ? // does loader investor id  === state accountId
              action.data.investorId === state.account.data.accountId
              ? //modify
                {
                  ...state.account.data,
                  isUploadLoading: !action.data.uploaded,
                  isUploadIcon: false,
                  isUploadKYCAML:
                    !action.data.uploaded === false ? true : false,
                }
              : // return orginal
                { ...state.account.data }
            : state.account.data,
        },
        entity: {
          ...state.entity,
          data: state.entity.data
            ? // does loader investor id  === state entityId
              action.data.investorId === state.entity.data.partyId
              ? //modify
                {
                  ...state.entity.data,
                  isUploadLoading: !action.data.uploaded,
                  isUploadIcon: false,
                  isUploadKYCAML:
                    !action.data.uploaded === false ? true : false,
                }
              : // return orginal
                { ...state.entity.data }
            : state.entity.data,
        },
        parties: {
          ...state.parties,
          data:
            state.parties.data && state.parties.data.length
              ? //modify
                state.parties.data.map(e => {
                  //if it matches then set the loader
                  if (e.partyId === action.data.investorId) {
                    return {
                      ...e,
                      isUploadLoading: !action.data.uploaded,
                      isUploadIcon: false,
                      isUploadKYCAML:
                        !action.data.uploaded === false ? true : false,
                    };
                  } else {
                    return {
                      ...e,
                    };
                  }
                })
              : // return original
                state.parties.data,
        },
      };

    case 'CLEAR_IS_UPLOAD_LOADER':
      return {
        ...state,
        account: {
          ...state.account,
          data: state.account.data
            ? {
                ...state.account.data,
                isUploadLoading: false,
                isUploadIcon: true,
              }
            : state.account.data,
        },
        parties: {
          ...state.parties,
          data: state.parties.data
            ? state.parties.data.map((e, i) => {
                return { ...e, isUploadLoading: false, isUploadIcon: true };
              })
            : null,
        },
        entity: {
          ...state.entity,
          data: state.entity.data
            ? {
                ...state.entity.data,
                isUploadLoading: false,
                isUploadIcon: true,
              }
            : null,
        },
      };

    //LINK
    case 'CREATE_LINK_INPROGRESS':
      return {
        ...state,
        links: {
          ...state.links,
          loading: true,
        },
      };
    case 'CREATE_LINK_SUCCESS':
      return {
        ...state,
        links: {
          ...state.links,
          error: false,
          loading: false,
          data: action.data,
        },
      };
    case 'CREATE_LINK_FAILURE':
      return {
        ...state,
        links: {
          ...state.links,
          error: true,
          loading: false,
        },
      };
    // LINK ENTITY
    case 'CREATE_LINK_ENTITY_LINK_INPROGRESS':
      return {
        ...state,
        linkEntity: {
          ...state.linkEntity,
          loading: true,
        },
      };
    case 'CREATE_LINK_ENTITY_SUCCESS':
      return {
        ...state,
        linkEntity: {
          ...state.linkEntity,
          error: false,
          loading: false,
          data: action.data,
        },
      };
    case 'CREATE_LINK_ENTITY_FAILURE':
      return {
        ...state,
        linkEntity: {
          ...state.linkEntity,
          error: true,
          loading: false,
        },
      };

    //PARTY
    case 'CREATE_PARTY_INPROGRESS':
      return {
        ...state,
        parties: {
          ...state.parties,
          loading: true,
        },
      };
    case 'CREATE_PARTY_SUCCESS':
      return {
        ...state,
        parties: {
          ...state.parties,
          error: false,
          loading: false,
          data: action.data,
        },
      };
    case 'CREATE_PARTY_FAILURE':
      return {
        ...state,
        parties: {
          ...state.parties,
          error: true,
          loading: false,
        },
      };

    //ACCOUNT
    case 'CREATE_ACCOUNT_INPROGRESS':
      return {
        ...state,
        account: {
          ...state.account,
          loading: true,
        },
      };
    case 'CREATE_ACCOUNT_SUCCESS':
      return {
        ...state,
        account: {
          ...state.account,
          error: false,
          loading: false,
          data: action.data,
        },
      };
    case 'CREATE_ACCOUNT_FAILURE':
      return {
        ...state,
        account: {
          ...state.account,
          error: true,
          loading: false,
        },
      };

    //ENTITY
    case 'CREATE_ENTITY_INPROGRESS':
      return {
        ...state,
        entity: {
          ...state.entity,
          loading: true,
        },
      };
    case 'CREATE_ENTITY_SUCCESS':
      return {
        ...state,
        entity: {
          ...state.entity,
          error: false,
          loading: false,
          data: action.data,
        },
      };
    case 'CREATE_ENTITY_FAILURE':
      return {
        ...state,
        entity: {
          ...state.entity,
          error: true,
          loading: false,
        },
      };

    //FUND MOVEMENT
    case 'CREATE_FUNDMOVEMENT_INPROGRESS':
      return {
        ...state,
        fundMovement: {
          ...state.fundMovement,
          loading: true,
        },
      };

    case 'CREATE_FUNDMOVEMENT_SUCCESS':
      return {
        ...state,
        fundMovement: {
          ...state.fundMovement,
          error: false,
          loading: false,
          data: action.data,
        },
      };
    case 'CREATE_FUNDMOVEMENT_FAILURE':
      return {
        ...state,
        fundMovement: {
          ...state.fundMovement,
          error: true,
          loading: false,
        },
      };

    //PAYMENT STATUS CARD
    case 'GET_PAYMENT_STATUS_INPROGRESS':
      return {
        ...state,
        paymentStatus: {
          ...state.paymentStatus,
          loading: true,
        },
      };

    case 'GET_PAYMENT_STATUS_SUCCESS':
      return {
        ...state,
        paymentStatus: {
          ...state.paymentStatus,
          error: false,
          loading: false,
          data: action.data,
        },
      };
    case 'GET_PAYMENT_STATUS_FAILURE':
      return {
        ...state,
        paymentStatus: {
          ...state.paymentStatus,
          error: true,
          loading: false,
        },
      };

    // PARTY KYC AML BASIC
    case 'ACCREDITED_EMAIL_INPROGRESS':
      return {
        ...state,
        partyKycAml: {
          ...state.partyKycAml,
          loading: true,
        },
      };

    case 'ACCREDITED_EMAIL_SUCCESS':
      return {
        ...state,
        partyKycAml: {
          ...state.partyKycAml,
          error: false,
          loading: false,
          data: action.data,
        },
      };
    case 'ACCREDITED_EMAIL_FAILURE':
      return {
        ...state,
        partyKycAml: {
          ...state.partyKycAml,
          error: true,
          loading: false,
        },
      };
    // ENTITY REQUEST KYC AML
    case 'CREATE_ENTITY_KYC_AML_INPROGRESS':
      return {
        ...state,
        entityKycAml: {
          ...state.entityKycAml,
          loading: true,
        },
      };

    case 'CREATE_ENTITY_KYC_AML_SUCCESS':
      return {
        ...state,
        entityKycAml: {
          ...state.entityKycAml,
          error: false,
          loading: false,
          data: action.data,
        },
      };
    case 'CREATE_ENTITY_KYC_AML_FAILURE':
      return {
        ...state,
        entityKycAml: {
          ...state.entityKycAml,
          error: true,
          loading: false,
        },
      };
    // UPLOAD DOCUMENTS LOADER
    case 'UPLOAD_DOCUMENTS_LOADER':
      return {
        ...state,
        uploadDocumentsLoader: {
          ...state.uploadDocumentsLoader,
          data: {
            ...state.uploadDocumentsLoader.data,
            [action.data.id]: action.data.data,
          },
        },
      };

    //UPLOAD DOCUMENTS
    case 'UPLOAD_DOCUMENTS_INPROGRESS':
      return {
        ...state,
        uploadDocuments: {
          ...state.uploadDocuments,
          loading: true,
        },
      };
    case 'UPLOAD_DOCUMENTS_SUCCESS':
      return {
        ...state,
        uploadDocuments: {
          ...state.uploadDocuments,
          data: {
            ...state.uploadDocuments.data,
            [action.data.uuid]: action.data.data,
          },
          loading: false,
        },
      };
    case 'UPLOAD_DOCUMENTS_FAILURE':
      return {
        ...state,
        uploadDocuments: {
          ...state.uploadDocuments,
          data: action.data,
          error: true,
          loading: false,
        },
      };

    case 'MAGIC_LINK_INPROGRESS':
      return {
        ...state,
        magicLinkLoader: {
          ...state.magicLinkLoader,
          error: false,
          loading: true,
        },
      };

    case 'MAGIC_LINK_SUCCESS':
      return {
        ...state,
        magicLinkLoader: {
          ...state.magicLinkLoader,
          error: false,
          loading: false,
        },
      };
    case 'MAGIC_LINK_FAILURE':
      return {
        ...state,
        magicLinkLoader: {
          ...state.magicLinkLoader,
          error: true,
          loading: false,
        },
        parties: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },

        account: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },

        entity: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },

        fundMovement: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },
      };

    case 'GET_PARTY_ENTITY_INPROGRESS':
      return {
        ...state,
        partyEntityLoader: {
          ...state.partyEntityLoader,
          error: false,
          loading: true,
        },
      };

    case 'GET_PARTY_ENTITY_SUCCESS':
      return {
        ...state,
        partyEntityLoader: {
          ...state.partyEntityLoader,
          error: false,
          loading: false,
        },
      };
    case 'GET_PARTY_ENTITY_FAILURE':
      return {
        ...state,
        partyEntityLoader: {
          ...state.partyEntityLoader,
          error: true,
          loading: false,
        },
      };

    case 'RESET':
      return {
        ...state,
        links: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },
        linkEntity: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },

        parties: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },

        account: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },

        entity: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },

        fundMovement: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },

        partyKycAml: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },

        entityKycAml: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },
        uploadDocuments: {
          loading: false,
          error: false,
          message: '',
          data: {},
        },

        uploadDocumentsLoader: {
          loading: false,
          error: false,
          message: '',
          data: {},
        },
        partyEntityLoader: {
          loading: false,
          error: false,
        },
        paymentStatus: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },
      };

    default:
      return state;
  }
};

export default investorReducer;
