import axios from 'axios';

//POST Individual
export const postCreateIndividual = async partyAccountAndLink => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/north-capital/individual`,
    partyAccountAndLink
  );
};

//POST ENTITY
export const postCreateEntity = async entity => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/north-capital/entity`,
    entity
  );
};

//GET OFFERING
export const getOffering = async offeringName => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/offerings/${offeringName}`
  );
};

//POST CREDIT CARD
export const postCreditCard = async tradeAndCardDetails => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/fund-movement/credit-card`,
    tradeAndCardDetails
  );
};

//POST ACH
export const postAch = async tradeAndAchDetails => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/fund-movement/ach`,
    tradeAndAchDetails
  );
};

//POST WIRE
export const postWire = async trade => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/fund-movement/wire`,
    trade
  );
};

//POST CHECK
export const postCheck = async trade => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/fund-movement/check`,
    trade
  );
};

//GET IP ADDRESS
export const getIpAddress = async () => {
  const res = await axios.get('https://geolocation-db.com/json/');
  return res.data.IPv4;
};

//POST BULK BASIC KYC/AML
export const postAccreditedEmail = async data => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/kyc/accredited-email`,
    data
  );
};

//POST JOINT
export const postJoint = async individualAndJoint => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/north-capital/joint`,
    individualAndJoint
  );
};

//POST UPLOAD DOCUMENT TO INDIVIDUAL ACCOUNT OR ACCREDIATION  ACCOUNT
export const postUploadDocument = async (data, uploadCallback) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/document/upload`,
    data,
    { onUploadProgress: uploadCallback }
  );
};

//POST GET PARTIES BY NANO ID
export const postGetPartiesByNanoId = async data => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/party/getPartiesByNanoId`,
    data
  );
};

//POST GET CARD PAYMENT STATUS
export const postGetCCFundMoveHistory = async data => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/fund-movement/get-cc-fund-move-history`,
    data
  );
};

//POST GET INVESTOR BY MAGICLINK
export const postgetInvestorByMagicLink = async data => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/magic-link/getInvestor`,
    data
  );
};

export const postUpdateCardPaymentFailure = async data => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/fund-movement/update-card-payment-and-fund-move`,
    data
  );
};

export const postUpdateACHPaymentFailure = async data => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/fund-movement/update-ach-payment-and-fund-move`,
    data
  );
};

export default {
  postJoint,
  postCheck,
  postWire,
  postAch,
  postUploadDocument,
  getOffering,
  postCreateIndividual,
  postCreateEntity,
  getIpAddress,
  postGetPartiesByNanoId,
  postGetCCFundMoveHistory,
  postgetInvestorByMagicLink,
  postUpdateCardPaymentFailure,
};
