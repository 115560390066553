import React from 'react';
import styles from './Prospect.module.scss';
import cs from 'classnames';
import Facebook from '../../assets/images/socialMediaImages/facebook.png';
import Instagram from '../../assets/images/socialMediaImages/instagram.png';
import Twitter from '../../assets/images/socialMediaImages/twitter.png';
import Telegram from '../../assets/images/socialMediaImages/telegram.png';
import Pinterest from '../../assets/images/socialMediaImages/pinterest.png';
import Youtube from '../../assets/images/socialMediaImages/youtube.png';
import TikTok from '../../assets/images/socialMediaImages/tiktok.png';
import LinkedIn from '../../assets/images/socialMediaImages/linkedin.png';

const socialLinks = [
  {
    name: 'Black Facebook Icon',
    icon: Facebook,
    url: 'https://www.facebook.com/boxabl/',
  },
  {
    name: 'Black Instagram Icon',
    icon: Instagram,
    url: 'https://www.instagram.com/boxabl/',
  },
  {
    name: 'Black Twitter Icon',
    icon: Twitter,
    url: 'https://www.twitter.com/boxabl/',
  },
  {
    name: 'Black LinkedIn Icon',
    icon: LinkedIn,
    url: 'https://www.linkedin.com/company/boxabl',
  },
  {
    name: 'Black Telegram Icon',
    icon: Telegram,
    url: 'https://t.me/boxabl',
  },
  {
    name: 'Black TikTok Icon',
    icon: TikTok,
    url: 'https://www.tiktok.com/@boxabl',
  },
  {
    name: 'Black Pinterest Icon',
    icon: Pinterest,
    url: 'https://www.pinterest.ca/boxabl',
  },
  {
    name: 'Black YouTube Icon',
    icon: Youtube,
    url: 'https://www.youtube.com/channel/UCsH515PWKkHVkJKBs_geE9g',
  },
];

const Prospect = () => {
  return (
    <div className={cs(styles.successContainer)}>
      <div className={styles.backButton__desktopStatus}>Success!</div>
      <div className="mt-2">
        <p className={styles.text}>
          Thank you for submitting your indication of interest to be a potential
          investor! As soon as our investment offering goes live you will
          receive instructions to finalize your investment. If you have any
          questions, please reach out us at&nbsp;
          <a
            href="mailto:invest@boxabl.com"
            className="text-brandorange underline"
          >
            invest@boxabl.com
          </a>
        </p>
        <p className={cs(styles.text, 'py-1')}>Sincerely</p>
        <p className={cs(styles.text, styles.autograph)}>Galiano Tiramani</p>
      </div>
      <div className="mt-2">
        <p className={styles.text}>
          In the meantime please checkout our social links.
        </p>

        <ul className={styles.socialLinkWrapper}>
          {socialLinks.map((obj, index) => (
            <li key={index} className={styles.socialLink}>
              <a href={obj.url} target="_blank" rel="noreferrer noopener">
                <img src={obj.icon} alt={obj.name} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Prospect;
