import React from 'react';
import cs from 'classnames';
import styles from './input.module.scss';

const Input = props => {
  const {
    id,
    label,
    wrapperClassName,
    labelClassName,
    className,
    value,
    name,
    error,
    type,
    leftIcon,
    rightIcon,
    register,
    autoComplete,
    ...rest
  } = props;

  return (
    <div
      className={cs(styles.root, wrapperClassName, {
        ['flex flex-row-reverse items-center']: type === 'checkbox',
      })}
    >
      {label ? (
        <label
          htmlFor={id}
          className={cs(`block text-brandblue`, labelClassName, {
            'mb-2': type !== 'checkbox',
            'mb-0 ml-2': type === 'checkbox',
          })}
        >
          {label}
        </label>
      ) : null}
      <div
        className={cs('relative', { [styles.checkbox]: type === 'checkbox' })}
      >
        <input
          data-testid={name}
          autoComplete={autoComplete}
          {...(register ? { ...register(name) } : {})}
          type={type}
          className={cs(
            `border focus:border-brandorange focus:outline-none rounded pt-2 w-full p-2`,
            { 'border-brandred': error },
            { 'pl-12': !!leftIcon },
            { 'pr-12': !!rightIcon },
            { hidden: type === 'checkbox' },
            className
          )}
          {...rest}
        />
        {type === 'checkbox' ? <span className={styles.toggle} /> : null}
        {leftIcon ? <span className={styles.leftIcon}>{leftIcon}</span> : null}
        {rightIcon ? (
          <span className={styles.rightIcon}>{rightIcon}</span>
        ) : null}
      </div>
      {error ? (
        <span
          className="text-brandred -mt-2 text-sm"
          data-testid={'error.' + name}
        >
          {error}
        </span>
      ) : null}
    </div>
  );
};

Input.defaultProps = {
  id: '',
  label: '',
  wrapperClassName: '',
  labelClassName: '',
  className: '',
  value: '',
  name: '',
  type: '',
  error: '',
  leftIcon: null,
  rightIcon: null,
};

export default Input;
