// Importing all the dependencies
import React from 'react';
import cs from 'classnames';
import styles from './sidebar.module.scss';
import BoxablLogo from '../../assets/images/boxabl_logo';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

// SideBar Component
const SideBar = () => {
  const offeringName = useSelector(state => {
    return get(state, 'offering.data.issueName', null);
  });

  const offering = useSelector(state => {
    return get(state, 'offering.data', null);
  });

  const stepData = [
    {
      title: 'Investor Information',
      path: `/${offeringName}/investor-information`,
      routes: [`/${offeringName}/investor-information`],
    },
    {
      title: 'Investment',
      path: `/${offeringName}/payment`,
      routes: [`/${offeringName}/payment`],
    },
    {
      title: offering?.payment_enabled
        ? 'Finalize Investment'
        : 'Finalize Commitment',
      path: `/${offeringName}/verification-documents`,
      routes: [`/${offeringName}/verification-documents`],
    },
  ];

  let location = useLocation();
  const foundRouteIndex = stepData.findIndex(
    s => s.routes.findIndex(r => r === location.pathname) > -1
  );

  return (
    <div className={styles.sideBar}>
      <div className="flex flex-col items-start">
        <div className={styles.logo_container}>
          <BoxablLogo />
        </div>
        <div>
          {stepData.map((step, index) => (
            <div className={styles.stepper} key={index}>
              <div
                className={cs(styles.circle, {
                  [styles.circle_last]: stepData.length === index + 1,
                  [styles.circle_active]: foundRouteIndex >= index,
                })}
              >
                <div
                  className={cs(styles.circle_second, {
                    [styles.circle_second_active]: foundRouteIndex >= index,
                  })}
                >
                  {' '}
                </div>
              </div>
              <div className={styles.stepper_text_title}>
                <p
                  className={cs({
                    [styles.stepper_paragraph_active]:
                      foundRouteIndex === index,
                  })}
                >
                  STEP {index + 1}
                </p>
                <h3
                  className={cs({
                    [styles.stepper_title_active]: foundRouteIndex === index,
                    'cursor-pointer': step.isClickable,
                  })}
                  {...(step.onClick ? { onClick: step.onClick } : {})}
                >
                  {step.title}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
