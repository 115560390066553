import React from 'react';
import { CheckCircle, Circle } from 'react-feather';

import { useMedia } from 'react-use';
import styles from './PaymentCard.module.scss';
export const PaymentCard = ({
  onClick,

  title,
  card_name,
  name,
  children,
  paragraph,
  chosen_card,
}) => {
  return (
    <div
      className={`${styles.paymentCard} 
        'p-5' bg-white flex p-5 justify-center flex-col  shadow-md`}
      style={
        `${chosen_card}` === `${card_name}`
          ? { border: '1px solid #ffa400' }
          : { border: '1px solid transparent' }
      }
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <div className="flex-col">
          <h3 className={styles.title}>{title}</h3>
          {paragraph && <p className={styles.description}>{paragraph}</p>}
        </div>
        {children}
        {card_name === chosen_card ? (
          <CheckCircle className={styles.iconChecked} size={30} />
        ) : (
          <Circle className={styles.iconUnchecked} size={30} />
        )}
      </div>
    </div>
  );
};

/*

*/

export default PaymentCard;
