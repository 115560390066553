import * as Yup from 'yup';
import { countriesOptions } from 'helpers/country';
export const getPersonSchema = hasPaymentEnabled =>
  Yup.object({
    firstName: Yup.string().required('First Name is Required'),
    lastName: Yup.string().required('Last Name is Required'),
    phone: Yup.string().required('Phone is Required'),
    email: Yup.string()
      .required('Email is required')
      .email('Please enter valid Email'),
    payment_enabled: Yup.boolean(),
    ...(hasPaymentEnabled
      ? {
          tax_id_number: Yup.string().when('country.value', {
            is: value => value && value === 'US',
            then: Yup.string()
              .required('Tax ID is Required')
              .test('is-digits', v => {
                //replace all special character and spacing
                const tax_id_number = v.replace(/[^A-Z0-9]+/gi, '');
                if (tax_id_number.length !== 9) {
                  return false;
                } else {
                  return true;
                }
              }),
            otherwise: Yup.string(),
          }),
        }
      : {}),
    birth_date: Yup.object().test('birthdate', 'required', ({ value }) => {
      if (value === false) {
        return false;
      } else {
        return true;
      }
    }),
    birth_month: Yup.object().test('birthmonth', 'required', ({ value }) => {
      if (value === false) {
        return false;
      } else {
        return true;
      }
    }),
    birth_year: Yup.object().test('birthmonth', 'required', ({ value }) => {
      if (value === false) {
        return false;
      } else {
        return true;
      }
    }),
    net_worth: Yup.string().when('accredited', {
      is: val => val === false,
      then: Yup.string().when('annual_income', {
        is: val => val < 200000,
        then: Yup.string().when('net_worth', {
          is: val => val < 1000000,
          then: Yup.string().test(
            'minValue',
            'Required $1,000,000+ Net Worth OR $200,000+ annual income.',
            val => val >= 1000000
          ),
        }),
      }),
    }),
    annual_income: Yup.string().when('accredited', {
      is: val => val === false,
      then: Yup.string().when('net_worth', {
        is: val => val < 1000000,
        then: Yup.string().when('annual_income', {
          is: val => val < 200000,
          then: Yup.string().test(
            'minValue',
            'Required $1,000,000+ Net Worth OR $200,000+ annual income.',
            val => val >= 200000
          ),
        }),
      }),
    }),

    street_address_1: Yup.string()
      .required('Street name is required')
      .test('PObox', 'PO Boxes cannot be used to make an investment', value => {
        if (
          //https://stackoverflow.com/questions/5680050/po-box-regular-expression-validation
          new RegExp('P(ost|ostal)?([ .]*(O|0)(ffice)?)?([ .]*Box)', 'i').test(
            value
          )
        ) {
          return false;
        } else {
          return true;
        }
      }),

    city: Yup.string()
      .required('City is required')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
    region: Yup.object()
      .required('State is required')
      .when('country', val => {
        // eslint-disable-next-line no-constant-condition
        if (val && val.value === 'US')
          return Yup.object()
            .nullable()
            .required('State is required')
            .test('region', 'required', ({ value }) => {
              if (value === false) {
                return false;
              } else {
                return true;
              }
            });
        else
          return Yup.string()
            .required('State is required')
            .matches(
              /^[aA-zZ\s]+$/,
              'Only alphabets are allowed for this field '
            );
      }),
    country: Yup.object()
      .nullable()
      .required('Country is required')
      .test('iscountry', 'Please enter a valid country', ({ value }) => {
        if (value === false) {
          return false;
        } else {
          return true;
        }
      }),
    postal_code: Yup.string().required('Postal Code is required'),
  }).test(
    'dateOfBirthOverEighteen',
    'Please provide the correct date of birth. You need to be 18+',
    value => {
      const {
        birth_date: { value: birth_date },
        birth_month: { value: birth_month },
        birth_year: { value: birth_year },
      } = value;

      const isOver18 = birthday => {
        // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
        var optimizedBirthday = birthday.replace(/-/g, '/');

        //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
        var myBirthday = new Date(optimizedBirthday);

        // set current day on 01:00:00 hours GMT+0100 (CET)
        var currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00';

        // calculate age comparing current date and borthday
        var myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);

        if (myAge < 18) {
          return false;
        } else {
          return true;
        }
      };

      if (birth_date && birth_month && birth_year) {
        const overEighteen = isOver18(
          `${birth_year ? birth_year : 0}-${birth_month ? birth_month : 0}-${
            birth_date ? birth_date : 0
          }`
        );

        if (!overEighteen) {
          return false;
        }
      }
      return true;
    }
  );
