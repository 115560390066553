import React from 'react';
import styles from './verification-document.module.scss';
import cs from 'classnames';
import { useMedia } from 'react-use';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const AccreditionDocumentTitle = () => {
  const isMaxWidth600px = useMedia('(max-width:600px)');
  const account = useSelector(({ investor }) =>
    get(investor, 'account.data', null)
  );

  return (
    <div
      className={cs(styles.titleHeader, {
        'shadow-sm': isMaxWidth600px,
      })}
    >
      <div className={styles.titleHeaderTitleButton}>
        <div className="flex flex-col justify-end">
          <h1 className={cs(styles.title, 'mt-2 mb-2')}>
            Accreditation Verification Documents
          </h1>
        </div>
      </div>
      {account?.aiNotes ? (
        <div className={styles.notes}>
          <div>
            Please upload your accredited documents from the notes specified by
            the document review team below.
          </div>
          <br />
          <div style={{ color: 'orange', fontWeight: '600' }}>
            Accreditation Notes :
          </div>
          <div
            style={{
              marginTop: '20px',
              marginBottom: '30px',
              color: '#ffa400',
              fontWeight: '600',
            }}
          >
            {' '}
            "{account?.aiNotes}"
          </div>
        </div>
      ) : (
        <div className={styles.notes}>
          Please upload documentation to verify that you are an accredited
          investor.
        </div>
      )}
    </div>
  );
};

export default AccreditionDocumentTitle;
