import React from 'react';
import Loading from '../../assets/images/Loading';

export default function PageChange() {
  return (
    <div className="z-150 fixed top-0 right-0 left-0 bottom-0 w-screen h-screen flex items-center justify-center h-screen w-screen bg-brandblue">
      <div className="flex flex-col items-center">
        <svg
          width="364"
          height="56"
          viewBox="0 0 364 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M34.088 26.8583C36.3794 24.017 37.7624 20.3371 37.7624 16.3174C37.7624 7.31645 30.8058 0 22.2322 0C22.1772 0 22.1221 0 22.0671 0H0V55.9723H24.3309C24.3722 55.9723 24.4066 55.9723 24.4479 55.9723C33.0215 55.9723 39.985 48.6558 39.985 39.6549C39.985 34.4712 37.6798 29.8514 34.088 26.8583ZM8.86261 9.34076H22.1428H22.1565C25.8447 9.34076 28.8379 12.4784 28.8379 16.3391C28.8379 20.1998 25.8447 23.3374 22.1565 23.3374H22.1428H8.86261V9.34076ZM24.4341 46.6821H24.4203L8.89013 46.6749V32.6854L24.4203 32.6927H24.4341C28.1223 32.6927 31.1155 35.8304 31.1155 39.691C31.1155 43.5444 28.1154 46.6821 24.4341 46.6821Z"
            fill="#fff"
          />
          <path
            d="M300.498 26.8864C302.789 24.0451 304.172 20.3652 304.172 16.3455C304.172 7.33729 297.209 0.0208435 288.635 0.0208435C288.58 0.0208435 288.525 0.0208435 288.47 0.0208435H266.403V55.9931H290.734C290.775 55.9931 290.81 55.9931 290.851 55.9931C299.425 55.9931 306.388 48.6767 306.388 39.6757C306.395 34.4993 304.09 29.8795 300.498 26.8864ZM275.272 9.36884H288.553H288.566C292.255 9.36884 295.248 12.5065 295.248 16.3672C295.248 20.2278 292.255 23.3655 288.566 23.3655H288.553H275.272V9.36884ZM290.844 46.7102H290.83L275.3 46.703V32.7063L290.83 32.7135H290.844C294.532 32.7135 297.525 35.8512 297.525 39.7119C297.525 43.5725 294.525 46.7102 290.844 46.7102Z"
            fill="#fff"
          />
          <path
            d="M84.3806 0.0578461C69.6968 0.0578461 57.7722 12.5869 57.7722 28.0151C57.7722 43.4432 69.6968 55.9723 84.3806 55.9723C99.0645 55.9723 110.989 43.4432 110.989 28.0151C110.989 12.5797 99.0645 0.0578461 84.3806 0.0578461ZM84.3806 46.646C74.5891 46.646 66.6416 38.2957 66.6416 28.0078C66.6416 17.72 74.5891 9.36969 84.3806 9.36969C94.1721 9.36969 102.12 17.72 102.12 28.0078C102.12 38.2957 94.1721 46.646 84.3806 46.646Z"
            fill="#fff"
          />
          <path
            d="M149.832 19.6575L162.437 0.00722969L173.137 0.0144594L155.185 28.0006L173.137 55.9795H162.424L149.832 36.3437L137.233 55.9795H126.519L144.471 28.0006L126.526 0.00722969L137.226 0L149.832 19.6575Z"
            fill="#fff"
          />
          <path
            d="M190.883 55.9868V28.0078L217.519 0.0072403L244.155 28.0078V55.9868H235.286V31.854L217.519 13.2014L199.76 31.854V55.9868H190.883Z"
            fill="#fff"
          />
          <path
            d="M221.958 31.854V41.1803H230.834V31.854H221.958Z"
            fill="#fff"
          />
          <path
            d="M326.286 0.0578461V56.0084H364.014V46.6388H335.162V0.0578461H326.286Z"
            fill="#fff"
          />
        </svg>
        <div className="mt-6">
          <Loading />
        </div>
      </div>
    </div>
  );
}
