// Importing everything as sideBarAction
import { omit } from 'lodash';
import * as formBodyActions from '../actions/formBodyActions';

// InitialState
const initialState = {
  payment_details: null,
  price_value: null,
  investor_info: null,
  personal_info: null,
  investment_info: null,
  address_info: null,
  investment_criteria: null,

  card_error: false,
  payment_failure_error: false,
  payment_failure_message: '',
  offering: {
    query: '',
    slug: '',
    loading: false,
    error: false,
    message: '',
    data: {},
  },

  investor: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },
  entity: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },
  investment: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },
  user: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },
  agreement: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },
  entities: {
    loading: false,
    error: false,
    message: '',
    data: {},
  },
  documentUpload: {
    loading: false,
    error: false,
    message: '',
    data: {},
  },
  prospect: {
    loading: false,
    error: false,
    message: '',
    data: null,
  },
  documentLoader: {
    data: {},
  },
  tokenUser: null,
  investorProspect: {
    loading: false,
    error: false,
    message: '',
    data: {},
  },
  referral: null,
};

// Reducer
const formBodyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VALIDATE_TOKEN_SUCCESS':
      return {
        ...state,
        tokenUser: action.data,
      };
    case 'VALIDATE_TOKEN_FAILURE':
      return {
        ...state,
        tokenUser: null,
      };
    // Payment Choice
    case 'PAYMENT_DETAILS':
      return {
        ...state,
        payment_details: action.data,
      };

    // Set Card error if the payment (Credit Card) does not work
    case 'SET_CARD_ERROR':
      return {
        ...state,
        card_error: true,
      };

    // Get select offering
    case 'GET_OFFERING_QUERY':
      return {
        ...state,
        offering: { ...state.offering, query: action.value },
      };

    // Get select offering slug
    case 'SET_OFFERING_SLUG':
      return {
        ...state,
        offering: { ...state.offering, slug: action.value },
      };

    // Price value
    case 'PRICE_VALUE':
      return {
        ...state,
        price_value: action.price,
      };

    // Invesetor Information
    case 'SET_INVESTOR_INFO':
      return {
        ...state,
        investor_info: action.data,
      };

    // Personal Information
    case 'SET_PERSONAL_INFO':
      return {
        ...state,
        personal_info: action.data,
      };

    // Investment Information
    case 'SET_INVESTMENT_INFO':
      return {
        ...state,
        investment_info: action.data,
      };

    // Address Information
    case 'SET_ADDRESS_INFO':
      return {
        ...state,
        address_info: action.data,
      };

    // Investment Information
    case 'SET_INVESTMENT_CRITERIA_INFO':
      return {
        ...state,
        investment_criteria: action.data,
      };
    case 'FETCH_INVESTOR_INPROGRESS':
    case 'CREATE_INVESTOR_INPROGRESS':
      return {
        ...state,
        investor: {
          ...state.investor,
          loading: true,
        },
      };
    case 'FETCH_INVESTOR_SUCCESS':
    case 'CREATE_INVESTOR_SUCCESS':
      return {
        ...state,
        investor: {
          ...state.investor,
          data: action.data,
          loading: false,
        },
      };
    case 'FETCH_INVESTOR_FAILURE':
    case 'CREATE_INVESTOR_FAILURE':
      return {
        ...state,
        investor: {
          ...state.investor,
          data: action.data,
          error: true,
          loading: false,
        },
      };
    case 'CREATE_INVESTMENT_INPROGRESS':
      return {
        ...state,
        investment: {
          ...state.investment,
          loading: true,
        },
      };
    case 'CREATE_INVESTMENT_SUCCESS':
      return {
        ...state,
        investment: {
          ...state.investment,
          data: action.data,
          loading: false,
        },
      };
    case 'CREATE_INVESTMENT_FAILURE':
      return {
        ...state,
        investment: {
          ...state.investment,
          data: action.data,
          error: true,
          loading: false,
        },
      };
    case 'CREATE_AGREEMENT_INPROGRESS':
      return {
        ...state,
        agreement: {
          ...state.agreement,
          loading: true,
        },
      };
    case 'CREATE_AGREEMENT_SUCCESS':
      return {
        ...state,
        agreement: {
          ...state.agreement,
          data: action.data,
          loading: false,
        },
      };
    case 'CREATE_AGREEMENT_FAILURE':
      return {
        ...state,
        agreement: {
          ...state.agreement,
          data: action.data,
          error: true,
          loading: false,
        },
      };
    case 'GET_PREVIOUS_ENTITIES_INPROGRESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          loading: true,
        },
      };
    case 'GET_PREVIOUS_ENTITIES_SUCCESS':
      return {
        ...state,
        entities: {
          ...state.entities,
          data: action.data,
          loading: false,
        },
      };
    case 'GET_PREVIOUS_ENTITIES_FAILURE':
      return {
        ...state,
        entities: {
          ...state.entities,
          data: action.data,
          error: true,
          loading: false,
        },
      };
    case 'FETCH_ENTITY_INPROGRESS':
      return {
        ...state,
        entity: {
          ...state.entity,
          loading: true,
        },
      };
    case 'FETCH_ENTITY_SUCCESS':
      return {
        ...state,
        entity: {
          ...state.entity,
          data: action.data,
          loading: false,
        },
      };
    case 'FETCH_USER_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          data: action.data,
          loading: false,
        },
      };
    case 'FETCH_ENTITY_FAILURE':
      return {
        ...state,
        entity: {
          ...state.entity,
          data: action.data,
          error: true,
          loading: false,
        },
      };

    case 'DELETE_DOCUMENT':
      return {
        ...state,
        documentUpload: {
          ...state.documentUpload,
          data: omit(state.documentUpload.data, action.uuid),
        },

        documentLoader: {
          ...state.documentLoader,
          data: omit(state.documentLoader.data, action.uuid),
        },
      };

    case 'UPDATE_DOCUMENT_LOADER':
      return {
        ...state,
        documentLoader: {
          ...state.documentLoader,
          data: {
            ...state.documentLoader.data,
            [action.data.id]: action.data.data,
          },
        },
      };
    case 'CREATE_PROSPECT_INPROGRESS':
    case 'UPDATE_PROSPECT_INPROGRESS':
      return {
        ...state,
        prospect: {
          ...state.prospect,
          loading: true,
        },
      };
    case 'UPDATE_PROSPECT_SUCCESS':
    case 'CREATE_PROSPECT_SUCCESS':
      return {
        ...state,
        prospect: {
          ...state.prospect,
          data: action.data,
          loading: false,
        },
      };
    case 'CREATE_PROSPECT_FAILURE':
    case 'UPDATE_PROSPECT_FAILURE':
      return {
        ...state,
        prospect: {
          ...state.prospect,
          data: action.data,
          error: true,
          loading: false,
        },
      };
    case 'UPDATE_TOKEN':
      return {
        ...state,
        tokenUser: action.data,
      };

    case 'VALIDATE_TOKEN_BY_PAYMENT_INPROGRESS':
      return {
        ...state,
        entity: {
          ...state.entity,
          loading: true,
        },
        investor: {
          ...state.investor,
          loading: true,
        },
        user: {
          ...state.user,
          loading: true,
        },
        investment: {
          ...state.investment,
          loading: true,
        },
        payment_details: null,
      };

    case 'VALIDATE_TOKEN_BY_PAYMENT_SUCCESS':
      return {
        ...state,
        payment_failure_error: false,
        payment_failure_message: '',
        entity: {
          ...state.entity,
          data: action.data?.entity,
          loading: false,
        },
        investor: {
          ...state.investor,
          data: action.data?.investor,
          loading: false,
        },
        user: {
          ...state.user,
          data: action.data?.user,
          loading: false,
        },
        investment: {
          ...state.investment,
          data: action.data?.investment,
          loading: false,
        },
      };

    case 'VALIDATE_TOKEN_BY_PAYMENT_FAILURE':
      return {
        ...state,
        payment_failure_error: true,
        payment_failure_message: action.data,
        entity: {
          ...state.entity,
          data: null,
          error: true,
          loading: false,
        },
        investor: {
          ...state.investor,
          data: null,
          error: true,
          loading: false,
        },
        user: {
          ...state.user,
          data: null,
          error: true,
          loading: false,
        },
        investment: {
          ...state.investment,
          data: null,
          error: true,
          loading: false,
        },
        payment_details: null,
      };

    case 'FETCH_PROSPECT_BY_INVESTOR_DETAILS_INPROGRESS':
      return {
        ...state,
        investorProspect: {
          ...state.investorProspect,
          loading: true,
        },
      };

    case 'FETCH_PROSPECT_BY_INVESTOR_DETAILS_SUCCESS':
      return {
        ...state,
        investorProspect: {
          ...state.investorProspect,
          data: action.data,
        },
      };
    case 'FETCH_PROSPECT_BY_INVESTOR_DETAILS_FAILURE':
      return {
        ...state,
        investorProspect: {
          ...state.investorProspect,
          data: null,
          error: true,
          loading: false,
        },
      };
    case 'SET_REFERRAL_DETAILS':
      return {
        ...state,
        referral: action.data,
      };
    case 'RESET_FORM_BODY_DETAILS':
      return {
        ...state,
        investorProspect: {
          loading: false,
          error: false,
          message: '',
          data: {},
        },
        investor: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },
        investment: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },
        entity: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },
        agreement: {
          loading: false,
          error: false,
          message: '',
          data: null,
        },
        documentUpload: {
          loading: false,
          error: false,
          message: '',
          data: {},
        },
      };

    default:
      return state;
  }
};

// Export formBodyReducer
export default formBodyReducer;
