import { v4 as uuidv4 } from 'uuid';
import { indentifyInLogRocket } from 'helpers/logrocket';
import {
  postGetCCFundMoveHistory,
  postCheck,
  postWire,
  postAch,
  postCreditCard,
  getOffering,
  postCreateIndividual,
  getIpAddress,
  postCreateEntity,
  postJoint,
  postAccreditedEmail,
  postUploadDocument,
  postGetPartiesByNanoId,
  postgetInvestorByMagicLink,
  postUpdateCardPaymentFailure,
  postUpdateACHPaymentFailure,
} from 'redux/api/investor.api';

import { showSnackBar } from './snackbar';
import { FETCH_PROSPECT_BY_INVESTOR_DETAILS_SUCCESS } from './formBodyActions';

// LINK
export const CREATE_LINK_INPROGRESS = 'CREATE_LINK_INPROGRESS';
export const CREATE_LINK_SUCCESS = 'CREATE_LINK_SUCCESS';
export const CREATE_LINK_FAILURE = 'CREATE_LINK_FAILURE';

// LINK ENTITY
export const CREATE_LINK_ENTITY_LINK_INPROGRESS =
  'CREATE_LINK_ENTITY_LINK_INPROGRESS';
export const CREATE_LINK_ENTITY_SUCCESS = 'CREATE_LINK_ENTITY_SUCCESS';
export const CREATE_LINK_ENTITY_FAILURE = 'CREATE_LINK_ENTITY_FAILURE';

// ENTITY
export const CREATE_ENTITY_INPROGRESS = 'CREATE_ENTITY_INPROGRESS ';
export const CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';
export const CREATE_ENTITY_FAILURE = 'CREATE_ENTITY_FAILURE';

// ACCOUNT
export const CREATE_ACCOUNT_INPROGRESS = 'CREATE_ACCOUNT_INPROGRESS ';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';

// PARTY
export const CREATE_PARTY_INPROGRESS = 'CREATE_PARTY_INPROGRESS';
export const CREATE_PARTY_SUCCESS = 'CREATE_PARTY_SUCCESS';
export const CREATE_PARTY_FAILURE = 'CREATE_PARTY_FAILURE';

// OFFERING
export const FETCH_OFFERING_INPROGRESS = 'FETCH_OFFERING_INPROGRESS';
export const FETCH_OFFERING_SUCCESS = 'FETCH_OFFERING_SUCCESS';
export const FETCH_OFFERING_FAILURE = 'FETCH_OFFERING_FAILURE';

// FUNDMOVEMENT
export const CREATE_FUNDMOVEMENT_INPROGRESS = 'CREATE_FUNDMOVEMENT_INPROGRESS';
export const CREATE_FUNDMOVEMENT_SUCCESS = 'CREATE_FUNDMOVEMENT_SUCCESS';
export const CREATE_FUNDMOVEMENT_FAILURE = 'CREATE_FUNDMOVEMENT_FAILURE';

// PARTY KYC AML
export const ACCREDITED_EMAIL_INPROGRESS = 'ACCREDITED_EMAIL_INPROGRESS';
export const ACCREDITED_EMAIL_SUCCESS = 'ACCREDITED_EMAIL_SUCCESS';
export const ACCREDITED_EMAIL_FAILURE = 'ACCREDITED_EMAIL_FAILURE';

// ENTITY KYC AML
export const CREATE_ENTITY_KYC_AML_INPROGRESS =
  'CREATE_ENTITY_KYC_AML_INPROGRESS';
export const CREATE_ENTITY_KYC_AML_SUCCESS = 'CREATE_ENTITY_KYC_AML_SUCCESS';
export const CREATE_ENTITY_KYC_AML_FAILURE = 'CREATE_ENTITY_KYC_AML_FAILURE';

// RESET
export const RESET = 'RESET';

// UPLOAD DOCUMENT
export const UPLOAD_DOCUMENTS_INPROGRESS = 'UPLOAD_DOCUMENTS_INPROGRESS';
export const UPLOAD_DOCUMENTS_SUCCESS = 'UPLOAD_DOCUMENTS_SUCCESS';
export const UPLOAD_DOCUMENTS_FAILURE = 'UPLOAD_DOCUMENTS_FAILURE';

//UPLOAD DOCUMENT LOADER
export const UPLOAD_DOCUMENTS_LOADER = 'UPLOAD_DOCUMENTS_LOADER';

//GET PARTY ENTITY
export const GET_PARTY_ENTITY_INPROGRESS = 'GET_PARTY_ENTITY_INPROGRESS';
export const GET_PARTY_ENTITY_SUCCESS = 'GET_PARTY_ENTITY_SUCCESS';
export const GET_PARTY_ENTITY_FAILURE = 'GET_PARTY_ENTITY_FAILURE';

//MAGIC LINK LOADING
export const MAGIC_LINK_INPROGRESS = 'MAGIC_LINK_INPROGRESS';
export const MAGIC_LINK_SUCCESS = 'MAGIC_LINK_SUCCESS';
export const MAGIC_LINK_FAILURE = 'MAGIC_LINK_FAILURE';

//GET PAYMENT STATUS
export const GET_PAYMENT_STATUS_INPROGRESS = 'GET_PAYMENT_STATUS_INPROGRESS';
export const GET_PAYMENT_STATUS_SUCCESS = 'GET_PAYMENT_STATUS_SUCCESS';
export const GET_PAYMENT_STATUS_FAILURE = 'GET_PAYMENT_STATUS_FAILURE';

// REENTER PAYMENT_FAILURE INFO
export const PAYMENT_FAILURE_INPROGRESS = 'PAYMENT_FAILURE_INPROGRESS';
export const PAYMENT_FAILURE_SUCCESS = 'PAYMENT_FAILURE_SUCCESS';
export const PAYMENT_FAILURE_FAILURE = 'PAYMENT_FAILURE_FAILURE';

export const CLEAR_IS_UPLOAD_LOADER = 'CLEAR_IS_UPLOAD_LOADER';
export const SET_ON_PARTY_LOADER = 'SET_ON_PARTY_LOADER';

//FETCH PROSPECT
export const FETCH_PROSPECT_INPROGRESS = 'FETCH_PROSPECT_INPROGRESS';
export const FETCH_PROSPECT_SUCCESS = 'FETCH_PROSPECT_SUCCESS';
export const FETCH_PROSPECT_FAILURE = 'FETCH_PROSPECT_FAILURE';
//ANAYLITICS

export const INVESTMENT_SUCCESS_ON_NEW_INVESTOR =
  'INVESTMENT_SUCCESS_ON_NEW_INVESTOR';

export const setUploadLoader = ({
  // partyId or entityId
  investorId,
  // progress in which is being in progressed
  uploaded,
}) => async dispatch => {
  return dispatch({
    type: SET_ON_PARTY_LOADER,
    data: {
      investorId,
      uploaded,
    },
  });
};

export const updatePayment = ({
  transactionTypes,
  paymentInformation,
  findTrade,
  trade,
  offeringId, // FOR ACH
  amount, //FOR ACH
}) => async dispatch => {
  dispatch({
    type: CREATE_FUNDMOVEMENT_INPROGRESS,
  });

  try {
    if (transactionTypes === 'credit_card') {
      const {
        data: { data },
      } = await postUpdateCardPaymentFailure({
        findTrade: findTrade,
        cardDetails: paymentInformation,
      });

      dispatch({
        type: CREATE_FUNDMOVEMENT_SUCCESS,
        data: { ...data, trade },
      });
      return data;
    }
    if (transactionTypes === 'ach') {
      const {
        data: { data },
      } = await postUpdateACHPaymentFailure({
        offeringId, // FOR ACH
        amount, //FOR ACH
        findTrade: findTrade,
        externalAccount: paymentInformation,
      });
      dispatch({
        type: CREATE_FUNDMOVEMENT_SUCCESS,
        data: { ...data, trade },
      });
      return data;
    }
    return;
  } catch (err) {
    dispatch({
      type: CREATE_FUNDMOVEMENT_FAILURE,
      data: {
        message: err?.response?.data?.message,
        fundMovement: err?.response?.data,
      },
    });
    showSnackBar(
      JSON.stringify(err?.response?.data?.errors) || 'Something went wrong',
      'error'
    );
    return null;
  }
};

export const getInvestorByMagiclink = magicData => async dispatch => {
  dispatch({
    type: MAGIC_LINK_INPROGRESS,
  });
  try {
    const {
      data: { data, err },
    } = await postgetInvestorByMagicLink(magicData);
    dispatch({
      type: MAGIC_LINK_SUCCESS,
    });
    if (magicData?.type !== 'prospect') {
      dispatch({
        type: CREATE_ACCOUNT_SUCCESS,
        data: data?.account,
      });

      dispatch({
        type: CREATE_ENTITY_SUCCESS,
        data: data?.entity,
      });

      dispatch({
        type: CREATE_PARTY_SUCCESS,
        data: data?.parties,
      });

      dispatch({
        type: CREATE_FUNDMOVEMENT_SUCCESS,
        data: { trade: data?.trade },
      });
    }

    if (magicData?.type === 'prospect') {
      showSnackBar('Please review your info and hit next to invest!', 'info');
      dispatch({
        type: FETCH_PROSPECT_SUCCESS,
        data: data,
      });
    }

    dispatch({
      type: CLEAR_IS_UPLOAD_LOADER,
    });

    return data;
  } catch (e) {
    showSnackBar(
      e?.response?.data?.message,
      magicData.type === 'prospect' ? 'info' : 'error'
    );
    dispatch({
      type: MAGIC_LINK_FAILURE,
      data: e?.response?.data?.message,
    });
  }
};

export const getPartiesByNanoId = body => async dispatch => {
  dispatch({
    type: GET_PARTY_ENTITY_INPROGRESS,
  });
  try {
    const {
      data: { data },
    } = await postGetPartiesByNanoId(body);

    dispatch({
      type: GET_PARTY_ENTITY_SUCCESS,
    });

    dispatch({
      type: CREATE_ENTITY_SUCCESS,
      data: data.entity,
    });

    dispatch({
      type: CREATE_PARTY_SUCCESS,
      data: data.parties,
    });

    return data;
  } catch (e) {
    showSnackBar(
      'Please Refresh the page. If your investment trade id does not match the email investment trade Id. Please contact invest@boxabl.com',
      'error'
    );
    dispatch({
      type: GET_PARTY_ENTITY_FAILURE,
      data: e?.response?.data?.message,
    });
    return null;
  }
};

export const getCCFundMoveHistory = nanoData => async dispatch => {
  dispatch({
    type: GET_PAYMENT_STATUS_INPROGRESS,
  });
  try {
    const { data } = await postGetCCFundMoveHistory(nanoData);
    dispatch({
      type: GET_PAYMENT_STATUS_SUCCESS,
      data: data,
    });

    return data;
  } catch (e) {
    dispatch({
      type: GET_PAYMENT_STATUS_FAILURE,
      data: e?.response?.data?.message,
    });
  }
};

// GET OFFERING
export const fetchOffering = offeringName => async dispatch => {
  dispatch({
    type: FETCH_OFFERING_INPROGRESS,
  });
  try {
    const { data } = await getOffering(offeringName);

    const offers = data.offerings
      ? data.offerings.filter(offer => !offer.is_deleted)
      : [];

    const minimumInvestment = Math.min(
      ...offers.map(o => +o.min_value || null)
    );

    if (data?.is_deleted) {
      return dispatch({
        type: FETCH_OFFERING_FAILURE,
        data: { ...data, message: 'Offering is not live' },
      });
    }

    return dispatch({
      type: FETCH_OFFERING_SUCCESS,
      data: {
        ...data,
        minimumInvestment,
      },
    });
  } catch (e) {
    dispatch({
      type: FETCH_OFFERING_FAILURE,
      data: e?.response?.data?.message,
    });
  }
};
// CREATE INDIVIDUAL
export const createIndividual = (
  partyAccountAndLink,
  is_joint
) => async dispatch => {
  const { account } = partyAccountAndLink;
  if (account) {
    indentifyInLogRocket(uuidv4(), {
      name: account?.accountRegistration,
      email: account?.email,
      phone: account?.phone,
      type: account?.investedAs,
      is: 'New User',
    });
  }

  dispatch({
    type: CREATE_LINK_INPROGRESS,
  });
  try {
    if (is_joint) {
      const {
        data: { data },
      } = await postJoint(partyAccountAndLink);

      const parties = data?.jointParties.map(e => e.party);
      const links = data?.jointParties.map(e => e.link);
      dispatch({
        type: CREATE_PARTY_SUCCESS,
        data: parties,
      });

      dispatch({
        type: CREATE_ACCOUNT_SUCCESS,
        data: data.account,
      });

      dispatch({
        type: CREATE_LINK_SUCCESS,
        data: links,
      });
      return data;
    }

    const {
      data: { data },
    } = await postCreateIndividual(partyAccountAndLink);

    dispatch({
      type: CREATE_PARTY_SUCCESS,
      data: [data.party],
    });

    dispatch({
      type: CREATE_ACCOUNT_SUCCESS,
      data: data.account,
    });

    dispatch({
      type: CREATE_LINK_SUCCESS,
      data: [data.link],
    });

    return data;
  } catch (err) {
    dispatch({
      type: CREATE_LINK_FAILURE,
      data: {
        message: err?.response?.data?.message,
        offering: err?.response?.data?.linkDetails,
      },
    });
    showSnackBar(
      err?.response?.data?.message || 'Something went wrong',
      'error'
    );
    return null;
  }
};

export const createEntity = ({
  account,
  entity,
  party,
  entityLink,
  link,
}) => async dispatch => {
  if (account) {
    indentifyInLogRocket(uuidv4(), {
      name: account?.accountRegistration,
      email: account?.email,
      phone: account?.phone,
      type: account?.investedAs,
      is: 'New User',
    });
  }

  dispatch({
    type: CREATE_LINK_INPROGRESS,
  });

  dispatch({
    type: CREATE_LINK_ENTITY_LINK_INPROGRESS,
  });

  let createdIpAddress = '111.111.111.111';

  try {
    createdIpAddress = await getIpAddress();
    if (createdIpAddress === 'Not found') {
      createdIpAddress = '111.111.111.111';
    }
  } catch (err) {
    createdIpAddress = '111.111.111.111';
  }

  try {
    const {
      data: { data },
    } = await postCreateEntity({
      entity: {
        ...entity,
        createdIpAddress: createdIpAddress,
      },
      account,
      party: {
        ...party,
        createdIpAddress: createdIpAddress,
      },
      entityLink,
      link,
    });

    dispatch({
      type: CREATE_ENTITY_SUCCESS,
      data: data.entity,
    });

    dispatch({
      type: CREATE_ACCOUNT_SUCCESS,
      data: data.account,
    });

    dispatch({
      type: CREATE_PARTY_SUCCESS,
      data: [data.party],
    });

    dispatch({
      type: CREATE_LINK_ENTITY_SUCCESS,
      data: data.entityLink,
    });

    dispatch({
      type: CREATE_LINK_SUCCESS,
      data: [data.link],
    });

    return data;
  } catch (err) {
    dispatch({
      type: CREATE_LINK_FAILURE,
      data: {
        message: err?.response?.data?.message,
        offering: err?.response?.data?.linkDetails,
      },
    });
    showSnackBar(
      err?.response?.data?.message || 'Something went wrong',
      'error'
    );
    return null;
  }
};

export const analyticsMovement = () => async dispatch => {
  dispatch({
    type: INVESTMENT_SUCCESS_ON_NEW_INVESTOR,
  });
};

export const fundMovement = ({
  transactionTypes,
  paymentInformation,
  trade,
}) => async dispatch => {
  dispatch({
    type: CREATE_FUNDMOVEMENT_INPROGRESS,
  });

  try {
    if (transactionTypes === 'credit_card') {
      const {
        data: { data },
      } = await postCreditCard({
        trade: trade,
        cardDetails: paymentInformation,
      });
      dispatch({
        type: CREATE_FUNDMOVEMENT_SUCCESS,
        data: data,
      });

      return data;
    }
    if (transactionTypes === 'ach') {
      const {
        data: { data },
      } = await postAch({
        trade: trade,
        externalAccount: paymentInformation,
      });
      dispatch({
        type: CREATE_FUNDMOVEMENT_SUCCESS,
        data: data,
      });

      return data;
    }

    if (transactionTypes === 'wire') {
      const {
        data: { data },
      } = await postWire({
        ...trade,
      });
      dispatch({
        type: CREATE_FUNDMOVEMENT_SUCCESS,
        data: data,
      });
      return data;
    }

    if (transactionTypes === 'check') {
      const {
        data: { data },
      } = await postCheck({
        ...trade,
      });
      dispatch({
        type: CREATE_FUNDMOVEMENT_SUCCESS,
        data: data,
      });
      return data;
    }
  } catch (err) {
    dispatch({
      type: CREATE_FUNDMOVEMENT_FAILURE,
      data: {
        message: err?.response?.data?.message,
        fundMovement: err?.response?.data,
      },
    });
    showSnackBar(
      JSON.stringify(err?.response?.data?.errors) || 'Something went wrong',
      'error'
    );
    return null;
  }
};

export const accreditedEmail = postdata => async dispatch => {
  dispatch({
    type: ACCREDITED_EMAIL_INPROGRESS,
  });
  try {
    const {
      data: { data },
    } = await postAccreditedEmail(postdata);

    dispatch({
      type: ACCREDITED_EMAIL_SUCCESS,
      data: data,
    });

    return data;
  } catch (e) {
    dispatch({
      type: ACCREDITED_EMAIL_FAILURE,
      data: e?.response?.data?.message,
    });
    return null;
  }
};

export const reset = () => async dispatch => {
  dispatch({
    type: RESET,
    data: {},
  });
};
// UPLOAD DOCUMENT LOADER
export const uploadDocumentLoader = (id, data) => ({
  type: UPLOAD_DOCUMENTS_LOADER,
  data: {
    id,
    data,
  },
});

// UPLOAD DOCUMENT
export const uploadDocument = (
  formData,
  callback,
  requestCallback,
  uuid
) => async dispatch => {
  dispatch({
    type: UPLOAD_DOCUMENTS_INPROGRESS,
  });
  try {
    const { data } = await postUploadDocument(formData, callback);
    requestCallback(data);
    dispatch({
      type: UPLOAD_DOCUMENTS_SUCCESS,
      data: {
        uuid,
        data: data,
      },
    });
  } catch (e) {
    requestCallback({ status: 0, message: 'Something went wrong!' });
    dispatch({
      type: UPLOAD_DOCUMENTS_FAILURE,
      data: e?.response?.data?.message,
    });
  }
};
