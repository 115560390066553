import React from 'react';
import cs from 'classnames';
import styles from './button.module.scss';
import LoadSpinner from '../../assets/images/load-spinner';

const Button = ({
  children,
  isDisabled,
  isLoading,
  onClick,
  className,
  type,
  ...rest
}) => {
  const handleClick = e => {
    if (isLoading) return;
    else onClick(e);
  };

  return (
    <button
      type={type}
      className={cs(styles.root, className)}
      disabled={isDisabled || isLoading}
      onClick={handleClick}
      {...rest}
    >
      {isLoading ? (
        <>
          <h3>Loading</h3> <LoadSpinner />
        </>
      ) : (
        children
      )}
    </button>
  );
};

Button.defaultProps = {
  className: '',
  isLoading: false,
  isDisabled: false,
  onClick: () => {},
  type: '',
};

export default Button;
