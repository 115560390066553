import { useLocation } from 'react-router-dom';
import React from 'react';
import styles from './stepnumber.module.scss';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import cs from 'classnames';

const StepNumber = () => {
  let location = useLocation();

  const offeringName = useSelector(state => {
    return get(state, 'offering.data.issueName', null);
  });

  const stepRoutes = [
    // { routes: ['/auth/login', '/auth/register'] },
    { routes: [`/${offeringName}/investor-information`] },
    {
      routes: [`/${offeringName}/payment`],
    },
    { routes: [`/${offeringName}/verification-documents`] },
  ];

  const stepNumber = stepRoutes.findIndex(
    r => r.routes.findIndex(r => r === location.pathname) > -1
  );

  return <p className={cs('stepper', styles.stepper)}>Step {stepNumber + 1}</p>;
};

export default StepNumber;
