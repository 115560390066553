import React from 'react';

const DollarSign = () => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.625 15.4545H5.69318L5.69886 14.1761C8.10227 14 9.47727 12.625 9.47727 10.7841C9.47727 8.6875 7.60795 7.875 6.13068 7.51136L5.71591 7.40341L5.72727 3.75568C6.78977 3.89773 7.51705 4.48864 7.625 5.42045H9.31818C9.27273 3.6875 7.84091 2.39205 5.73295 2.22159L5.73864 0.90909H4.67045L4.66477 2.23295C2.625 2.4375 1.13068 3.71023 1.13068 5.54545C1.13068 7.16477 2.28977 8.11364 4.15909 8.61932L4.64773 8.75L4.63636 12.6193C3.52841 12.483 2.67045 11.8807 2.57955 10.7841H0.818182C0.926136 12.7443 2.34091 14.0057 4.63068 14.1761L4.625 15.4545ZM5.70455 12.6193L5.71591 9.04545C6.86932 9.36364 7.73864 9.78409 7.73864 10.767C7.73864 11.7614 6.90909 12.4602 5.70455 12.6193ZM4.65341 7.11932C3.80682 6.86932 2.875 6.42045 2.88068 5.44318C2.88068 4.59091 3.55682 3.92614 4.65909 3.76136L4.65341 7.11932Z"
      fill="#627D98"
    />
  </svg>
);

export default DollarSign;
